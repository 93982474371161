import * as React from 'react'
import * as ReactRedux from 'react-redux'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import { useCountryCode } from '../combined-selectors'

const Image = styled.img`
  ${css({
    p: [1, null, 2],
    width: '100%',
    height: 'auto',
  })}
`

export function PaymentProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviders(state.configuration)
  )
  const countryCode = useCountryCode()

  // We want to enable it only for Netherlands at least for now
  if (!paymentProviders.length || countryCode !== 'NL') {
    return null
  }

  return (
    <Common.Box
      display="grid"
      gridTemplateColumns={[
        'repeat(2, minmax(150px, 1fr))',
        null,
        null,
        'repeat(3, minmax(150px, 1fr))',
      ]}
      alignItems="center"
      flexWrap="wrap"
      justifyContent="center"
      minHeight="74px"
      width="100%"
      maxWidth="1200px"
      justifyItems="center"
    >
      {paymentProviders.map(provider => {
        if (!provider) {
          return
        }

        return (
          <LazyLoad key={provider.name} once height="34px" offset={100}>
            <Common.Box
              maxWidth="180px"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Image
                src={provider.image.url}
                alt={provider.name}
                height="24"
                width="115"
              />
            </Common.Box>
          </LazyLoad>
        )
      })}
    </Common.Box>
  )
}
