import { Link, noneOf } from '@rushplay/common'

import { AltButton, Button, PrimaryButton } from './button'

export const ButtonLink = Button.withComponent(Link, {
  shouldForwardProp: noneOf(['stretched', 'small']),
})

export const PrimaryButtonLink = PrimaryButton.withComponent(Link, {
  shouldForwardProp: noneOf(['stretched', 'small']),
})

export const AltButtonLink = AltButton.withComponent(Link, {
  shouldForwardProp: noneOf(['stretched', 'small']),
})
