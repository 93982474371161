import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'

import { FieldResponse } from './field-response'
import { Select } from './select'

export function SelectField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Common.Box display="grid" gridGap={0}>
      <Select
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        name={field.name}
        options={props.options}
        valid={R.isEmpty(field.errors)}
        visited={
          !R.includes(field.status, [
            Forms.FieldStatus.PRISTINE,
            Forms.FieldStatus.ABSENT,
          ])
        }
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        contentTranslated={props.contentTranslated}
      />
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}

SelectField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  scope: PropTypes.string,
  contentTranslated: PropTypes.bool,
}
