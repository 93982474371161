import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from '../components/icons'

const Input = styled.input`
  opacity: 0;
  margin-right: -1.2em;
`

const InputBase = styled.div`
  border-radius: 4px;
  border: 2px solid;
  background-color: white;
  height: 20px;
  width: 20px;
  min-width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  ${props =>
    css({
      bg: 'white',
      'input[type=checkbox]:checked + &': {
        borderColor: 'inputValid',
        color: 'inputTitle',
      },
      'input[type=checkbox]:not([checked]):disabled + &': {
        borderColor: 'disabled',
        cursor: 'not-allowed',
      },
      'input[type=checkbox]:disabled:checked + &': {
        borderColor: 'disabled',
        cursor: 'not-allowed',
        color: 'inputTitle',
      },
      borderColor: props.focus ? 'inputFocused !important' : 'input',
    })};
`

const Label = styled.label`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-content: center;
  cursor: pointer;
  pointer-events: inherit;
  padding: 2px;
`

export function Checkbox(props) {
  const [focus, setFocus] = React.useState(false)

  return (
    <Label>
      <Common.Box position="relative" alignSelf="center">
        <Input
          name={props.name}
          type="checkbox"
          checked={props.checked}
          disabled={props.disabled}
          value={props.value}
          data-testid={`${props.name}.checkbox`}
          onChange={props.onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <InputBase focus={focus}>
          <div>
            {props.checked && (
              <Common.Box color="inputValid" width="14px">
                <Icons.Done />
              </Common.Box>
            )}
          </div>
        </InputBase>
      </Common.Box>
      {props.label && (
        <Common.Box pl={1} color="inputTitle" fontSize="14px">
          {props.label}
        </Common.Box>
      )}
    </Label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
}
