import * as R from 'ramda'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Session from '@rushplay/session'

import * as _Session from '../../../session'
import * as Notifications from '../../../notifications'
import * as Countries from '../../../countries'
import * as Actions from '../actions'

import { CollectNumber as Component } from './collect-number'

export const CollectNumber = ReactRedux.connect(
  state => ({
    countryCallingCodeOptions: R.map(
      country => ({
        label: `+${country.countryCode}`,
        value: country.countryCode,
      }),
      Countries.getCountries(state.countries)
    ),
    optional: R.equals('optional', Session.getPhoneVerification(state.session)),
    token: Session.getSessionToken(state.session),
  }),
  dispatch => ({
    onSendVerificationCode: data => {
      const userData = {
        address: {
          mobile: data.phoneNumber,
          countryCallingCode: data.countryCallingCode,
        },
        allowSMS: !data.marketingOptOut,
      }
      dispatch(
        Api.updateMe(userData, {
          success: () => Actions.requestSmsVerification(),
          failure: () =>
            Notifications.add(
              { message: 'error.verify-phone-number.update-phone-number' },
              'error'
            ),
          version: 1,
        })
      )
    },
    onDismiss: () => {
      dispatch(
        Api.dismissPhoneVerification({
          success: token => _Session.refresh(token),
          failure: () =>
            Notifications.add(
              { message: 'error.verify-phone-number.dismiss' },
              'error'
            ),
          version: 1,
        })
      )
    },
  })
)(Component)
