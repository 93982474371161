import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import { CasinoNavigation as Component } from './casino-navigation'

export function mapStateToProps(state) {
  return {
    license: jurisdiction.getLicense(state.jurisdiction),
  }
}

const connector = R.compose(withRouter, connect(mapStateToProps, null))

export const CasinoNavigation = connector(Component)
