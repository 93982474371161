import { connect } from 'react-redux'

import { getLanguage } from '../combined-selectors'

import { Status } from './status'

function mapStateToProps(state) {
  const language = getLanguage(state)
  return {
    status: 302,
    to: ({ pathname = '/', search = '' }) => `/${language}${pathname}${search}`,
  }
}

const connector = connect(mapStateToProps)

export const LanguageRedirect = connector(Status)
