import * as React from 'react'
import * as ReactRedux from 'react-redux'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import * as Lookup from '../lookup'
import { ClientType } from '../client-type'
import { CookieBanner } from '../cookie-banner'
import { CssVariables } from '../components/css-variables'
import { GoogleDataLayerManager } from '../google-data-layer-manager'
import { Loader } from '../components/loader'
import { Metadata } from '../metadata'
import { Notifications } from '../notifications'
import { Registration } from '../registration'
import { Sidebar } from '../sidebar'
import { Spinner } from '../components/common/spinner'
import { SupportChat } from '../support-chat'
import { WebSocket } from '../web-socket'

import { LanguageRedirect } from './language-redirect'
import { Routes } from './routes'
import { ScrollRestoration } from './scroll-restoration'

const App = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: var(--window-inner-height, 100vh);
`

const BlockedMarket = Loadable({
  loader: () => import('../blocked-market'),
  render(loaded, props) {
    const Page = loaded.BlockedMarket
    return <Page {...props} />
  },
  loading: Spinner,
})

const Wallet = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Wallet = loaded.Wallet
    return <Wallet {...props} />
  },
  loading: Spinner,
})

export default function Router(props) {
  const Router = props.as

  const blocked = ReactRedux.useSelector(state => Lookup.getBlock(state.lookup))

  if (blocked) {
    return (
      <Router basename={props.basename} history={props.history}>
        <BlockedMarket />
      </Router>
    )
  }

  if (props.basename === '') {
    return (
      <Router history={props.history}>
        <LanguageRedirect />
      </Router>
    )
  }

  return (
    <Router basename={props.basename} history={props.history}>
      <App>
        <Routes />
        <Metadata />
        <CssVariables />
        <Notifications />
        <ScrollRestoration />
        <Sidebar />
        <Wallet />
        <Registration />
        <SupportChat />
        <CookieBanner />
        <WebSocket />
        <ClientType />
        <Loader />
        <GoogleDataLayerManager />
      </App>
    </Router>
  )
}

Router.defaultProps = {
  basename: '',
}

Router.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  basename: PropTypes.string,
  history: PropTypes.object,
}
