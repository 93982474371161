import * as R from 'ramda'

import * as api from '@rushplay/api-client'

import * as types from './types'

export function clearPromotions() {
  return { type: types.CLEAR_PROMOTIONS }
}

export function markAllPromotionsAsSeen() {
  return { type: types.MARK_ALL_PROMOTIONS_SEEN }
}

export function markPromotionAsSeen(key) {
  return {
    type: types.MARK_PROMOTION_SEEN,
    payload: key,
  }
}

export function removePromotion(key) {
  return {
    type: types.UPDATE_PROMOTION,
    payload: key,
  }
}

export function updatePromotions(payload) {
  return {
    type: types.UPDATE_PROMOTIONS,
    payload: R.reduce(
      (acc, item) =>
        R.assoc(
          R.path(['key'], item),
          R.pick(
            [
              'activeFrom',
              'activeTo',
              'campaign',
              'key',
              'lobbyBanner',
              'loggedInButtonLink',
              'previewFrom',
              'previewTo',
              'seen',
            ],
            item
          ),
          acc
        ),
      {},
      payload
    ),
  }
}

export function updatePromotion(promotion) {
  return {
    type: types.UPDATE_PROMOTION,
    payload: promotion,
  }
}

export function fetch(countryCode, config) {
  return api.fetchPromotions(countryCode, {
    success: res => [
      updatePromotions(res.value),
      R.path(['success'], config) && config.success(res),
    ],
    failure: res => R.path(['failure'], config) && config.failure(res),
    version: 2,
  })
}
