import * as R from 'ramda'

export function formatErrorVariables(errorVariables = {}) {
  if (
    errorVariables.reason === 'login_time_limit_exceeded' &&
    errorVariables.limitValue
  ) {
    return R.assocPath(
      ['limitValue'],
      errorVariables.limitValue / 6000,
      errorVariables
    )
  }
  return errorVariables
}
