import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@styled-system/css'
import { noneOf } from '@rushplay/common'

const BadgeWrapper = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
`

const BadgeCircle = styled.div`
  position: absolute;
  top: 0;
  right: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  ${props =>
    css({
      borderWidth: props.border.width,
      borderStyle: props.border.style,
      borderColor: props.border.color,
      backgroundColor: props.backgroundColor,
      color: props.color,
      fontSize: props.fontSize,
    })}
`

const Notification = styled(BadgeCircle, {
  shouldForwardProp: noneOf(['backgroundColor', 'border', 'color', 'fontSize']),
})`
  min-width: 1em;
  min-height: 1em;
  ${props => props.border.width && `top: -${props.border.width}`};
  ${props => props.border.width && `right: -${props.border.width}`};
`

const Count = styled(BadgeCircle, {
  shouldForwardProp: noneOf([
    'backgroundColor',
    'border',
    'color',
    'count',
    'fontSize',
  ]),
})`
  min-width: 1.5em;
  min-height: 1.5em;
  line-height: 1.5;
`

export function Badge(props) {
  return (
    <BadgeWrapper>
      {props.children}

      {props.notification && (
        <Notification
          color={props.color}
          backgroundColor={props.backgroundColor}
          border={props.border}
          fontSize={props.fontSize}
        />
      )}

      {props.count > 0 && (
        <Count
          count={props.count}
          color={props.color}
          backgroundColor={props.backgroundColor}
          border={props.border}
          fontSize={props.fontSize}
        >
          {props.count}
        </Count>
      )}
    </BadgeWrapper>
  )
}

Badge.defaultProps = {
  backgroundColor: 'primary',
  color: 'white',
  fontSize: 0,
  border: {},
}

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.object,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notification: PropTypes.bool,
}
