import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Locks from '@rushplay/compliance/locks'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as FormComponents from '../form-components'
import * as Notifications from '../notifications'
import * as Session from '../session'
import * as Ui from '../ui'
import { Spinner } from '../components/common/spinner'

const StyledInputField = styled(FormComponents.InputField)`
  height: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px;

  > input {
    color: #61696b;
    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    text-align: center;
  }
`

const dataSchema = {
  type: 'object',
  properties: {
    verificationCode: {
      type: 'string',
      pattern: /^[0-9]+$/,
      minLength: 4,
      maxLength: 4,
    },
  },
  required: ['verificationCode'],
}

export function EmailVerification() {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [resendDisabled, setResendDisabled] = React.useState(false)

  const email = ReactRedux.useSelector(state =>
    Session.getPlayerEmail(state.session)
  )

  function handleResend() {
    dispatch(
      Api.requestEmailVerificationCode({
        success: () => {
          setResendDisabled(true)
          setTimeout(() => {
            setResendDisabled(false)
          }, 10000)
        },
        failure: () =>
          Notifications.add({
            level: 'error',
            message: 'errors.email-verification-code.resend.failed',
          }),
        version: 1,
      })
    )
  }

  function handleSubmit(data) {
    setLoading(true)
    dispatch(
      Api.verifyEmail(data.verificationCode, {
        success: () => [
          setLoading(false),
          Locks.fetch(),
          Locks.remove('email-verification'),
          Ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true),
        ],
        failure: () => {
          setLoading(false)
          return Notifications.add(
            { message: 'errors.email-verifications-code.invalid' },
            'error'
          )
        },
        version: 1,
      })
    )
  }

  return (
    <Common.Box
      textAlign="center"
      minHeight="100vh"
      // margins overwite paddings from the PageWrapper
      my={[null, '-32px', '-24px']}
      mx={[null, '-24px', '-16px']}
      pt="36px"
      color="darkgray"
      backgroundColor="drawerBackground"
    >
      {loading && <Spinner />}
      <Common.Box fontSize="28px" fontWeight="600" lineHeight="33px">
        {i18n.translate('email-verification.heading')}
      </Common.Box>
      <Common.Box
        py="2"
        px="1"
        margin="auto"
        maxWidth="343px"
        fontSize="14px"
        lineHeight="16.24px"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {i18n.translate('email-verification.description', {
          email: email,
        })}
      </Common.Box>
      <Forms.Provider
        name="verifyEmailAddress"
        schema={dataSchema}
        onSubmit={(errors, data) => {
          R.isEmpty(errors) && handleSubmit(data)
        }}
      >
        <Common.Box display="flex" flexDirection="column" alignItems="center">
          <Common.Box width="280px">
            <Common.Box
              fontSize="12px"
              lineHeight="14px"
              textAlign="left"
              mb="4px"
            >
              {i18n.translate('email-verification.verification-code')}
            </Common.Box>
            <StyledInputField
              autoFocus
              autoComplete="one-time-code"
              inputmode="numeric"
              scope="#/properties/verificationCode"
              normalize={value => R.take(4, value || '')}
            />
          </Common.Box>
          <Common.Box width="200px" py="2">
            <FormComponents.SubmitButton stretched>
              <Common.Text
                color="white"
                fontWeight="700"
                fontSize="1"
                style={{ textShadow: '0px 2px 0px rgba(0, 0, 0, 0.1)' }}
              >
                {i18n.translate('email-verification.continue')}
              </Common.Text>
            </FormComponents.SubmitButton>
          </Common.Box>
          <Common.Box
            as="a"
            color="#EF8633"
            fontWeight="700"
            fontSize="14px"
            lineHeight="17px"
            style={{
              cursor: resendDisabled ? 'not-allowed' : 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => !resendDisabled && handleResend()}
          >
            {!resendDisabled
              ? i18n.translate('email-verification.new-code-request')
              : i18n.translate('email-verification.code-resent')}
          </Common.Box>
        </Common.Box>
      </Forms.Provider>
    </Common.Box>
  )
}
