import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

import * as types from './types'

export * from './actions'
export * from './selectors'

export function reducer(state = {}, action) {
  switch (action.type) {
    case websockets.SESSION_EXPIRED:
    case types.CLEAR_INVENTORY: {
      return {}
    }

    case types.UPDATE_INVENTORY: {
      return action.payload
    }

    case types.REMOVE_INVENTORY_ITEM: {
      return R.dissoc(action.payload, state)
    }

    case websockets.NEW_INVENTORY_ITEM_ADDED:
    case types.UPDATE_INVENTORY_ITEM: {
      return R.includes(R.path(['type'], action.payload), [
        'FeatureTriggers',
        'Freebet',
        'Freespins',
      ])
        ? R.assoc(R.path(['id'], action.payload), action.payload, state)
        : state
    }

    case types.MARK_ALL_INVENTORY_SEEN: {
      return R.map(R.assoc('seen', true), state)
    }

    case types.MARK_INVENTORY_ITEM_SEEN: {
      return R.assocPath([action.payload, 'seen'], true, state)
    }

    default:
      return state
  }
}
