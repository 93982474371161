import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import { PrimaryButton } from '../components/common/button'

const OverlayContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  transform: translateY(-100%);
  transition: transform 0.2s ease;
  padding: 1em;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:hover ${OverlayContent} {
    transform: translateY(0);
  }
`

export function LoginRequiredOverlay() {
  const i18n = useI18n()
  const manualSignUpAllowed = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )

  return (
    <Overlay>
      <Common.Link to={manualSignUpAllowed ? '/login' : '/deposit'}>
        <OverlayContent>
          <Common.Text fontWeight={200} color="white" textAlign="center">
            {i18n.translate('login-required-overlay.prompt-login-text')}
          </Common.Text>
          <Common.Box as={PrimaryButton} fontSize={0} py={0} px={1} mt={1}>
            {i18n.translate('login-required-overlay.prompt-login-button')}
          </Common.Box>
        </OverlayContent>
      </Common.Link>
    </Overlay>
  )
}
