import * as R from 'ramda'
import url from 'url'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'
import { withRouter } from 'react-router-dom'

import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import * as Constants from '../../../constants'
import * as promotions from '../../../promotions'
import * as notifications from '../../../notifications'
import * as ui from '../../../ui'
import { updateQueryParams } from '../../../utils/update-query-params'

import { Promotion as Component } from './promotion'

function mapStateToProps(state, props) {
  return {
    isLoading: processes.isRunning(state.processes, {
      ids: [`USE_PROMOTION_${props.item.key}`],
    }),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onClaimPromotion: () => {
      if (promotions.isDepositCampaignPromotion(props.item)) {
        const urlParams = R.path(
          ['query'],
          url.parse(props.location.search, true)
        )
        return dispatch([
          updateQueryParams(urlParams, {
            campaign: R.path(['campaign', 'id'], props.item),
          }),
          ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true),
          ui.toggleVisibility('notifications', false),
        ])
      } else if (
        R.pathEq(['campaign', 'optInState'], 'automatic', props.item)
      ) {
        return dispatch([
          redirectTo(props.item.loggedInButtonLink),
          ui.toggleVisibility('notifications', false),
        ])
      } else if (R.pathEq(['campaign', 'optInState'], 'out', props.item)) {
        return dispatch([
          processes.start(`USE_PROMOTION_${props.item.key}`),
          api.optInCampaign(R.path(['campaign', 'id'], props.item), {
            success: () => [
              processes.stop(`USE_PROMOTION_${props.item.key}`),
              props.item.loggedInButtonLink &&
                redirectTo(props.item.loggedInButtonLink),
              promotions.updatePromotion(
                R.assocPath(['campaign', 'optInState'], 'in', props.item)
              ),
              ui.toggleVisibility('notifications', false),
            ],
            failure: () => [
              processes.stop(`USE_PROMOTION_${props.item.key}`),
              notifications.add(
                { message: 'errors.promotion-not-claimed' },
                'error'
              ),
            ],
            version: 2,
          }),
        ])
      }
    },
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export const Promotion = connector(Component)
