import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'

import * as Analytics from '../analytics'

export function Item(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()
  const title = i18n.isExistingTranslation(props.title)
    ? i18n.translate(props.title)
    : props.title

  return (
    <props.as
      {...R.omit(['title', 'as'], props)}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
        dispatch(
          Analytics.clickSideMenuItem({
            path: `${location.pathname}${location.search}${location.hash}`,
            title,
            destination: props.to || props.href,
            expanded: props.expanded,
          })
        )
      }}
    >
      {props.children ? props.children : title}
    </props.as>
  )
}

Item.defaultProps = {
  as: 'div',
}

Item.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}
