import * as R from 'ramda'
import url from 'url'
import { replace } from 'connected-react-router'

/**
 *
 * @param {object} queryParams The base params that can be overrided
 * @param {object} overrideParams The params that will be added to the base or override
 * @returns {object} Redux action
 */
export function updateQueryParams(queryParams, overrideParams) {
  return replace({
    search: url.format({
      query: R.reject(R.isNil, R.merge(queryParams, overrideParams)),
    }),
  })
}
