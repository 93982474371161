import * as R from 'ramda'
import * as ReactRouterDom from 'react-router-dom'

function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

/**
 * Only updates specified queries
 * @param {Object} location
 * @param {Object} nextQuery
 * @returns {string} querystring with updated values
 */
export function getSafeUpdateQuery(location, nextQuery) {
  const query = new URLSearchParams(location.search)

  return new URLSearchParams(
    R.reject(R.isNil, { ...paramsToObject(query.entries()), ...nextQuery })
  ).toString()
}

export function useSafeUpdateQuery(nextQuery) {
  const location = ReactRouterDom.useLocation()

  return getSafeUpdateQuery(location, nextQuery)
}
