import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

/**
 * Create redirect route with specific HTTP status code
 */
export function Status(props) {
  return (
    <Route
      render={({ location, staticContext }) => {
        if (staticContext) {
          staticContext.status = props.status
        }
        return (
          <Redirect
            from={props.from}
            to={typeof props.to === 'function' ? props.to(location) : props.to}
          />
        )
      }}
    />
  )
}

Status.propTypes = {
  from: PropTypes.string,
  status: PropTypes.number,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
}
