import PropTypes from 'prop-types'
import React from 'react'

import css from '@styled-system/css'
import styled from '@emotion/styled'
import { color, font, fontSize, mediaQuery, spacing } from '@rushplay/theme'

const Wrapper = styled('div')`
  line-height: 1.2;

  h1,
  h2,
  h3,
  h4 {
    font-family: ${font('head')};
    color: currentColor;
    margin: 0;
  }
  h1 {
    line-height: 1.26;
    font-size: ${fontSize(4)};
    font-weight: bold;
  }
  h2 {
    font-size: ${fontSize(3)};
    line-height: 1.14;
    font-weight: normal;
  }
  h3 {
    font-size: ${fontSize(2)};
    line-height: 1.45;
    font-weight: bold;
  }
  h4 {
    font-size: ${fontSize(0)};
    line-height: 1.14;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${color('gray')};
  }
  li {
    padding: 4px;
    line-height: 1.5;
    font-size: ${fontSize(0)};
  }
  ol,
  ul {
    padding-left: ${spacing(1)};
    padding-top: ${spacing(1)};
    list-style: initial;
    font-family: ${font('body')};
    color: ${color('gray')};
  }
  p {
    color: ${color('gray')};
    font-family: ${font('body')};
    font-size: ${fontSize(0)};
    line-height: 1.5;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  a {
    font-size: ${fontSize(0)};
    text-decoration: underline;
    color: ${color('primary')};
  }
  b,
  strong {
    font-weight: bold;
  }
  > details {
    position: relative;
    margin-bottom: 1px;
    > summary {
      position: relative;
      display: block;
      cursor: pointer;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      background-color: #fff;
      padding: ${spacing(1)} ${spacing(4)} ${spacing(1)} ${spacing(1)};
      font-family: ${font('body')};
      font-size: ${fontSize(1)};

      ${mediaQuery.md`
        font-size: 20px;
      `};

      &:after {
        top: 20px;
        right: 20px;
      }
      &::-webkit-details-marker {
        display: none;
      }
    }
    details {
      border-bottom: 1px solid ${color('grayish')};
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:not(:first-of-type) {
        padding-top: 5px;
      }
      > summary {
        font-size: ${fontSize(1)};
        line-height: 1.45;
        position: relative;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        padding-right: 25px;
        &:after {
          right: 0;
          top: 0;
          height: 100%;
        }
        &::-webkit-details-marker {
          display: none;
        }
      }
    }
    main {
      padding: 20px 35px;
      background-color: #f9f9f9;
    }
  }
  details {
    &[open] > summary:after {
      transform: rotate(0deg);
    }
    summary {
      &:after {
        position: absolute;
        width: 20px;
        height: 17px;
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1683 1331l-166 165q-19 19-45 19t-45-19l-531-531-531 531q-19 19-45 19t-45-19l-166-165q-19-19-19-45.5t19-45.5l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z" fill="${encodeURIComponent(
          '#'
        )}7D838B" /></svg>');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        transform: rotate(-180deg);
      }
    }
  }

  ${props => css(props.styleOverrides)}

`

export function HtmlContent(props) {
  const html = props.children ? { __html: props.children } : props.html

  return (
    <Wrapper
      className={props.className}
      dangerouslySetInnerHTML={html}
      styleOverrides={props.styleOverrides}
    />
  )
}

HtmlContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  html: PropTypes.shape({ __html: PropTypes.string }),
  styleOverrides: PropTypes.object,
}

HtmlContent.defaultProps = {
  styleOverrides: {},
}
