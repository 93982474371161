import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Ui from '../ui'
import * as Lookup from '../lookup'
import * as Player from '../player'

import { LandingPage as Component } from './landing-page'

function mapStateToProps(state) {
  return {
    countryCode: Lookup.getCountryCode(state.lookup),
    showWelcomeOffer: !(
      R.isEmpty(R.pathOr([], ['depositOffers'], state.offers)) ||
      Player.hasDeposited(state.player)
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onRegister: () => dispatch(Ui.toggleVisibility('register', true)),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const LandingPage = connector(Component)
