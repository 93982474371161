import * as R from 'ramda'
import { connect } from 'react-redux'

import * as selectors from '../selectors'
import * as actions from '../actions'

import { Notification as Component } from './notification'

function mapStateToProps(state, ownProps) {
  return selectors.getOne(state.notifications, { id: ownProps.id })
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onDismiss: () => dispatch(actions.dismiss(ownProps.id)),
  }
}

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

export const Notification = connector(Component)
