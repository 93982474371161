import * as R from 'ramda'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as FormComponents from '../../form-components'
import { Card } from '../../components/common/card'
import { H1 } from '../../components/common/headings'
import { PrimaryButtonLink } from '../../components/common/button-link'

import { Timeout } from './timeout'

const dataSchema = {
  type: 'object',
  properties: {
    period: {
      type: 'string',
    },
  },
  required: ['period'],
}

function SubmitButton() {
  const i18n = I18n.useI18n()
  const period = Forms.useField('#/properties/period', { noRegister: true })

  return (
    <PrimaryButtonLink
      stretched
      to={`/time-out/confirmation?period=${period.value}`}
    >
      {i18n.translate('player-limits.confirm')}
    </PrimaryButtonLink>
  )
}

export function TimeoutForm() {
  const i18n = I18n.useI18n()

  return (
    <Timeout>
      {timeout => (
        <Common.Box maxWidth="400px" margin="auto">
          <Common.Space py={1}>
            <H1 align="center">{i18n.translate('time-out-period.headline')}</H1>
          </Common.Space>
          <Card>
            <Common.Text
              color="gray"
              fontSize={1}
              textAlign="center"
              lineHeight={1.5}
            >
              {i18n.translate(`time-out-period.description`)}
            </Common.Text>
            <Forms.Provider name="time-out" schema={dataSchema}>
              <Common.Space paddingTop={1} paddingBottom={2}>
                <FormComponents.SelectField
                  scope="#/properties/period"
                  initialValue={R.path([0, 'value'], timeout.options)}
                  options={R.map(
                    option => R.assoc('label', option.labelKey, option),
                    timeout.options
                  )}
                />
              </Common.Space>
              <SubmitButton />
            </Forms.Provider>
          </Card>
        </Common.Box>
      )}
    </Timeout>
  )
}
