import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'

export const IconCircle = styled('div', {
  shouldForwardProp: noneOf(['backgroundColor', 'color', 'hoverColor', 'size']),
})`
  border: ${props => props.border};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  color: ${props => t.color(props.color)};
  background-color: ${props => t.color(props.backgroundColor)};
  ${props => props.onClick && 'cursor: pointer'};

  overflow: hidden;

  &:hover {
    color: ${props => t.color(props.hoverColor)};
  }

  &:active {
    box-shadow: inset ${t.boxShadow(0)};
  }
`

IconCircle.defaultProps = {
  backgroundColor: 'white',
  color: 'gray3',
  hoverColor: 'blue',
  size: 36,
}

IconCircle.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
}
