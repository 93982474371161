import { connect } from 'react-redux'

import { isSessionActive } from '@rushplay/session'

import { PrivateRoute as Component } from './private-route'

function mapStateToProps(state) {
  return { authenticated: isSessionActive(state.session) }
}

const connector = connect(mapStateToProps)

export const PrivateRoute = connector(Component)
