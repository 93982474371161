import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { withTranslate } from '@rushplay/i18n'

const Navigation = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  overflow: hidden;
  order: 1;
  margin-top: ${t.spacing(1)};

  ${t.mediaQuery.sm`
    order: 0;
    margin-top: 0;
    margin-right: 16px;
  `};
`

const LinkItem = styled.div`
  display: flex;
  padding-top: ${t.spacing(0)};
  padding-bottom: ${t.spacing(0)};
  border-bottom: 2px solid white;

  &:not(:first-of-type) {
    padding-left: ${t.spacing(1)};
  }
`

const Link = styled(ReactRouterDom.NavLink)`
  font-family: ${t.font('head')};
  font-size: 14px;
  font-weight: 600;
  color: ${t.color('white')};
  white-space: nowrap;
  padding-right: ${t.spacing(0)};
  padding-left: ${t.spacing(0)};
  height: 51px;
  align-items: center;
  display: flex;

  &:hover {
    border-bottom: 9px solid white;
    padding-top: 9px;
  }

  ${props =>
    css({
      borderBottom: props.selected ? '9px solid white' : null,
      paddingTop: props.selected ? '9px' : null,
    })}
`

export function isSelected(item, props) {
  const islobbySelected = !item.category && !props.selectedCategory
  const isCategorySelected = item.category === props.selectedCategory

  return islobbySelected || isCategorySelected
}

export const LobbyNavigation = R.compose(withTranslate)(props => {
  return (
    <Navigation className={props.className}>
      {props.children && props.children}
      <Common.ScrollableRow>
        <Common.Flex wide>
          {R.map(
            item => (
              <LinkItem key={item.key}>
                <Link to={item.path} selected={isSelected(item, props)}>
                  {props.translate(item.key)}
                </Link>
              </LinkItem>
            ),
            props.items
          )}
        </Common.Flex>
      </Common.ScrollableRow>
    </Navigation>
  )
})
