import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Common from '@rushplay/common'
import * as SupportChat from '@rushplay/support-chat'
import * as I18n from '@rushplay/i18n'

import * as Configuration from '../configuration'
import { Badge } from '../components/common/badge'

import { MenuItem } from './menu-item'
import { Settings } from './settings'
import { SidebarButtons } from './sidebar-buttons'

/* eslint-disable react/display-name */
export function MenuItems(props) {
  const i18n = I18n.useI18n()
  const dispatch = useDispatch()
  const unreadMessagesCount = useSelector(state =>
    SupportChat.getUnreadCount(state.supportChat)
  )
  const whatsAppNumber = useSelector(state =>
    Configuration.getWhatsAppNumber(state.configuration)
  )

  const linkItems = {
    WhatsApp: () =>
      whatsAppNumber ? (
        <MenuItem
          to={`https://wa.me/${whatsAppNumber}`}
          title="side-bar.whatsapp-chat"
          color="veryDarkBlue"
        />
      ) : null,
    Casino: () => (
      <MenuItem to="/casino" title="side-bar.casino" color="veryDarkBlue" />
    ),
    LiveCasino: () => (
      <MenuItem
        to="/casino/live-casino"
        title="side-bar.live-casino"
        color="veryDarkBlue"
      />
    ),
    Sports: () => (
      <MenuItem to="/sports" title="side-bar.sports" color="veryDarkBlue" />
    ),
    ChatSupport: () => (
      <MenuItem
        onClick={() => dispatch(SupportChat.setTabVisibility(true))}
        title="support"
        color="veryDarkBlue"
      >
        <Badge count={unreadMessagesCount}>
          <Common.Space pr={2}>{i18n.translate('support')}</Common.Space>
        </Badge>
      </MenuItem>
    ),
    Settings,
    SidebarButtons,
  }

  const items = props.items(linkItems)

  return R.map(key => {
    const Item = items[key]
    return <Item key={key} />
  }, R.keys(items))
}

/* eslint-enable react/display-name */

MenuItems.propTypes = {
  items: PropTypes.func.isRequired,
}
