import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Space } from '@rushplay/common'

import * as Ui from '../ui'
import * as Constants from '../constants'
import { Card } from '../components/common/card'

export function LockCard(props) {
  const dispatch = ReactRedux.useDispatch()

  // a hacky way to ensure that the deposit drawer won't open
  // on a locked page
  React.useEffect(() => {
    dispatch(Ui.toggleVisibility(Constants.TransactionType.DEPOSIT, false))
  }, [])

  return (
    <Box maxWidth="600px" margin="auto">
      <Space py={2}>
        <Card>{props.children}</Card>
      </Space>
    </Box>
  )
}

LockCard.propTypes = {
  children: PropTypes.node,
}
