import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import { Amount } from './components/common/amount'

const CancelButton = styled.div`
  cursor: pointer;
`

export function PendingTransaction(props) {
  const i18n = I18n.useI18n()
  const amount = props.amount ? parseInt(R.head(R.split(' ', props.amount))) : 0
  const locale = ReactRedux.useSelector(CombinedSelectors.getLocale)
  /**
   * Safari doesn't see date-strings with dashes (-) as a proper date-string hence
   * the replace with slash (/). See this link for more info:
   * https://stackoverflow.com/questions/4310953/invalid-date-in-safari
   */
  const date = new Date(R.replace(/-/g, '/', props.date)).toLocaleDateString(
    locale
  )

  return (
    <Common.Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="40px"
      px="15px"
      mt="5px"
      backgroundColor="white"
      borderRadius="4px"
      boxShadow={0}
    >
      <Common.Text weight={600} font="head">
        {'- '}
        <Amount>{Math.abs(amount) * 100}</Amount>
      </Common.Text>
      <Common.Text color="gray2" font="head" size="0">
        {date}
      </Common.Text>
      <CancelButton onClick={props.onCancel}>
        <Common.Text
          color="primary"
          font="head"
          decoration="underline"
          weight={600}
        >
          {i18n.translate('pending-withdrawals.cancel')}
        </Common.Text>
      </CancelButton>
    </Common.Box>
  )
}

PendingTransaction.propTypes = {
  amount: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
}
