import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as casino from '@rushplay/casino'

import { GameTile as Component } from './game-tile'

function mapStateToProps(state, props) {
  if (!props.id) {
    return {}
  }

  return casino.selectors.getGame(state.casino, props)
}

const connector = R.compose(withRouter, connect(mapStateToProps))

export const GameTile = connector(Component)
