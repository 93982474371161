import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'

import * as CombinedSelectors from '../combined-selectors'

import { FieldResponse } from './field-response'
import { WalletInput } from './wallet-input'

const DECIMAL_SEPARATORS = ['.', ',']

function parseValue(value) {
  const normalizedValue =
    typeof value === 'string' ? value.replace(/,/, '.') : value

  return parseFloat(normalizedValue)
}

function normalizeAmount(value) {
  const amount = parseValue(value)

  if (Boolean(value) && R.includes(R.last(value), DECIMAL_SEPARATORS)) {
    return value
  }

  if (Number.isNaN(amount)) {
    return 0
  }

  return Math.round(amount * 100)
}

function parseAmount(locale) {
  return value => {
    const amount = value / 100

    if (Boolean(value) && R.includes(R.last(value), DECIMAL_SEPARATORS)) {
      return value
    }

    if (Number.isNaN(amount)) {
      return ''
    }

    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      useGrouping: false,
    })

    return formatter.format(amount)
  }
}

export function WalletInputField(props) {
  const currency = CombinedSelectors.useCurrency()
  const locale = ReactRedux.useSelector(CombinedSelectors.getLocale)
  const form = Forms.useFormContext()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: normalizeAmount,
    parse: parseAmount(locale),
  })

  return (
    <Common.Box display="grid" gridGap={1}>
      <WalletInput
        autoFocus={props.autoFocus}
        currency={currency}
        disabled={props.disabled}
        maxLength={props.maxLength}
        minLength={props.minLength}
        id={`${form.name}-${field.name}`}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}

WalletInputField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  scope: PropTypes.string,
}
