import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from '../components/icons'

function borderColor(props) {
  if (props.focused) {
    return 'inputFocused'
  } else if (props.valid) {
    return 'inputValid'
  } else if (props.invalid) {
    return 'inputInvalid'
  } else {
    return 'input'
  }
}

const Wrapper = styled.label`
  ${props =>
    css({
      position: 'relative',
      border: '1px solid',
      borderColor: borderColor(props),
      backgroundColor: props.disabled ? 'gray1' : 'white',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      transition: 'border 300ms ease-in-out',
      fontSize: '14px',
      borderRadius: '4px',
      overflow: 'hidden',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    })};
`

const StyledSelect = styled.select`
  ${props =>
    css({
      width: '100%',
      fontSize: '14px',
      fontFamily: 'body',
      border: 'none',
      borderRadius: '4px',
      backgroundImage: 'none',
      appearance: 'none',
      lineHeight: '24px',
      py: '12px',
      pl: '8px',
      pr: '32px',
      outline: 'none',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'transparent',
      color: props.disabled && 'gray8',
    })};
`

const IconWrapper = styled.div`
  ${css({
    display: 'block',
    position: 'absolute',
    top: '19px',
    right: '8px',
    width: '16px',
    color: 'input',
    pointerEvents: 'none',
  })};
`
export function Select(props) {
  const i18n = I18n.useI18n()
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)
  const value = props.value && props.value.toString()

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper
      valid={props.valid && props.visited}
      invalid={!props.valid && props.visited}
      disabled={props.disabled}
      focused={focused}
      ref={inputRef}
    >
      <StyledSelect
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        value={value}
        onBlur={handleBlur}
        onChange={props.onChange}
        onFocus={() => setFocused(true)}
      >
        {props.options &&
          R.map(
            option => (
              <option key={option.value} value={option.value}>
                {props.contentTranslated
                  ? option.value
                  : i18n.isExistingTranslation(option.label)
                  ? i18n.translate(option.label, option.translationData)
                  : option.label}
              </option>
            ),
            props.options
          )}
      </StyledSelect>
      <IconWrapper>
        <Icons.ChevronDown />
      </IconWrapper>
    </Wrapper>
  )
}

Select.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentTranslated: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}
