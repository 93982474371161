import * as React from 'react'

/* @jsx UiSchema.createElement */
import { UiSchema } from '@rushplay/forms'

export function dateRangeFormSchema() {
  return {
    type: 'object',
    properties: {
      startDay: {
        type: 'string',
        enum: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
        ],
      },
      startMonth: {
        type: 'string',
        enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      },
      startYear: {
        type: 'string',
      },
      endDay: {
        type: 'string',
        enum: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
        ],
      },
      endMonth: {
        type: 'string',
        enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      },
      endYear: {
        type: 'string',
      },
    },
  }
}

export const dateRangeFormUiSchema = (
  <UiSchema.Box display="grid" gridGap={0}>
    <UiSchema.Text content="history-date-range-start-description" />
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gridGap={1}
      pb={0}
    >
      <UiSchema.Control
        scope="#/properties/startDay"
        forwardProps={{
          contentTranslated: true,
        }}
      />
      <UiSchema.Control
        scope="#/properties/startMonth"
        forwardProps={{
          contentTranslated: true,
        }}
      />
      <UiSchema.Control
        forwardProps={{
          maxLength: '4',
          contentTranslated: true,
        }}
        scope="#/properties/startYear"
      />
    </UiSchema.Box>
    <UiSchema.Text content="history-date-range-end-description" />
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gridGap={1}
      pb={0}
    >
      <UiSchema.Control
        scope="#/properties/endDay"
        forwardProps={{
          contentTranslated: true,
        }}
      />
      <UiSchema.Control
        scope="#/properties/endMonth"
        forwardProps={{
          contentTranslated: true,
        }}
      />
      <UiSchema.Control
        forwardProps={{
          maxLength: '4',
          contentTranslated: true,
        }}
        scope="#/properties/endYear"
      />
    </UiSchema.Box>
  </UiSchema.Box>
)
