import React from 'react'

import EmotionStyled from '@emotion/styled'
import StyledSystemCss from '@styled-system/css'

const Spinner = EmotionStyled.div`
  mask: url(/images/simplecasino-spinner.svg) no-repeat center;
  width: 50px;
  height: 50px;
  margin: auto;
  ${() => StyledSystemCss({ backgroundColor: 'primary' })}
`

export function SpinnerIcon() {
  return <Spinner />
}
