import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { clientTypes } from './types'

export function ClientType(props) {
  React.useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    props.onUpdateClientType(
      isTouchDevice ? clientTypes.MOBILE_BROWSER : clientTypes.BROWSER
    )

    document.addEventListener(
      'nativeApp',
      event =>
        event.detail.isNativeApp && props.onUpdateClientType(clientTypes.IOS),
      false
    )

    const postEvent = R.path(
      ['webkit', 'messageHandlers', 'is_native_app'],
      window
    )

    if (postEvent) {
      postEvent.postMessage(null)
    }

    return () =>
      document.removeEventListener('nativeApp', props.onUpdateClientType)
  }, [])

  return null
}

ClientType.propTypes = {
  onUpdateClientType: PropTypes.func.isRequired,
}
