import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'

import { Checkbox } from './checkbox'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

export function CheckboxGroup(props) {
  const [options, setOptions] = React.useState([])
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  function handleChange(key, value) {
    // checkbox was true
    if (value && R.not(R.includes(key, options))) {
      const nextOptions = R.append(key, options)
      setOptions(nextOptions)
      return field.onChangeValue(nextOptions)
    }
    // checkbox was false
    const nextOptions = R.without(key, options)
    setOptions(nextOptions)
    return field.onChangeValue(nextOptions)
  }

  return (
    <Common.Box display="grid" gridGap={0}>
      {R.map(
        item => (
          <Checkbox
            key={item.value}
            disabled={R.includes(item.key, props.disabled)}
            checked={R.includes(item.value, options)}
            value={R.includes(item.value, options)}
            onChange={value => handleChange(item.value, value)}
            label={item.label}
          />
        ),
        props.options
      )}
    </Common.Box>
  )
}

CheckboxGroup.defaultProps = {
  disabled: [],
}

CheckboxGroup.propTypes = {
  disabled: PropTypes.array,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string,
}
