import * as R from 'ramda'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import * as Common from '@rushplay/common'

import GamblingHistory from '../../settings/gambling-history'
import { BonusTermsConditions } from '../../wallet/bonus-offer/terms-and-conditions'
import { Footer } from '../../components/footer'
import { HomePage } from '../home-page'
import { LockPageContainer } from '../../locks'
import { LockedTopbar } from '../../locks/locked-topbar'
import { NotFound } from '../../components/not-found'
import { PageWrapper } from '../../components/page-wrapper'
import { PrivateRoute } from '../private-route'
import { PromotionTermsConditions } from '../../wallet/campaigns/terms-and-conditions'
import { ReceiptsContainer } from '../../receipts/container'
import { Redirect } from '../redirect'
import {
  SelfExclusionConfirmation,
  SelfExclusionForm,
} from '../../settings/self-exclusion'
import { Spinner } from '../../components/common/spinner'
import { TimeoutConfirmation, TimeoutForm } from '../../settings/timeout'
import { Topbar } from '../../components/topbar'
import { usePageview } from '../../use-pageview'

const CasinoLobby = Loadable({
  loader: () => import('../../casino/casino-lobby'),
  render(loaded, props) {
    const CasinoLobby = loaded.CasinoLobby
    return <CasinoLobby {...props} />
  },
  loading: Spinner,
})

const CasinoCategory = Loadable({
  loader: () => import('../../casino/category'),
  render(loaded, props) {
    const CasinoCategory = loaded.CasinoCategory
    return <CasinoCategory {...props} />
  },
  loading: Spinner,
})

const LiveCasino = Loadable({
  loader: () => import('../../casino/live-casino'),
  render(loaded, props) {
    const LiveCasino = loaded.LiveCasino
    return <LiveCasino {...props} />
  },
  loading: Spinner,
})

const GameLauncher = Loadable({
  loader: () => import('../../casino/launcher'),
  render(loaded, props) {
    const GameLauncher = loaded.GameLauncher
    return <GameLauncher {...props} />
  },
  loading: Spinner,
})

const PayNPlayTransaction = Loadable({
  loader: () => import('../../pay-n-play-transaction'),
  render(loaded, props) {
    const PayNPlayTransaction = loaded.PayNPlayTransaction
    return <PayNPlayTransaction {...props} />
  },
  loading: Spinner,
})

const Logout = Loadable({
  loader: () => import('../../logout'),
  render(loaded, props) {
    const Logout = loaded.Logout
    return <Logout {...props} />
  },
  loading: Spinner,
})

const ForgotPassword = Loadable({
  loader: () => import('../../forgot-password'),
  render(loaded, props) {
    const ForgotPassword = loaded.ForgotPassword
    return <ForgotPassword {...props} />
  },
  loading: Spinner,
})

const ResetPasswordConfirmation = Loadable({
  loader: () => import('../../forgot-password/reset-password-confirmation'),
  render(loaded, props) {
    const ResetPasswordConfirmation = loaded.ResetPasswordConfirmation
    return <ResetPasswordConfirmation {...props} />
  },
  loading: Spinner,
})

const CreateNewPassword = Loadable({
  loader: () => import('../../forgot-password/create-new-password'),
  render(loaded, props) {
    const CreateNewPassword = loaded.CreateNewPassword
    return <CreateNewPassword {...props} />
  },
  loading: Spinner,
})

const RealityCheck = Loadable({
  loader: () => import('../../settings/reality-check'),
  render(loaded, props) {
    const RealityCheck = loaded.RealityCheck
    return <RealityCheck {...props} />
  },
  loading: Spinner,
})

const PlayerProfile = Loadable({
  loader: () => import('../../settings/player-profile'),
  render(loaded, props) {
    const PlayerProfile = loaded.PlayerProfile
    return <PlayerProfile {...props} />
  },
  loading: Spinner,
})

const StaticPage = Loadable({
  loader: () => import('../../static-page'),
  render(loaded, props) {
    const StaticPage = loaded.StaticPage
    return <StaticPage {...props} />
  },
  loading: Spinner,
})

const TermsPage = Loadable({
  loader: () => import('../../static-page/terms-page'),
  render(loaded, props) {
    const TermsPage = loaded.TermsPage
    return <TermsPage {...props} />
  },
  loading: Spinner,
})

const GameSearchResult = Loadable({
  loader: () => import('../../casino/game-search-result'),
  render(loaded, props) {
    const GameSearchResult = loaded.GameSearchResult
    return <GameSearchResult {...props} />
  },
  loading: Spinner,
})

const ModeSelector = Loadable({
  loader: () => import('../../casino/mode-selector'),
  render(loaded, props) {
    const ModeSelector = loaded.ModeSelector
    return <ModeSelector {...props} />
  },
  loading: Spinner,
})

const SportsbookDirect = Loadable({
  loader: () => import('../../sports-direct'),
  render(loaded, props) {
    const SportsbookDirect = loaded.SportsbookDirect
    return <SportsbookDirect {...props} />
  },
  loading: Spinner,
})

const Login = Loadable({
  loader: () => import('../../login'),
  render(loaded, props) {
    const Login = loaded.Login
    return <Login {...props} />
  },
  loading: Spinner,
})

const Limits = Loadable({
  loader: () => import('../../settings/limits/limits'),
  render(loaded, props) {
    const Limits = loaded.Limits
    return <Limits {...props} />
  },
  loading: Spinner,
})

export function Routes(props) {
  usePageview()

  if (!R.isEmpty(props.locks)) {
    return (
      <React.Fragment>
        <Route path="/" component={LockedTopbar} />
        <PageWrapper>
          <Switch>
            <Redirect
              exact
              from="/"
              to={`/locks/${R.head(props.locks)}`}
              preserveQuery
            />

            <PrivateRoute path="/locks/:lock" component={LockPageContainer} />

            <PrivateRoute path="/limits" component={Limits} />
            <Redirect exact from="/money-limits" to="/limits?limit=money" />
            <Redirect exact from="/session-limits" to="/limits?limit=time" />

            <Route path="/reality-check" component={RealityCheck} />
            <PrivateRoute path="/logout" component={Logout} />

            <Route
              path="/(about-us|cookie-policy|faq|privacy-policy|responsible-gaming|security|terms-conditions-bonus)"
              component={StaticPage}
            />

            <Route path="/terms-conditions" component={TermsPage} />

            <Redirect to="/" preserveQuery />
          </Switch>
        </PageWrapper>
        <Common.Box backgroundColor="darkblue" width="100%">
          <Footer />
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Route path="/" component={Topbar} />

      <Switch>
        <Redirect from="/locks" to="/" preserveQuery />
        <Redirect from="/bullcasinopromo" to="/" preserveQuery />

        <Route exact path="/" component={HomePage} />

        <Route path="/sports" component={SportsbookDirect} />

        <Route path="/casino/games/:gameId/mode" component={ModeSelector} />
        <Route path="/casino/games/:gameId" component={GameLauncher} />

        <PageWrapper>
          <Switch>
            <Redirect exact from="/deposit" to="/login" />

            <Route path="/deposit/process">
              <PayNPlayTransaction flow="deposit" />
            </Route>
            <Route path="/login" component={Login} />
            <Route path="/casino/search" component={GameSearchResult} />
            <Route
              path="/casino/live-casino/:category?"
              component={LiveCasino}
            />
            <Route path="/casino/games" component={CasinoLobby} />
            <Route path="/casino/:tag" component={CasinoCategory} />
            <Route path="/casino" component={CasinoLobby} />
            <PrivateRoute
              path="/time-out/confirmation"
              component={TimeoutConfirmation}
            />
            <PrivateRoute path="/time-out" component={TimeoutForm} />
            <PrivateRoute
              path="/self-exclusion/confirmation"
              component={SelfExclusionConfirmation}
            />
            <PrivateRoute
              path="/self-exclusion"
              component={SelfExclusionForm}
            />
            <Route
              path="/(about-us|cookie-policy|faq|privacy-policy|responsible-gaming|security|terms-conditions-bonus)"
              component={StaticPage}
            />
            <Route path="/(terms-conditions-sport)" component={StaticPage} />
            <Route path="/terms-conditions" component={TermsPage} />
            <Route
              path="/promotion/:key"
              component={PromotionTermsConditions}
            />
            <Route
              path="/bonus/terms-conditions-bonus"
              component={BonusTermsConditions}
            />
            <PrivateRoute
              path="/gambling-history"
              component={GamblingHistory}
            />
            <Redirect exact from="/receipts" to="/receipts/1" />
            <PrivateRoute
              path="/receipts/:page"
              component={ReceiptsContainer}
            />
            <PrivateRoute path="/limits" component={Limits} />
            <Redirect exact from="/player-limits" to="/limits?limit=money" />
            <Redirect exact from="/money-limits" to="/limits?limit=money" />
            <Redirect exact from="/login-time-limits" to="/limits?limit=time" />
            <Redirect exact from="/session-limits" to="/limits?limit=time" />
            <PrivateRoute path="/reality-check" component={RealityCheck} />
            <PrivateRoute path="/my-profile" component={PlayerProfile} />
            <PrivateRoute path="/logout" component={Logout} />
            <Redirect
              exact
              from="/my-bets"
              to="/sports/history?bt-path=%2Fbets"
            />
            {props.manualSignup && (
              <Redirect
                exact
                from="/password/:token"
                to="/password-recovery/create/:token"
              />
            )}
            {props.manualSignup && (
              <Route
                path="/password-recovery"
                exact
                component={ForgotPassword}
              />
            )}
            {props.manualSignup && (
              <Route
                path="/password-recovery/create/:token"
                component={CreateNewPassword}
              />
            )}
            {props.manualSignup && (
              <Route
                path="/password-recovery/reset-confirmation"
                component={ResetPasswordConfirmation}
              />
            )}
            <Route component={NotFound} />
          </Switch>
        </PageWrapper>
      </Switch>
      <Common.Box backgroundColor="darkblue" width="100%">
        <Footer />
      </Common.Box>
    </React.Fragment>
  )
}

Routes.propTypes = {
  locks: PropTypes.array,
  manualSignup: PropTypes.bool,
}
