import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { Box, Flex } from '@rushplay/common'

import { Amount } from '../../components/common/amount'
import { Card } from '../../components/common/card'
import { H1, H3 } from '../../components/common/headings'
import { Pagination } from '../../components/pagination'
import { Receipt } from '../receipt'
import { Spinner } from '../../components/common/spinner'

import { PendingTransactions } from './pending-transactions'

function BalanceReportItem(props) {
  return (
    <Box
      display={['block', null, 'flex']}
      justifyContent="space-between"
      paddingBottom={2}
    >
      <Box fontSize="1" paddingBottom={1}>
        {props.label}
      </Box>
      <Box fontSize="0" paddingLeft={1}>
        <Amount decimals="show">{props.value}</Amount>
      </Box>
    </Box>
  )
}

BalanceReportItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
}

export class ReceiptsContainer extends React.Component {
  constructor(props) {
    super(props)

    props.onFetch()
  }

  render() {
    return (
      <React.Fragment>
        {this.props.showPendingTransactions && <PendingTransactions />}

        <Box as={H1} textAlign="center" py={3}>
          {this.props.translate('receipts.headline')}
        </Box>
        {this.props.showTotalBalanceTransactions && (
          <React.Fragment>
            <Box maxWidth="600px" mx="auto" paddingTop={1}>
              <Card>
                <Box as={H3} textAlign="center" py={2}>
                  {this.props.translate('wallet.receipts.total-balance.title')}
                </Box>

                <BalanceReportItem
                  label={this.props.translate('wallet.receipts.total-deposits')}
                  value={this.props.balanceTotals.depositsCents}
                />
                <BalanceReportItem
                  label={this.props.translate(
                    'wallet.receipts.total-withdraws'
                  )}
                  value={this.props.balanceTotals.withdrawsCents}
                />
                <BalanceReportItem
                  label={this.props.translate(
                    'wallet.receipts.current-real-money-balance'
                  )}
                  value={this.props.currentRealBalance}
                />
                {this.props.totalDepositCents && (
                  <BalanceReportItem
                    label={this.props.translate(
                      'wallet.receipts.cumulative-deposits'
                    )}
                    value={this.props.totalDepositCents}
                  />
                )}
              </Card>
            </Box>
          </React.Fragment>
        )}

        <Box mx="auto" maxWidth="600px" flexGrow="1" paddingTop={1}>
          <Card>
            {R.isEmpty(this.props.items) ? (
              this.props.translate('wallet.receipts.no-transactions')
            ) : (
              <Flex flexDirection="column" wide>
                {this.props.loading ? (
                  <Spinner />
                ) : (
                  R.map(
                    receipt => (
                      <Receipt
                        key={receipt.transactionId}
                        amount={receipt.totalAmountCents}
                        currency={receipt.currency}
                        date={receipt.createdAt}
                        provider={
                          R.includes(receipt.subProvider, ['Trustly', 'swish'])
                            ? receipt.subProvider
                            : 'simplecasino.com'
                        }
                      />
                    ),
                    this.props.items
                  )
                )}
              </Flex>
            )}
          </Card>
          <Pagination
            base="/receipts"
            current={this.props.currentPage}
            total={this.props.totalPages}
            onNav={this.props.onNav}
          />
        </Box>
      </React.Fragment>
    )
  }
}

ReceiptsContainer.defaultProps = {
  onFetch: () => {},
  currentPage: 1,
}

ReceiptsContainer.propTypes = {
  balanceTotals: PropTypes.object,
  currency: PropTypes.string,
  children: PropTypes.object,
  currentPage: PropTypes.number,
  currentRealBalance: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  totalDepositCents: PropTypes.number,
  totalPages: PropTypes.number,
  translate: PropTypes.func.isRequired,
  showPendingTransactions: PropTypes.bool,
  showTotalBalanceTransactions: PropTypes.bool,
  onFetch: PropTypes.func,
  onNav: PropTypes.func,
}
