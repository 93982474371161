import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withTranslate } from '@rushplay/i18n'

import * as configuration from '../configuration'

import { Metadata as Component } from './metadata'

function mapStateToProps(state) {
  return {
    metaTitle: configuration.getMetadataTitle(state.configuration),
    metaDescription: configuration.getMetadataDescription(state.configuration),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: url => dispatch(configuration.fetchMetadata(url)),
  }
}

const connector = R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withTranslate
)

export const Metadata = connector(Component)
