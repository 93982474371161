import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Locks from '@rushplay/compliance/locks'

import * as Configuration from './configuration'
import { getPrefixedToken } from './session'

export function FastTrack() {
  const [isLoaded, setisLoaded] = React.useState(false)
  const prefixedToken = ReactRedux.useSelector(state =>
    getPrefixedToken(state.session)
  )
  const currentLocks = ReactRedux.useSelector(state =>
    Locks.getLocks(state.locks)
  )
  const fastTrackScript = ReactRedux.useSelector(state =>
    Configuration.getFastTrackScript(state.configuration)
  )
  React.useEffect(() => {
    // load script
    window.fasttrackbrand = 'herogaming'
    window.fasttrack = {
      integrationVersion: 1.1,
      autoInit: false,
      inbox: {
        enable: true,
      },
    }

    const fastTrackCrmScript = window.document.createElement('script')
    fastTrackCrmScript.src = fastTrackScript
    fastTrackCrmScript.async = true
    fastTrackCrmScript.onload = () => {
      new window.FastTrackLoader()
      setisLoaded(true)
    }

    document.body.appendChild(fastTrackCrmScript)
  }, [])

  React.useEffect(() => {
    if (currentLocks.length === 0 && prefixedToken && isLoaded) {
      // using interval to retry initialization if fast-track object was not yet attached
      const interval = setInterval(() => {
        if (window.FasttrackCrm) {
          clearInterval(interval)
          // re-initialize when prefix token is changed
          window.sid = prefixedToken
          window.FasttrackCrm && window.FasttrackCrm.init()
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [prefixedToken, isLoaded, currentLocks])

  return null
}
