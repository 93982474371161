import * as R from 'ramda'
import { connect } from 'react-redux'
import { mapProps } from 'recompose'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import {
  fetchReceipts,
  fetchTotalDepositsCents,
  fetchTransactionTotals,
} from '@rushplay/api-client'
import { getCurrency } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as receipts from '../'
import * as notifications from '../../notifications'
import { getTotalDepositsCents } from '../selectors'
import { isCancelPendingWithdrawalsVisible } from '../../combined-selectors'
import { updatePlayerBalanceTotals, updateTotalDepositsCents } from '../actions'

import { ReceiptsContainer as Component } from './container'

function mapStateToProps(state, props) {
  return {
    balanceTotals: R.pathOr({}, ['balanceTotals'], state.receipts),
    totalDepositCents: getTotalDepositsCents(state.receipts),
    currency: getCurrency(state.session),
    currentRealBalance: R.path(['player', 'moneyBalanceCents'], state.session),
    items: receipts.getAll(state.receipts),
    showTotalBalanceTransactions: jurisdiction.isBalanceTotalsVisible(
      state.jurisdiction
    ),
    loading: receipts.getCurrentPage(state.receipts) !== props.currentPage,
    totalPages: receipts.getTotalPages(state.receipts),
    showPendingTransactions: isCancelPendingWithdrawalsVisible(state),
  }
}

function mapDispatchToProps(dispatch, props) {
  const config = {
    success: res => receipts.update(res.value),
    failure: err => notifications.add({ message: err.val }, 'error'),
    version: 1,
  }

  return {
    onFetch: () =>
      dispatch([
        fetchTotalDepositsCents({
          success: res => updateTotalDepositsCents(res.value),
          version: 2,
        }),
        fetchReceipts(props.currentPage, config),
        fetchTransactionTotals({
          success: res => updatePlayerBalanceTotals(res.value),
          failure: () =>
            notifications.add(
              { message: 'speedy.receipts.failed-loading' },
              'error'
            ),
          version: 1,
        }),
      ]),
    onNav: page => dispatch(fetchReceipts(page, config)),
  }
}

const connector = R.compose(
  mapProps(props => ({
    currentPage: parseInt(R.pathOr('1', ['match', 'params', 'page'], props)),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate
)

export const ReceiptsContainer = connector(Component)
