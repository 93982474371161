
import * as types from './types'

export function reducer(state = [], action) {
  if (action.error) {
    return state
  }
  switch (action.type) {
    case types.ADDED: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
