import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import Url from 'url'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as FormComponents from '../../../form-components'
import { H2 } from '../../../components/common/headings'

const dataSchema = {
  type: 'object',
  properties: {
    verificationCode: {
      type: 'string',
      minLength: 4,
    },
  },
  required: ['verificationCode'],
}

function parsePhoneNumber(code, phoneNumber) {
  return `+${code} ${R.replace(
    /^0/,
    '',
    R.replace(/[^0-9]/g, '', phoneNumber)
  )}`
}

export function VerifyNumber(props) {
  const i18n = I18n.useI18n()

  return (
    <React.Fragment>
      <Common.Box mt="0px">
        <H2 align="center" color="black" weight={800}>
          {i18n.translate('collect-phone-number.step2.heading')}
        </H2>
      </Common.Box>
      <Common.Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        my={0}
        fontSize="1.1em"
        font="head"
        lineHeight={1.5}
      >
        {i18n.translate('collect-phone-number.step2.description1')}
        <Common.Text weight={800}>
          {parsePhoneNumber(props.countryCallingCode, props.phoneNumber)}
        </Common.Text>
      </Common.Box>

      <Forms.Provider
        name="verifyPhoneNumber"
        schema={dataSchema}
        onSubmit={(errors, data) => {
          if (R.isEmpty(errors)) {
            props.onVerify(props.token, data.verificationCode)
          }
        }}
      >
        <Common.Flex center column>
          <Common.Box width="200px" px="1">
            <FormComponents.InputField
              autoFocus
              autoComplete="one-time-code"
              inputmode="numeric"
              scope="#/properties/verificationCode"
              normalize={value => R.take(4, value || '')}
            />
          </Common.Box>
          <Common.Box display="flex" justifyContent="center" mt={2}>
            <Common.Text
              fontSize="1.1em"
              font="head"
              lineHeight={1.5}
              align="center"
            >
              {i18n.translate('collect-phone-number.step2.description2')}
            </Common.Text>
            <Common.Space ml="0">
              <Common.Text
                fontSize="1.1em"
                font="head"
                lineHeight={1.5}
                align="center"
                weight={800}
                textDecoration="underline"
                style={{ cursor: 'pointer' }}
                onClick={props.onResendVerificationCode}
              >
                {i18n.translate('collect-phone-number.step2.description3')}
              </Common.Text>
            </Common.Space>
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            width="200px"
            mt={2}
          >
            <FormComponents.SubmitButton stretched>
              {i18n.translate('collect-phone-number.step2.button.primary')}
            </FormComponents.SubmitButton>

            <Common.Box
              mt="1"
              textDecoration="underline"
              textAlign="center"
              style={{ cursor: 'pointer' }}
            >
              <Common.Link
                to={Url.format({
                  query: {
                    step: 1,
                    countryCallingCode: props.countryCallingCode,
                    phoneNumber: props.phoneNumber,
                    marketingOptOut: props.marketingOptOut,
                  },
                })}
              >
                {i18n.translate('collect-phone-number.step2.button.secondary')}
              </Common.Link>
            </Common.Box>
          </Common.Box>
        </Common.Flex>
      </Forms.Provider>
    </React.Fragment>
  )
}

VerifyNumber.propTypes = {
  countryCallingCode: PropTypes.number,
  marketingOptOut: PropTypes.bool,
  phoneNumber: PropTypes.string,
  token: PropTypes.string.isRequired,
  onResendVerificationCode: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
}
