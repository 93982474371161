import * as R from 'ramda'
import url from 'url'

/**
 *
 * @param {number} id Game id
 * @param {object} options Game options
 * @param {string} [options.referrer] Game referrer, "casino" by default
 * @param {string} [options.mode] Game mode, not added by default
 * @returns {string} Game URL
 */
export function getGameUrl(id, options = {}) {
  return id
    ? url.format({
        pathname: `/casino/games/${id}`,
        query: R.reject(R.isNil, {
          referrer: R.pathOr('casino', ['referrer'], options),
          mode: R.path(['mode'], options),
          free_games: R.path(['freeGames'], options),
        }),
      })
    : undefined
}
