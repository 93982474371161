import PropTypes from 'prop-types'
import React from 'react'
import { withTheme } from 'emotion-theming'

import * as t from '@rushplay/theme'
import { Global, css } from '@emotion/core'

function CssFontFaces() {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy.woff') format('woff'),
            url('/fonts/gilroy.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-italic.woff') format('woff'),
            url('/fonts/gilroy-italic.woff2') format('woff2');
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-semibold.woff') format('woff'),
            url('/fonts/gilroy-semibold.woff2') format('woff2');
          font-weight: 600;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-semibold-italic.woff') format('woff'),
            url('/fonts/gilroy-semibold-italic.woff2') format('woff2');
          font-weight: 600;
          font-style: italic;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-bold.woff') format('woff'),
            url('/fonts/gilroy-bold.woff2') format('woff2');
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-bold-italic.woff') format('woff'),
            url('/fonts/gilroy-bold-italic.woff2') format('woff2');
          font-weight: bold;
          font-style: italic;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-extrabold.woff') format('woff'),
            url('/fonts/gilroy-extrabold.woff2') format('woff2');
          font-weight: 800;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gilroy';
          src: url('/fonts/gilroy-extrabold-italic.woff') format('woff'),
            url('/fonts/gilroy-extrabold-italic.woff2') format('woff2');
          font-weight: 800;
          font-style: italic;
        }
      `}
    />
  )
}

function CssReset() {
  return (
    <Global
      styles={css`
        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        a {
          text-decoration: inherit;
          color: inherit;
        }
      `}
    />
  )
}

const CssBase = withTheme(props => (
  <Global
    styles={css`
      body {
        color: ${t.color('gray')(props)};
        font-family: ${t.font('body')(props)};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-smoothing: antialiased;

        font-size: 12px;

        ${t.mediaQuery('md')`
          font-size: 14px;
        `(props)}
        ${props.blockScroll
          ? `overflow: hidden; position: fixed; width: 100%;`
          : ``}
      }
    `}
  />
))

export function GlobalStyles(props) {
  return (
    <React.Fragment>
      <CssFontFaces />
      <CssReset />
      <CssBase blockScroll={props.blockScroll} />
    </React.Fragment>
  )
}

GlobalStyles.propTypes = {
  blockScroll: PropTypes.bool,
}
