import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export class Metadata extends React.Component {
  constructor(props) {
    super(props)
    if (!process.browser) {
      this.props.onFetch(this.props.location.pathname)
    }
  }

  render() {
    if (process.browser) {
      return (
        <Helmet>
          <title>{this.props.translate('meta.title')}</title>
        </Helmet>
      )
    }

    return (
      <Helmet>
        <title>{this.props.translate(this.props.metaTitle)}</title>
        {this.props.metaDescription && (
          <meta
            content={this.props.translate(this.props.metaDescription)}
            name="description"
          />
        )}
      </Helmet>
    )
  }
}

Metadata.propTypes = {
  location: PropTypes.object.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  onFetch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  translate: PropTypes.func,
}
