import * as R from 'ramda'
import { addDays } from 'date-fns'
import { bind } from 'redux-effects'
import { connect } from 'react-redux'
import { cookie } from 'redux-effects-universal-cookie'

import * as analytics from '@rushplay/analytics'
import * as i18n from '@rushplay/i18n'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as locks from '@rushplay/compliance/locks'
import * as offers from '@rushplay/offers'
import * as api from '@rushplay/api-client'
import * as Triggers from '@rushplay/triggers'
import { getPhoneVerification, getSessionToken } from '@rushplay/session'

import * as Countries from '../countries'
import * as configuration from '../configuration'
import * as Constants from '../constants'
import * as inventory from '../inventory'
import * as player from '../player'
import * as promotions from '../promotions'
import * as session from '../session'
import * as ui from '../ui'
import { analyticsRegistration } from '../analytics'
import {
  getBasename,
  getLanguage,
  getPlayerLanguage,
} from '../combined-selectors'

import { App as Component } from './app'

function mapStateToProps(state) {
  const btag = analytics.getBtag(state.analytics)

  const affiliateId = R.not(R.isNil(btag)) && R.head(btag.split('_'))

  return {
    affiliateId,
    basename: getBasename(state),
    cdnUrl: configuration.getCdnUrl(state.configuration),
    depositCount: player.getDepositCount(state.player),
    language:
      configuration.getCurrentLanguage(state.configuration) ||
      getLanguage(state),
    playerLanguage: getPlayerLanguage(state),
    preferredLanguage: getLanguage(state),
    sessionToken: getSessionToken(state.session),
    playerRestricted:
      getPhoneVerification(state.session) === 'restricted' ||
      session.getRequiresEmailValidation(state.session) === 'restricted',
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: props =>
      dispatch([
        api.fetchIpInfo({
          success: res =>
            configuration.init({ isIpInternal: res.value.internal }),
          version: 1,
        }),
        i18n.fetch(props.cdnUrl, props.language),
        jurisdiction.fetch(),
        Countries.fetch(),
        offers.fetchDepositOffers({ affiliateId: props.affiliateId }),
        props.token &&
          !props.playerRestricted && [player.fetch(), inventory.fetch()],
        props.token && locks.fetch(),
        props.token && promotions.fetch(),
        props.token && Triggers.fetch(),
        props.token &&
          props.depositCount &&
          cookie('deposit_count', props.depositCount, {
            httpOnly: false,
            // TODO: Replace new Date with timestamp from redux-effects-timestamp
            expires: addDays(new Date(), 180),
            path: '/',
          }),
        // Delete unused cookie
        // TODO: remove in 2020
        props.token &&
          props.depositCount &&
          cookie('already_deposited', null, { path: '/' }),
      ]),
    onRestore: () =>
      dispatch([
        bind(cookie('deposit_count'), depositCount =>
          player.updateDepositCount(depositCount)
        ),
        bind(cookie('cookie_consent'), cookieConsent =>
          player.updateCookieConsent(Boolean(cookieConsent))
        ),
      ]),
    onLanguageChange: (
      playerLanguage,
      preferredLanguage,
      shouldUpdatePreference
    ) => {
      dispatch(
        cookie('language', preferredLanguage, {
          httpOnly: false,
          // TODO: Replace new Date with timestamp from redux-effects-timestamp
          expires: addDays(new Date(), 180),
          path: '/',
        })
      )

      if (playerLanguage !== preferredLanguage) {
        if (shouldUpdatePreference) {
          dispatch(session.saveLanguagePreference(preferredLanguage))
        }
      }
    },
    onSessionChange: (sessionToken, playerRestricted, depositCount) =>
      dispatch([
        configuration.fetch(),
        jurisdiction.fetch(),
        offers.fetchDepositOffers(),
        sessionToken &&
          !playerRestricted && [
            analyticsRegistration(),
            player.fetch(),
            inventory.fetch(),
            Triggers.fetch(),
          ],
        sessionToken &&
          depositCount == 0 &&
          !playerRestricted &&
          ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true),
        sessionToken && [
          analytics.authenticate(),
          locks.fetch(),
          promotions.fetch(),
        ],
        ui.toggleVisibility('notifications', false),
      ]),
    onSessionCheck: token => dispatch(session.keepAlive(token)),
    onPlayerRestrictionRemoved: (sessionToken, playerRestricted) => {
      if (sessionToken && !playerRestricted) {
        dispatch([analyticsRegistration(), player.fetch(), inventory.fetch()])
      }
    },
  }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return R.merge(
    {
      basename: stateProps.basename,
      playerRestricted: stateProps.playerRestricted,
      sessionToken: stateProps.sessionToken,
      onFetch: () =>
        dispatchProps.onFetch({
          affiliateId: stateProps.affiliateId,
          cdnUrl: stateProps.cdnUrl,
          depositCount: stateProps.depositCount,
          language: stateProps.language,
          playerRestricted: stateProps.playerRestricted,
          token: stateProps.sessionToken,
        }),
      onLanguageChange: () =>
        dispatchProps.onLanguageChange(
          stateProps.playerLanguage,
          stateProps.preferredLanguage,
          Boolean(stateProps.sessionToken)
        ),
      onRestore: () => dispatchProps.onRestore(),
      onSessionChange: () =>
        dispatchProps.onSessionChange(
          stateProps.sessionToken,
          stateProps.playerRestricted,
          stateProps.depositCount
        ),
      onSessionCheck: () =>
        dispatchProps.onSessionCheck(stateProps.sessionToken),
      onPlayerRestrictionRemoved: () =>
        dispatchProps.onPlayerRestrictionRemoved(
          stateProps.sessionToken,
          stateProps.playerRestricted
        ),
    },
    ownProps
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps)

export default connector(Component)
