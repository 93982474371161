import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'

import * as Session from '@rushplay/session'

import * as Configuration from './configuration'

function getPayerConfig(state) {
  return {
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getSessionToken(state.session),
    userId: Session.getUsername(state.session),
  }
}

export function usePendingTransactions() {
  const [pendingTransactions, setPendingTransactions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const config = ReactRedux.useSelector(getPayerConfig)

  const pendingTransactionsUrl = Url.format({
    pathname: '/api/pending-transactions',
    query: {
      token: config.token,
      user_id: config.userId,
    },
  })

  const onCancel = React.useCallback(
    id => {
      const apiUrl = Url.format({
        pathname: `/api/pending-transactions/${id}`,
        query: {
          token: config.token,
          user_id: config.userId,
        },
      })

      setDeleting(true)

      fetch(`${config.host}${apiUrl}`, { method: 'DELETE' })
        .then(() => {
          setDeleting(false)
        })
        .catch(() => {
          setDeleting(false)
        })
    },
    [pendingTransactions, config.host, config.token, config.userId]
  )

  React.useEffect(() => {
    // If there's no userId or token there's no point in fetching
    if (config.userId && config.token) {
      if (!deleting) {
        setLoading(true)
        const controller = new AbortController()

        fetch(`${config.host}${pendingTransactionsUrl}`, {
          method: 'GET',
          signal: controller.signal,
        })
          .then(res => res.json())
          .then(body => {
            const data = body.data.map(item => ({
              ...item,
              onCancel,
            }))
            setPendingTransactions(data)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })

        const id = setTimeout(() => controller.abort(), 5000)

        return () => {
          clearTimeout(id)
          controller.abort()
        }
      }
    }
  }, [config.host, config.userId, config.token, deleting])

  return { pendingTransactions, loading }
}
