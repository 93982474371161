import * as api from '@rushplay/api-client'

import * as notifications from '../../notifications'

export function requestSmsVerification() {
  return api.requestSmsVerification({
    failure: () =>
      notifications.add(
        {
          message: 'error.verify-phone-number.request-sms-verification-code',
        },
        'error'
      ),
    version: 1,
  })
}
