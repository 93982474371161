import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as locks from '@rushplay/compliance/locks'

import * as notifications from '../../notifications'

import { TermsAndConditionsConsent as Component } from './terms-and-conditions-consent'

function mapStateToProps(state) {
  return {
    license: jurisdiction.getLicense(state.jurisdiction),
  }
}

const connector = R.compose(
  connect(mapStateToProps, {
    onAccept: () =>
      api.acceptPrivacyPolicy({
        success: () =>
          api.acceptTermsAndConditions({
            success: () => [
              locks.fetch(),
              locks.remove('terms-and-conditions-consent'),
            ],
            failure: () =>
              notifications.add({ message: 'errors.general.unknown' }, 'error'),
            version: 1,
          }),
        failure: () =>
          notifications.add({ message: 'errors.general.unknown' }, 'error'),
        version: 1,
      }),
  })
)

export const TermsAndConditionsConsent = connector(Component)
