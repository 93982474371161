import {
  CAD,
  DKK,
  EUR,
  INR,
  NOK,
  SEK,
  USD,
  createAmountComponent,
} from '@rushplay/common'

export const currencies = { CAD, DKK, EUR, INR, NOK, SEK, USD }

export const Amount = createAmountComponent({
  currencies: [CAD, DKK, EUR, INR, NOK, SEK, USD],
})
