import PropTypes from 'prop-types'
import React from 'react'

import { LandingPage } from '../../landing-page'
import { Redirect } from '../redirect'

export function HomePage(props) {
  if (props.authenticated) {
    return <Redirect to={props.url} />
  } else {
    return <LandingPage />
  }
}

HomePage.propTypes = {
  authenticated: PropTypes.bool,
  url: PropTypes.string,
}
