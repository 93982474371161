import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withTranslate } from '@rushplay/i18n'

import { Component } from './form'

export { SelfExclusionConfirmation } from './confirmation'

function mapStatetoProps(state) {
  return {
    licenseId: jurisdiction.getLicense(state.jurisdiction),
    crossbrand: jurisdiction.isSelfExclusionCrossbrand(state.jurisdiction),
  }
}

export const connector = connect(mapStatetoProps)

export const SelfExclusionForm = withTranslate(connector(Component))
