import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

function borderColor(props) {
  if (props.focused) {
    return 'inputFocused'
  } else if (props.valid) {
    return 'inputValid'
  } else if (props.invalid) {
    return 'inputInvalid'
  } else {
    return 'input'
  }
}

const Wrapper = styled('label', {
  shouldForwardProp: Common.noneOf([
    'disabled',
    'empty',
    'focused',
    'invalid',
    'valid',
  ]),
})`
  ${props =>
    css({
      position: 'relative',
      textAlign: 'left',
      display: 'flex',
      backgroundColor: props.disabled ? 'gray1' : 'white',
      transition: 'border 300ms ease-in-out',
      fontSize: '14px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: borderColor(props),
      overflow: 'hidden',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    })}

  &:disabled {
    cursor: not-allowed;
  }
`

const InputField = styled.input`
  ${props =>
    css({
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      lineHeight: '24px',
      color: props.disabled && 'gray8',
      fontFamily: 'body',
      fontSize: '14px',
      py: '12px',
      px: '16px',
      '&::placeholder': {
        color: 'gray6',
      },
    })}

  &:focus {
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export function Input(props) {
  const inputRef = React.useRef(null)
  const fieldRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)

  const value = props.value && props.value.toString()
  const valid = props.valid && props.visited
  const invalid = !props.valid && props.visited

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused, inputRef])

  React.useEffect(() => {
    // Allow horizontal scroll with a mousewheel
    if (fieldRef.current) {
      fieldRef.current.addEventListener('wheel', evt => {
        evt.preventDefault()
        fieldRef.current.scrollLeft += evt.deltaY
      })
    }
  }, [fieldRef.current])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper
      empty={R.isEmpty(props.value)}
      valid={valid}
      className={props.className}
      invalid={invalid}
      focused={focused}
      disabled={props.disabled}
      ref={inputRef}
    >
      <InputField
        ref={fieldRef}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        id={props.id}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        placeholder={props.placeholder}
        type={props.type}
        value={value}
        hasStatus={valid || invalid}
        onChange={props.onChange}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
      />
      {props.children}
    </Wrapper>
  )
}

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputMode: PropTypes.oneOf(['numeric', 'email', 'decimal', 'tel']),
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  valid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}
