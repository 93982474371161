import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as FormComponents from '../../form-components'
import { Card } from '../../components/common/card'
import { H1 } from '../../components/common/headings'
import { PrimaryButtonLink } from '../../components/common/button-link'

import { SelfExclusion } from './self-exclusion'

const dataSchema = {
  type: 'object',
  properties: {
    period: {
      type: 'string',
    },
  },
  required: ['period'],
}

function SubmitButton() {
  const i18n = I18n.useI18n()
  const period = Forms.useField('#/properties/period', { noRegister: true })

  return (
    <PrimaryButtonLink
      stretched
      to={`/self-exclusion/confirmation?period=${period.value}`}
    >
      {i18n.translate('player-limits.confirm')}
    </PrimaryButtonLink>
  )
}

export function Component(props) {
  const i18n = I18n.useI18n()

  return (
    <SelfExclusion>
      {exclusion => (
        <React.Fragment>
          <Common.Space py={1}>
            <H1 align="center">
              {i18n.translate('self-exclusion-period.headline')}
            </H1>
          </Common.Space>
          <Common.Box maxWidth="420px" margin="auto">
            <Card>
              <Common.Text
                textAlign="center"
                color="gray"
                fontSize={1}
                lineHeight={1.5}
              >
                {i18n.translate(
                  `self-exclusion-period.${props.licenseId}.description`
                )}
              </Common.Text>
              <Forms.Provider name="self-exclusion" schema={dataSchema}>
                <Common.Space paddingTop={1} paddingBottom={2}>
                  <FormComponents.SelectField
                    scope="#/properties/period"
                    initialValue={R.path([0, 'value'], exclusion.options)}
                    options={R.map(
                      option => ({
                        label: option.labelKey,
                        value: option.value,
                      }),
                      exclusion.options
                    )}
                  />
                </Common.Space>
                {props.crossbrand && (
                  <Common.Space py={1}>
                    <Common.Text textAlign="center">
                      {i18n.translate(
                        'player-self-exclusion.crossbrand-prompt'
                      )}
                    </Common.Text>
                  </Common.Space>
                )}
                <SubmitButton />
              </Forms.Provider>
            </Card>
          </Common.Box>
        </React.Fragment>
      )}
    </SelfExclusion>
  )
}

Component.propTypes = {
  crossbrand: PropTypes.bool,
  licenseId: PropTypes.string,
}
