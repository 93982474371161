import * as React from 'react'

/* @jsx UiSchema.createElement */
import { UiSchema } from '@rushplay/forms'

export const step1 = (
  <UiSchema.Box display="grid" gridGap={0}>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.email.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'email',
          inputMode: 'email',
        }}
        scope="#/properties/email"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.password.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'new-password',
        }}
        scope="#/properties/password"
      />
    </UiSchema.Box>
    <UiSchema.Box display="grid" gridTemplateColumns="80px 1fr" gridGap={1}>
      <UiSchema.Box overflow="hidden" pb={0}>
        <UiSchema.Box fontWeight="600" lineHeight="24px">
          <UiSchema.Text content="registration.code.title" />
        </UiSchema.Box>
        <UiSchema.Control
          forwardProps={{
            autoComplete: 'tel-country-code',
          }}
          scope="#/properties/countryCallingCode"
        />
      </UiSchema.Box>
      <UiSchema.Box overflow="hidden" pb={0}>
        <UiSchema.Box fontWeight="600" lineHeight="24px">
          <UiSchema.Text content="registration.mobile.title" />
        </UiSchema.Box>
        <UiSchema.Control
          forwardProps={{
            autoComplete: 'tel',
            inputMode: 'tel',
          }}
          scope="#/properties/phonenumber"
        />
      </UiSchema.Box>
    </UiSchema.Box>
  </UiSchema.Box>
)

export const step2 = (
  <UiSchema.Box display="grid" gridGap={0} color="g-text">
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.first-name.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'given-name',
        }}
        scope="#/properties/firstName"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.last-name.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'family-name',
        }}
        scope="#/properties/lastName"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.street.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'street-address',
        }}
        scope="#/properties/street"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.zip.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'postal-code',
        }}
        scope="#/properties/zip"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.city.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'address-level2',
        }}
        scope="#/properties/city"
      />
    </UiSchema.Box>
    <UiSchema.Box overflow="hidden" pb={0}>
      <UiSchema.Box fontWeight="600" lineHeight="24px">
        <UiSchema.Text content="registration.country.title" />
      </UiSchema.Box>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'country-name',
        }}
        scope="#/properties/country"
      />
    </UiSchema.Box>
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="1fr 114px 1fr"
      gridGap={1}
      pb={0}
    >
      <UiSchema.Box overflow="hidden" pb={0}>
        <UiSchema.Box fontWeight="600" lineHeight="24px">
          <UiSchema.Text content="registration.b-day.title" />
        </UiSchema.Box>
        <UiSchema.Control
          forwardProps={{
            autoComplete: 'bday-day',
          }}
          scope="#/properties/bdayDay"
        />
      </UiSchema.Box>
      <UiSchema.Box overflow="hidden" pb={0}>
        <UiSchema.Box fontWeight="600" lineHeight="24px">
          <UiSchema.Text content="registration.b-month.title" />
        </UiSchema.Box>
        <UiSchema.Control
          forwardProps={{
            autoComplete: 'bday-month',
          }}
          scope="#/properties/bdayMonth"
        />
      </UiSchema.Box>
      <UiSchema.Box overflow="hidden" pb={0}>
        <UiSchema.Box fontWeight="600" lineHeight="24px">
          <UiSchema.Text content="registration.b-year.title" />
        </UiSchema.Box>
        <UiSchema.Control
          forwardProps={{
            autoComplete: 'bday-year',
            maxLength: '4',
          }}
          scope="#/properties/bdayYear"
        />
      </UiSchema.Box>
    </UiSchema.Box>
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'one-time-code',
      }}
      scope="#/properties/phoneVerificationCode"
    />
    <UiSchema.Control scope="#/properties/promotional" />
  </UiSchema.Box>
)
