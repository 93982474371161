import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import { isSessionActive } from '@rushplay/session'

import * as Constants from '../../constants'
import * as Ui from '../../ui'

import { SidebarButtons as Component } from './sidebar-buttons'

function mapStateToProps(state) {
  return {
    authenticated: isSessionActive(state.session),
    manualSignup: Jurisdiction.getManualSignUpAllowed(state.jurisdiction),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClose: () => Ui.toggleVisibility('sidebar', false),
      onLogin: () => [Ui.toggleVisibility('sidebar', false), push('/login')],
      onRegister: () => [
        Ui.toggleVisibility('sidebar', false),
        Ui.toggleVisibility('register', true),
      ],
      onShowDeposit: () => [
        Ui.toggleVisibility('sidebar', false),
        Ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true),
      ],
      onShowWithdrawal: () => [
        Ui.toggleVisibility('sidebar', false),
        Ui.toggleVisibility('withdrawal', true),
      ],
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const SidebarButtons = connector(Component)
