import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

function Svg(props) {
  return (
    <Common.Box as="svg" {...props} width={props.size} height={props.size} />
  )
}

Svg.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export function ChevronDown(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" {...props}>
      <path
        d="M7.99978 9.33511C7.82216 9.33511 7.65178 9.26439 7.52613 9.13901L0.196306 1.80838C-0.0654353 1.54664 -0.0654353 1.12255 0.196306 0.861345C0.458047 0.599604 0.881869 0.599604 1.14334 0.861345L7.99978 7.71859L14.8568 0.861881C15.1185 0.60014 15.5423 0.60014 15.8038 0.861881C16.0653 1.12362 16.0655 1.54744 15.8038 1.80892L8.47343 9.13874C8.34779 9.26439 8.1774 9.33511 7.99978 9.33511Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export function Done(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
        fill="currentColor"
        strokeWidth="2"
        stroke="currentColor"
      />
    </Svg>
  )
}

export function ArrowEast(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 22" {...props}>
      <g fill="none" stroke="currentColor">
        <path d="M0.166748 12.5L18.1667 12.5" strokeWidth="2" />
        <path d="M13.1667 6.5L19.1667 12.5L13.1667 18.5" strokeWidth="2" />
      </g>
    </Svg>
  )
}

export function Smartphone(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 121" {...props}>
      <g fill="currentColor">
        <path d="M86.06 0H34.94C30.012 0 26 3.987 26 8.883v103.232c0 4.898 4.012 8.885 8.94 8.885h51.118c4.93 0 8.942-3.987 8.942-8.883V8.883C95 3.987 90.988 0 86.06 0zm-56 16.133h60.88v76.634H30.06zm4.88-12.1h51.12c2.692 0 4.882 2.176 4.882 4.85V12.1H30.06V8.883c0-2.674 2.19-4.85 4.88-4.85zm51.12 112.934H34.94c-2.691 0-4.881-2.176-4.881-4.85V96.8H90.94v15.317c0 2.674-2.19 4.85-4.88 4.85z" />
        <path d="M61 99c-4.412 0-8 3.588-8 8s3.588 8 8 8 8-3.588 8-8-3.588-8-8-8zm0 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
        <path d="M52 10h8c1.106 0 2-.894 2-2s-.894-2-2-2h-8c-1.106 0-2 .894-2 2s.894 2 2 2zm15 0h2c1.106 0 2-.894 2-2s-.894-2-2-2h-2c-1.106 0-2 .894-2 2s.894 2 2 2zm47.351-.412a1.933 1.933 0 0 0-2.775 0 2.031 2.031 0 0 0 0 2.833c7.333 7.487 7.333 19.67 0 27.158a2.031 2.031 0 0 0 0 2.834 1.937 1.937 0 0 0 2.775 0c8.865-9.048 8.865-23.775 0-32.825zm-9.108 4.012a1.802 1.802 0 0 0-2.686 0c-.743.802-.743 2.097 0 2.898 2.35 2.534 3.644 5.91 3.644 9.503 0 3.594-1.294 6.968-3.644 9.502-.743.801-.743 2.096 0 2.897.37.4.857.6 1.343.6s.973-.2 1.343-.6C108.311 35.093 110 30.689 110 26c0-4.687-1.687-9.094-4.757-12.4zM9.424 12.421a2.031 2.031 0 0 0 0-2.833 1.933 1.933 0 0 0-2.775 0c-8.865 9.05-8.865 23.775 0 32.825a1.937 1.937 0 0 0 2.775 0 2.031 2.031 0 0 0 0-2.834c-7.335-7.487-7.335-19.669 0-27.158zm9.019.179a1.802 1.802 0 0 0-2.686 0C12.689 15.908 11 20.313 11 25s1.689 9.091 4.757 12.4c.37.4.857.6 1.343.6s.972-.2 1.343-.6c.743-.801.743-2.096 0-2.897-2.35-2.534-3.644-5.91-3.644-9.504 0-3.593 1.292-6.967 3.644-9.501.74-.8.74-2.096 0-2.897z" />
      </g>
    </Svg>
  )
}

export function CheckCirleOutline(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </g>
    </Svg>
  )
}

export function Clock(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.316A6.323 6.323 0 0 1 1.684 8 6.323 6.323 0 0 1 8 1.684 6.323 6.323 0 0 1 14.316 8 6.323 6.323 0 0 1 8 14.316z"
        />
        <path d="M12.327 7.916H9.39a1.342 1.342 0 0 0-.427-.454V3.714C8.963 3.32 8.662 3 8.29 3c-.372 0-.673.32-.673.714v3.748A1.39 1.39 0 0 0 7 8.63C7 9.386 7.579 10 8.29 10c.465 0 .873-.263 1.1-.655h2.937c.372 0 .673-.32.673-.715 0-.394-.301-.714-.673-.714z" />
      </g>
    </Svg>
  )
}

export function Close(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <g fill="currentColor" stroke="currentColor" fillRule="none">
        <path d="M0.992752 14.1045L6.10412 8.99316L7.57894 10.468L2.46757 15.5793L0.992752 14.1045ZM8.28605 9.76087L6.81123 8.28605L8.28605 6.81123L9.76087 8.28605L8.28605 9.76087ZM7.57894 6.10412L6.10412 7.57894L0.992751 2.46757L2.46757 0.992751L7.57894 6.10412ZM10.468 7.57894L8.99316 6.10412L14.1045 0.992752L15.5793 2.46757L10.468 7.57894ZM8.99316 10.468L10.468 8.99316L15.5793 14.1045L14.1045 15.5793L8.99316 10.468Z" />
      </g>
    </Svg>
  )
}

export function NotificationBell(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" {...props}>
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <path
          d="M11.73 20C11.5542 20.3031 11.3018 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64962 20.9965 9.30539 20.9044 9.00177 20.7295C8.69816 20.5547 8.44581 20.3031 8.27 20M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
          stroke="#F57A00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  )
}

export function Lock(props) {
  return (
    <Svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M40.163 24.624H38.4v-9.857C38.4 6.624 31.776 0 23.633 0S8.865 6.624 8.865 14.767v9.857H7.102c-3.857 0-7.004 3.135-7.004 7.005v21.367C.098 56.853 3.233 60 7.102 60h33.061c3.857 0 7.004-3.135 7.004-7.004V31.629c-.012-3.87-3.147-7.005-7.004-7.005zm-27.098-9.857c0-5.828 4.74-10.567 10.568-10.567C29.46 4.2 34.2 8.939 34.2 14.767v9.857H13.065v-9.857zm29.89 38.217a2.812 2.812 0 01-2.804 2.804H7.102a2.812 2.812 0 01-2.804-2.804V31.629a2.812 2.812 0 012.804-2.805h33.061a2.812 2.812 0 012.804 2.805v21.355h-.012z" />
        <path d="M23.633 37.616a2.096 2.096 0 00-2.094 2.106v5.425c0 1.163.943 2.094 2.094 2.094a2.103 2.103 0 002.106-2.094v-5.425a2.114 2.114 0 00-2.106-2.106z" />
      </g>
    </Svg>
  )
}

export function Help(props) {
  return (
    <Svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M47.695 0h-35.39C5.52 0 0 5.52 0 12.305v35.39C0 54.48 5.52 60 12.305 60h35.39C54.48 60 60 54.48 60 47.695v-35.39C60 5.52 54.48 0 47.695 0zM10.08 12.566l7.482 7.482a15.86 15.86 0 00-3.5 9.952 15.86 15.86 0 003.5 9.952l-7.482 7.482A26.373 26.373 0 013.516 30c0-6.67 2.48-12.772 6.564-17.434zM42.422 30c0 6.85-5.573 12.422-12.422 12.422-6.85 0-12.422-5.573-12.422-12.422 0-6.85 5.573-12.422 12.422-12.422 6.85 0 12.422 5.573 12.422 12.422zM20.048 42.438a15.86 15.86 0 009.952 3.5 15.86 15.86 0 009.952-3.5l7.482 7.482A26.374 26.374 0 0130 56.484c-6.67 0-12.772-2.48-17.434-6.564l7.482-7.482zm29.872 4.996l-7.482-7.482a15.86 15.86 0 003.5-9.952 15.86 15.86 0 00-3.5-9.952l7.482-7.482A26.374 26.374 0 0156.484 30c0 6.67-2.48 12.772-6.564 17.434zm-9.968-29.872a15.86 15.86 0 00-9.952-3.5 15.86 15.86 0 00-9.952 3.5l-7.482-7.482A26.373 26.373 0 0130 3.516c6.67 0 12.772 2.48 17.434 6.564l-7.482 7.482zM3.516 12.305c0-4.847 3.942-8.79 8.789-8.79h3.616A30.211 30.211 0 003.516 15.922v-3.616zm8.789 44.18c-4.847 0-8.79-3.943-8.79-8.79V44.08a30.211 30.211 0 0012.406 12.405h-3.616zm44.18-8.79c0 4.847-3.943 8.79-8.79 8.79H44.08a30.211 30.211 0 0012.405-12.406v3.616zM44.078 3.515h3.616c4.847 0 8.79 3.943 8.79 8.79v3.616A30.211 30.211 0 0044.078 3.516z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </Svg>
  )
}
