import { connect } from 'react-redux'

import * as processes from '@rushplay/processes'

import { Spinner as Component } from './spinner'

function mapDispatchToProps(dispatch) {
  return {
    onMount: id => dispatch(processes.start(id)),
    onUnmount: id => dispatch(processes.stop(id)),
  }
}

const connector = connect(
  null,
  mapDispatchToProps
)

export const Spinner = connector(Component)
