import * as R from 'ramda'
import { connect } from 'react-redux'

import { withTranslate } from '@rushplay/i18n'

import * as selectors from './selectors'
import { Notifications as Component } from './notifications'

function mapStateToProps(state) {
  return {
    notifications: selectors.getAll(state.notifications),
  }
}

const connector = R.compose(
  withTranslate,
  connect(mapStateToProps)
)
export const Notifications = connector(Component)

export * from './reducer'
