import * as R from 'ramda'

import * as api from '@rushplay/api-client'

import * as types from './types'

export function clearInventory() {
  return { type: types.CLEAR_INVENTORY }
}

export function updateInventory(payload) {
  return {
    type: types.UPDATE_INVENTORY,
    payload: R.reduce(
      (acc, item) =>
        R.includes(R.path(['type'], item), [
          'FeatureTriggers',
          'Freebet',
          'FreeGamesVoucher',
          'Freespins',
          'LotteryBagItem',
          'LotteryTicketItem',
        ])
          ? R.assoc(
              R.path(['id'], item),
              R.pick(
                [
                  'active',
                  'activeFrom',
                  'amount',
                  'betAmountCents',
                  'blitz',
                  'createdAt',
                  'expiresAt',
                  'gameDisabledForClientType',
                  'gameId',
                  'gameImage',
                  'gameTitle',
                  'id',
                  'lottery_bag',
                  'redirectUrl',
                  'seen',
                  'type',
                ],
                item
              ),
              acc
            )
          : acc,
      {},
      payload
    ),
  }
}

export function updateInventoryItem(item) {
  return {
    type: types.UPDATE_INVENTORY_ITEM,
    payload: item,
  }
}

export function removeInventoryItem(id) {
  return {
    type: types.REMOVE_INVENTORY_ITEM,
    payload: id,
  }
}

export function markAllInventoryAsSeen() {
  return { type: types.MARK_ALL_INVENTORY_SEEN }
}

export function markInventoryItemAsSeen(id) {
  return {
    type: types.MARK_INVENTORY_ITEM_SEEN,
    payload: id,
  }
}

export function fetch() {
  return api.fetchInventory({
    success: res => updateInventory(res.value),
    version: 1,
  })
}
