import PropTypes from 'prop-types'
import React from 'react'

import { Box, Flex, Space, Text } from '@rushplay/common'

import { H1 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function FreeText(props) {
  return (
    <Box maxWidth="600px" margin="auto">
      <H1 align="center">{props.title}</H1>
      <Space py={1}>
        <Text textAlign="center">
          <HtmlContent html={{ __html: props.message }} />
        </Text>
      </Space>
      <Flex justifyContent="center">
        <PrimaryButton onClick={props.onAccept}>
          {props.translate('ok')}
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

FreeText.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}
