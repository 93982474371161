import * as R from 'ramda'

const PhoneNumberFormats = {
  DE: {
    format: /(.{3})(.{2})(.*)/,
    placeholder: '$1 $2 $3',
  },
  FI: {
    format: /(.{4})(.{2})(.{3})(.{2})(.*)/,
    placeholder: '$1 $2 $3 $4 $5',
  },
  SE: {
    format: /(.{3})(.{3})(.{3})(.*)/,
    placeholder: '$1 $2 $3 $4',
  },
  default: {
    format: /(.*)/,
    placeholder: '$1',
  },
}

/**
 * @param {string} countryCode
 * @param {string} phoneNumber International phone number
 */
export function toPhoneNumberFormat(countryCode, phoneNumber) {
  return R.replace(
    R.pathOr(
      PhoneNumberFormats['default'].format,
      [countryCode, 'format'],
      PhoneNumberFormats
    ),
    R.pathOr(
      PhoneNumberFormats['default'].placeholder,
      [countryCode, 'placeholder'],
      PhoneNumberFormats
    ),
    phoneNumber
  )
}
