import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

//TODO: clean up buttons after the code split

const BaseButton = styled('button', {
  shouldForwardProp: Common.noneOf(['small', 'stretched']),
})`
  display: inline-block;
  cursor: pointer;
  outline: none;

  text-align: center;
  text-decoration: none;
  font-family: ${t.font('head')};
  font-size: 16px;
  font-weight: bold;

  background-color: transparent;

  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 9999px;

  padding: ${t.spacing(1)} ${t.spacing(1)};
  ${props => props.stretched && 'width: 100%;'};

  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover,
  &:disabled:active,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${t.color('gray1')};
    border-color: ${t.color('gray1')};
  }

  ${props =>
    props.small &&
    `
    padding: ${t.spacing(0)(props)} ${t.spacing(1)(props)};
    font-size: ${t.fontSize(0)(props)};
  `}
`

export const PrimaryButton = styled(BaseButton)`
  background-color: #2db200;
  border-color: #2db200;
  color: ${t.color('white')};
  text-shadow: 0 2px 4px ${t.color('black', 0.2)};

  &:hover,
  &:active {
    background-color: #2fbd00;
    border-color: #2fbd00;
  }

  &:active {
    box-shadow: inset 0 1px 9px 0 rgba(0, 0, 0, 0.2);
  }

  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover,
  &:disabled:active,
  &[disabled]:active {
    color: ${t.color('white')};
    background-color: ${t.color('gray1')};
  }
`

export const AltButtonInverted = styled(BaseButton)`
  background-color: transparent;
  border-color: ${t.color('darkgray')};
  color: ${t.color('darkgray')};
  font-weight: 600;
  line-height: 24px;

  &:hover {
    color: ${t.color('darkblue')};
    border-color: ${t.color('darkblue')};
  }
`

export const PrimaryButtonLink = PrimaryButton.withComponent(Common.Link, {
  shouldForwardProp: Common.noneOf(['stretched', 'small']),
})

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  stretched: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
}
