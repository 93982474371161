import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import {
  AltButtonInverted,
  PrimaryButton,
} from '../../components/common/button'
import { CurrentSessionTime } from '../../components/current-session-time'
import { Item } from '../item'
import { PrimaryButtonLink } from '../../components/common/button-link'

const ExitWrapper = styled.div`
  &:hover {
    color: black;
  }
`

export function SidebarButtons(props) {
  return (
    <Common.Space py={2}>
      <Common.Flex flexDirection="column">
        {!props.manualSignup && !props.authenticated && (
          <Item
            as={PrimaryButtonLink}
            stretched
            to="/deposit"
            title="side-bar.cta"
          />
        )}

        {props.manualSignup && !props.authenticated && (
          <React.Fragment>
            <Item
              as={PrimaryButtonLink}
              stretched
              to="/login"
              title="login-form.login"
            />
            <Common.Box display="flex" justifyContent="center" pt="2">
              <Item as={Common.Text} title="sidebar.not-a-member" />
              <Item
                as={Common.Text}
                cursor="pointer"
                textAlign="center"
                color="simpleorange"
                onClick={props.onRegister}
                title="sidebar.register-here"
                fontSize="16px"
              />
            </Common.Box>
          </React.Fragment>
        )}

        {props.authenticated && (
          <React.Fragment>
            <Item
              as={PrimaryButton}
              stretched
              onClick={props.onShowDeposit}
              title="side-bar.deposit"
            />
            <Common.Space pt={2}>
              <Item
                as={AltButtonInverted}
                stretched
                onClick={props.onShowWithdrawal}
                title="side-bar.cashout"
              />
              <Common.Space pt={0}>
                <Common.Space pb={1}>
                  <Common.Text fontSize="0">
                    <CurrentSessionTime withContent />
                  </Common.Text>
                </Common.Space>
                <ExitWrapper>
                  <Common.Text textAlign="center">
                    <Item as={Common.Link} to="/logout" title="side-bar.quit" />
                  </Common.Text>
                </ExitWrapper>
              </Common.Space>
            </Common.Space>
          </React.Fragment>
        )}
      </Common.Flex>
    </Common.Space>
  )
}

SidebarButtons.propTypes = {
  authenticated: PropTypes.bool,
  manualSignup: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  onShowDeposit: PropTypes.func.isRequired,
  onShowWithdrawal: PropTypes.func.isRequired,
}
