import PropTypes from 'prop-types'
import React from 'react'

import { Box, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { H1 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function BonusTermsConditions() {
  const i18n = useI18n()

  return (
    <Box maxWidth="800" margin="auto" position="relative">
      <Box
        paddingBottom={[1, null, 3]}
        display="flex"
        justifyContent="center"
        position="sticky"
        top="88px"
        backgroundColor="grayish"
      >
        <PrimaryButton
          onClick={() => {
            if (window) {
              window.close()
            }
          }}
        >
          {i18n.translate('bonus.terms-conditions.close')}
        </PrimaryButton>
      </Box>
      <Space paddingBottom={[0, null, 4]}>
        <H1 align="center" color="gray">
          {i18n.translate('terms-conditions-bonus.title')}
        </H1>
      </Space>
      <HtmlContent
        html={{
          __html: i18n.translate('terms-conditions-bonus.content'),
        }}
      />
    </Box>
  )
}

BonusTermsConditions.propTypes = {
  match: PropTypes.object.isRequired,
}
