import { connect } from 'react-redux'

import { updateClientType } from '../configuration'

import { ClientType as Component } from './client-type'

export * from './types'

const connector = connect(
  null,
  { onUpdateClientType: updateClientType }
)

export const ClientType = connector(Component)
