import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Space } from '@rushplay/common'

import { Fade } from '../components/common/fade'

import { Notification } from './notification'

// z-index has to be greater than the header
const Wrapper = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

export function Notifications(props) {
  return (
    <Wrapper>
      <Fade in={R.not(R.isEmpty(props.notifications))}>
        <React.Fragment>
          {R.map(
            id => (
              <Space py={0} key={id}>
                <Notification id={id} />
              </Space>
            ),
            props.notifications
          )}
        </React.Fragment>
      </Fade>
    </Wrapper>
  )
}

Notifications.propTypes = { notifications: PropTypes.array.isRequired }
