import PropTypes from 'prop-types'
import React from 'react'

export class WebSocket extends React.Component {
  componentDidMount() {
    this.props.onInit()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionToken !== this.props.sessionToken) {
      this.props.onInit()
    }
  }

  render() {
    return null
  }
}

WebSocket.propTypes = {
  sessionToken: PropTypes.string,
  onInit: PropTypes.func,
}
