import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import * as Common from '@rushplay/common'
import * as Icons from '@rushplay/icons'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import { Close } from '../../components/icons'
import { H3 } from '../../components/common/headings'
import { IconCircle } from '../common/icon-circle'

import { InventoryItem } from './inventory-item'
import { Promotion } from './promotion'

const Wrapper = styled.div`
  box-shadow: 0 2px 4px 0 #b2b5ba;
  position: absolute;
  top: 70px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 4px;
  background: ${t.color('white')};
  width: calc(100% - 2 * ${t.spacing(1)});
  z-index: 11;

  ${t.mediaQuery.sm`
  right: ${t.spacing(2)};
  transform: none;
  width: 300px;
`};
`
const StyledIconCircle = styled(IconCircle)`
  color: #61696b;
  box-shadow: ${t.boxShadow(1)};
  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }
`

export function NotificationsScreen(props) {
  const i18n = useI18n()

  const ref = useRef(null)

  useEffect(() => {
    props.onFetch()
  }, [])

  useEffect(() => {
    function onClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        props.onClose()
      }
    }

    window.addEventListener('click', onClick)
    return () => window.removeEventListener('click', props.onClose)
  }, [props.onClose])

  useEffect(() => {
    return () => props.onClose()
  }, [props.onClose])

  return (
    <Wrapper ref={ref}>
      <Common.Box
        overflow="auto"
        maxHeight={['calc(100vh - 85px)', null, '400px']}
        borderRadius="4px"
        position="relative"
      >
        <Common.Box
          display="flex"
          flexDirection="row"
          p="0"
          borderTop="1px solid white"
          borderRight="1px solid white"
          borderLeft="1px solid white"
          backgroundColor="storePageBackground"
          justifyContent="space-between"
          alignItems="center"
        >
          <Common.Space pl="0">
            <H3
              lineHeight="20px"
              style={{
                color: '#61696B',
                textTransform: 'uppercase',
                fontSize: '14px',
                fontWeight: '600',
                letterSpacing: '1px',
              }}
            >
              {props.activeNotifications.length > 0
                ? i18n.translate('notifications-screen.active-notifications')
                : i18n.translate('notifications-screen.no-notifications')}
            </H3>
          </Common.Space>
          <Common.Box onClick={props.onClose}>
            <StyledIconCircle pointer size="40" hoverColor="black">
              <Icons.IconWrapper fontSize="2">
                <Close />
              </Icons.IconWrapper>
            </StyledIconCircle>
          </Common.Box>
        </Common.Box>
        {R.map(
          item =>
            item.notificationType === 'inventory' ? (
              <InventoryItem item={item} key={item.id} />
            ) : (
              <Promotion active item={item} key={item.key} />
            ),
          props.activeNotifications
        )}

        {props.futureNotifications.length > 0 && (
          <Common.Box backgroundColor="#FFF2E5" p="0">
            <Common.Box
              color="#61696B"
              fontSize="14px"
              fontWeight="600"
              letterSpacing="1px"
              lineHeight="20px"
              pl="0"
              pb="0"
              style={{ textTransform: 'uppercase' }}
            >
              {i18n.translate('notifications-screen.future-notifications')}
            </Common.Box>
            {R.map(
              item => (
                <Promotion
                  futureNotifications={props.futureNotifications.length > 0}
                  item={item}
                  key={item.key}
                />
              ),
              props.futureNotifications
            )}
          </Common.Box>
        )}
      </Common.Box>
    </Wrapper>
  )
}

NotificationsScreen.propTypes = {
  activeNotifications: PropTypes.array,
  futureNotifications: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
}
