import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

class ScrollTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

ScrollTop.propTypes = {
  location: PropTypes.object,
}

export const ScrollRestoration = withRouter(ScrollTop)
