import { countries } from '../../constants'

export function dataSchema() {
  return {
    type: 'object',
    properties: {
      employment: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'Self Employed',
            'Part time',
            'Full time',
            'Retired',
            'Student',
            'Unemployed',
          ],
        },
      },
      occupation: {
        type: 'string',
        minLength: 1,
      },
      otherSources: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'Salary-Employment',
            'Pension',
            'Savings',
            'Winnings',
            'Dividends',
            'Inheritance',
            'Property Sale',
            'Company Sale',
            'Assets Sale',
            'Investments',
            'Settlement',
            'Gift',
            'Crypto',
            'Rental Income',
            'None',
            'Other',
          ],
        },
      },
      otherSourcesField: {
        type: 'string',
        minLength: 1,
      },
      salary: {
        type: 'string',
        enum: [
          '5001-20000',
          '20001-40000',
          '40001-60000',
          '60001-80000',
          '80001',
        ],
      },
      depositsLevel: {
        type: 'string',
        enum: ['5000', '5001-10000', '10001-30000', '30001'],
      },
      sourceOfDeposits: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: [
            'No difference',
            'Salary',
            'Pension',
            'Dividends',
            'Inheritance',
            'Winnings',
            'Savings',
            'Loan',
            'Crypto',
            'Trading',
            'Rental income',
            'Gift',
            'Sale of company',
            'Sale of property',
            'Other',
          ],
        },
      },
      sourceOfDepositsOtherField: {
        type: 'string',
        minLength: 1,
      },
      fundsCountryOrigin: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: countries,
        },
      },
      nationality: {
        type: 'string',
        enum: countries,
      },
      placeOfBirth: {
        type: 'string',
        enum: countries,
      },
      idCardNumber: {
        type: 'string',
        minLength: 1,
      },
      confirmation: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          enum: ['confirmation'],
        },
      },
    },
    required: [
      'employment',
      'salary',
      'otherSources',
      'nationality',
      'placeOfBirth',
      'idCardNumber',
      'depositsLevel',
      'sourceOfDeposits',
      'confirmation',
    ],
  }
}
