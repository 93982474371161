import * as R from 'ramda'
import * as ReactRedux from 'react-redux'

import * as Locks from '@rushplay/compliance/locks'
import * as Api from '@rushplay/api-client'
import * as Session from '@rushplay/session'

import * as Notifications from '../../notifications'

import { SourceOfFunds as Component } from './source-of-funds'

export const SourceOfFunds = ReactRedux.connect(
  state => {
    const lock = Locks.getLock(state.locks, {
      type: 'source-of-funds-verification',
    })
    return {
      dismissable: R.pathOr(false, ['dismissable'], lock),
      rejected: R.pathOr(false, ['rejected'], lock),
      currency: Session.getCurrency(state.session),
    }
  },
  dispatch => ({
    onDismiss: () =>
      dispatch(
        Api.dismissSourceOfFunds({
          success: () => [
            Locks.fetch(),
            Locks.remove('source-of-funds-verification'),
          ],
          failure: () =>
            Notifications.add({
              message: 'error.source-of-funds.dismiss-failed',
              level: 'error',
            }),
          version: 1,
        })
      ),
    onSubmit: data =>
      dispatch([
        Api.submitSourceOfFunds(data, {
          failure: () =>
            Notifications.add({
              message: 'error.source-of-funds.submit-failed',
              level: 'error',
            }),
          version: 1,
        }),
        Api.acceptPrivacyPolicy({
          success: () => [
            Locks.fetch(),
            Locks.remove('source-of-funds-verification'),
          ],
          version: 1,
        }),
      ]),
  })
)(Component)
