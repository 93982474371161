import * as types from './types'

export function update(payload) {
  return {
    type: types.UPDATED,
    payload,
  }
}

export function updatePlayerBalanceTotals(payload) {
  return {
    type: types.TOTALS_UPDATED,
    payload,
  }
}

export function updateTotalDepositsCents(payload) {
  return {
    type: types.TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}
