import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Flex, Space, Text } from '@rushplay/common'

import { Button } from '../components/common/button'
import { HtmlContent } from '../components/common/html-content'

const Wrapper = styled(Space)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(250, 250, 250, 0.9);
  box-shadow: ${t.boxShadow(0)};

  ${t.mediaQuery.sm`
    width: 60%;
    margin: auto;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  `};
`

export class CookieBanner extends React.Component {
  componentDidMount() {
    this.props.onSetVisibility()
  }

  render() {
    if (this.props.visible) {
      // As we don't have the option to decline cookies anyway
      // and we want to make sure that each user has accepted cookies before making a deposit,
      // we accept them by clicking anywhere on the site

      document.body.addEventListener('click', this.props.onHandleSubmit, {
        once: true,
      })
      return (
        <Wrapper x="1" y="0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="black" size="0" lineHeight="1.5">
              <HtmlContent
                html={{ __html: this.props.translate('cookie-disclaimer') }}
              />
            </Text>
            <Button small>{this.props.translate('ok')}</Button>
          </Flex>
        </Wrapper>
      )
    }

    return null
  }
}

CookieBanner.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onHandleSubmit: PropTypes.func.isRequired,
  onSetVisibility: PropTypes.func.isRequired,
}
