import * as R from 'ramda'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { H1 } from '../../components/common/headings'
import { PendingTransaction } from '../../pending-transaction'
import { Spinner } from '../../components/common/spinner'
import { usePendingTransactions } from '../../use-pending-transactions'

export function PendingTransactions() {
  const i18n = I18n.useI18n()
  const { pendingTransactions, loading } = usePendingTransactions()

  if (loading) {
    return <Spinner />
  }

  if (R.isEmpty(pendingTransactions) && !loading) {
    return null
  }

  return (
    <Common.Flex justifyContent="center">
      <Common.Box width="600">
        <H1 align="center">{i18n.translate('pending-withdrawals')}</H1>
        <Common.Space pt={1}>
          {R.map(
            transaction => (
              <PendingTransaction
                amount={transaction.amount}
                date={transaction.created}
                key={transaction.transactionId}
                onCancel={() => transaction.onCancel(transaction.transactionId)}
              />
            ),
            pendingTransactions
          )}
        </Common.Space>
      </Common.Box>
    </Common.Flex>
  )
}
