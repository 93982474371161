import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import { withTranslate } from '@rushplay/i18n'

import * as Configuration from '../configuration'
import * as Ui from '../ui'

import { Sidebar as Component } from './sidebar'

function mapStateToProps(state) {
  return {
    features: Configuration.getFeatures(state.configuration),
    licenseId: Jurisdiction.getLicense(state.jurisdiction),
    manualSignup: Jurisdiction.getManualSignUpAllowed(state.jurisdiction),
    version: Configuration.getVersion(state.configuration),
    visible: Ui.getVisibility(state.ui, {
      id: 'sidebar',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClose: () => Ui.toggleVisibility('sidebar', false),
    },
    dispatch
  )
}

const connector = R.compose(
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps)
)

export const Sidebar = connector(Component)
