import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'

import * as I18n from '@rushplay/i18n'
import * as Session from '@rushplay/session'
import {
  SupportChat as Component,
  getTabVisibility,
} from '@rushplay/support-chat'

import * as CombinedSelectors from './combined-selectors'
import * as Configuration from './configuration'

export function SupportChat(props) {
  const location = ReactRouterDom.useLocation()
  const i18n = I18n.useI18n()
  const [allowLoad, setAllowLoad] = React.useState(false)

  const visible = ReactRedux.useSelector(state =>
    getTabVisibility(state.supportChat)
  )
  const session = ReactRedux.useSelector(state => ({
    authenticated: Session.isSessionActive(state.session),
    valueSegment: Session.getValueSegment(state.session),
    name: Session.getDisplayName(state.session),
    username: Session.getUsername(state.session),
    email: Session.getEmail(state.session),
  }))

  const liveChatKey = ReactRedux.useSelector(state =>
    Configuration.getLiveChatKey(state.configuration)
  )
  const department = ReactRedux.useSelector(state =>
    CombinedSelectors.getLiveChatDepartment(state)
  )
  const language = ReactRedux.useSelector(state =>
    CombinedSelectors.getLanguage(state)
  )
  const birthdate = ReactRedux.useSelector(
    state => state.session.player.birthdate
  )
  const birthdateTag = birthdate ? `DOB: ${birthdate}` : null
  const valueSegmentTag = session.valueSegment
    ? `Value segment: ${session.valueSegment}`
    : null
  const displayName = session.name && `${session.name} (${session.username})`
  const tags = [birthdateTag, valueSegmentTag].filter(tag => tag !== null)

  const translations = {
    'chat.concierge.avatarPath': i18n.translate('chat.concierge.avatar'),
    'chat.concierge.name': i18n.translate('chat.concierge.name'),
    'chat.concierge.title': i18n.translate('chat.concierge.title'),
    'chat.offlineForm.greeting': i18n.translate('chat.offline-form.greeting'),
    'chat.title': i18n.translate('chat.title'),
    'launcher.chatLabel': i18n.translate('chat.launcher.chat-label'),
    'launcher.label': i18n.translate('chat.launcher.label'),
  }

  /**
   * Only load the chat if requested or if the user is logged in
   */
  React.useEffect(() => {
    if (visible || session.authenticated) {
      setAllowLoad(true)
    }
  }, [visible, session.authenticated])

  if (allowLoad && liveChatKey) {
    return (
      <Component
        {...props}
        addScriptInHead
        hidden
        appKey={liveChatKey}
        departmentId={department.primary}
        email={session.email}
        fallbackDepartmentId={department.fallback}
        language={language}
        mountPoint="supportChat"
        name={displayName}
        tags={tags}
        translations={translations}
        visibleAt={location.pathname.includes('/sports') ? 9999 : 800}
      />
    )
  }
  return null
}

export default SupportChat
