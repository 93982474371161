import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as casino from '@rushplay/casino'

import * as configuration from '../configuration'
import { clientTypes } from '../client-type'

function createGetParams() {
  return createSelector(
    [
      state => configuration.getClientType(state.configuration),
      (state, props) => props.params,
    ],
    (clientType, params) => {
      const isMobile = R.includes(clientType, [
        clientTypes.MOBILE_BROWSER,
        clientTypes.IOS,
      ])

      return R.assoc('mobile', isMobile, params)
    }
  )
}

function makeMapStateToProps() {
  const getParams = createGetParams()
  return (state, props) => {
    const clientType = configuration.getClientType(state.configuration)
    const params = getParams(state, props)

    return {
      clientType,
      mountPoint: 'casino',
      params,
    }
  }
}

const connector = R.compose(
  connect(makeMapStateToProps),
  recompose.branch(
    props => R.equals(props.clientType, clientTypes.UNKNOWN),
    recompose.renderNothing
  )
)

export const GameQuery = connector(casino.GameQuery)
