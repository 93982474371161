import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

export function Card(props) {
  return (
    <Common.Box borderRadius="4px" overflow="hidden" {...props}>
      {props.children}
    </Common.Box>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

Card.defaultProps = {
  backgroundColor: 'white',
  boxShadow: 0,
  color: 'black',
  padding: [null, 2, 1],
}

Card.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
}
