import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import { Footer as Component } from './footer'

function mapStateToProps(state) {
  return {
    licenseId: jurisdiction.getLicense(state.jurisdiction),
  }
}

const connector = connect(mapStateToProps)

export const Footer = connector(Component)
