import PropTypes from 'prop-types'
import React from 'react'

import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import * as I18n from '@rushplay/i18n'

import { H2 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function AcceptBonusFunds(props) {
  const i18n = I18n.useI18n()
  return (
    <common.Flex flexDirection="column" alignItems="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 166 144"
        width="166px"
        height="144px"
      >
        <g fill={t.color('primary')(props)} fillRule="evenodd">
          <path d="M120.878 4.776c-.42-1.312-1.797-2.026-3.076-1.596l-.865.29V2.5c0-1.381-1.091-2.5-2.437-2.5-1.346 0-2.437 1.119-2.437 2.5v.971l-.866-.29c-1.279-.43-2.656.284-3.075 1.596-.42 1.312.278 2.724 1.557 3.154l.898.303-.565.805c-1.165 1.66.008 3.961 1.976 3.961 1.42 0 1.972-1.03 2.512-1.799l.533.76a2.398 2.398 0 0 0 3.402.567c1.092-.806 1.34-2.368.554-3.49l-.566-.805.899-.302c1.278-.43 1.975-1.843 1.556-3.155zM144.878 30.777c-.42-1.313-1.797-2.027-3.076-1.597l-.865.29v-.97c0-1.381-1.091-2.5-2.437-2.5-1.346 0-2.437 1.119-2.437 2.5v.97l-.866-.29c-1.279-.428-2.655.285-3.075 1.597-.42 1.311.278 2.723 1.557 3.154l.898.302-.566.806c-.786 1.12-.538 2.683.555 3.49a2.398 2.398 0 0 0 3.402-.569l.532-.759c.512.73 1.073 1.799 2.513 1.799 1.963 0 3.143-2.298 1.976-3.96l-.566-.807.899-.302c1.278-.43 1.976-1.842 1.556-3.154z" />
          <circle cx="112.5" cy="25.5" r="2.5" />
          <circle cx="150.5" cy="64.5" r="2.5" />
          <circle cx="125.5" cy="46.5" r="2.5" />
          <circle cx="151.5" cy="14.5" r="2.5" />
          <path d="M106.884 30.495c-1.276-1.582-3.085-1.74-3.085-1.74a2.46 2.46 0 0 0-2.778 2.078 2.452 2.452 0 0 0 2.072 2.781l-4.373 4.268a2.456 2.456 0 0 0-.706 2.173l1.032 6.029h-.003c-7.202-3.794-5.936-3.77-13.084-.004h-.003c-.004.004-.004.004-.007.004l1.033-6.029a2.464 2.464 0 0 0-.703-2.173l-4.376-4.268 6.047-.88a2.454 2.454 0 0 0 1.843-1.342l2.706-5.485 1.066 2.163a2.438 2.438 0 0 0 2.2 1.371c1.815 0 2.997-1.91 2.2-3.544l-1.07-2.163c-1.8-3.692-7.04-3.599-8.795 0l-2.13 4.327c-4.864.707-6.385.608-7.859 2.434a4.917 4.917 0 0 0-1.11 3.132c-.02 2.546 1.466 3.485 4.931 6.87l-.813 4.759a4.87 4.87 0 0 0 1.412 4.346 4.905 4.905 0 0 0 3.425 1.397c1.53 0 2.07-.46 6.54-2.814h.003l4.266 2.245c3.243 1.722 7.193-.668 7.184-4.33 0-.285-.023-.57-.072-.844l-.813-4.759 3.45-3.368c1.842-1.78 1.979-4.653.37-6.634z" />
          <path
            d="M163.568 74.196h-32.364l1.154-1.127c3.25-3.171 1.453-8.702-3.036-9.355l-6.245-.909-2.793-5.667c-2.008-4.075-7.815-4.073-9.822 0l-2.793 5.667-6.245.91c-4.491.652-6.284 6.184-3.036 9.354l1.154 1.127h-8.7a4.868 4.868 0 0 0-1.396-3.94L75.691 56.482a4.864 4.864 0 0 0-6.877 0l-8.464 8.475-9.321-9.334c-2.28-2.282-5.498-3.146-8.612-2.31-3.114.835-5.47 3.195-6.305 6.312l-4.874 18.218a12.088 12.088 0 0 0-6.43 6.44l-18.192 4.88c-6.63 1.779-8.855 10.074-3.997 14.938l9.32 9.335-8.463 8.475a4.872 4.872 0 0 0 0 6.888l13.756 13.774a4.844 4.844 0 0 0 3.438 1.424c1.246 0 2.491-.474 3.44-1.424l63.417-63.507h73.61v57.629a2.445 2.445 0 0 1-2.432 2.435H76.679a2.445 2.445 0 0 1-2.432-2.435V105.64c0-1.34-1.09-2.435-2.431-2.435a2.437 2.437 0 0 0-2.432 2.429v31.061c0 1.951.759 3.786 2.137 5.165a7.257 7.257 0 0 0 5.158 2.14h82.026a7.297 7.297 0 0 0 7.295-7.305V76.63a2.438 2.438 0 0 0-2.432-2.435zM40.81 60.886c.807-3.017 4.572-4.03 6.78-1.819l9.322 9.334-11.24 11.256 2.933-10.965a2.43 2.43 0 1 0-4.697-1.26l-2.824 10.555a12.058 12.058 0 0 0-4.553-1.107l4.28-15.994zm-6.87 21.151a7.253 7.253 0 0 1 7.358 2l-10.306 10.32c-4.12-3.887-2.418-10.753 2.948-12.32zM6.057 100.658a4.022 4.022 0 0 1-1.05-3.92 4.014 4.014 0 0 1 2.866-2.87l15.972-4.285a12.111 12.111 0 0 0 1.105 4.56L14.41 96.97a2.435 2.435 0 0 0 1.258 4.705l10.95-2.938-11.24 11.256-9.321-9.335zM20.67 139.13L6.914 125.355l10.183-10.197 44.971-45.034v-.001l10.183-10.197L86.01 73.698l-65.34 65.432zm108.293-69.548l-4.726 4.614h-17.728c-5.082-4.961-5.044-4.744-4.882-5.244.164-.506-.077-.335 8.006-1.511a2.43 2.43 0 0 0 1.83-1.332c3.627-7.357 3.384-7.158 3.91-7.158.525 0 .283-.2 3.908 7.158a2.432 2.432 0 0 0 1.831 1.332l7.51 1.093c.508.073.709.689.341 1.048z"
            fillRule="nonzero"
          />
        </g>
      </svg>
      <common.Space py="1">
        <H2 align="center">
          {i18n.translate('locks.accept-bonus-funds.title', {
            amount: props.bonusAmount,
          })}
        </H2>
      </common.Space>
      <common.Text textAlign="center" color="gray">
        <HtmlContent
          html={{
            __html: i18n.translate('locks.accept-bonus-funds.content', {
              percent: props.bonusPercentage,
              maxBonusAmount: props.maxBonusCents,
              minDepositAmount: props.minDepositAmount,
            }),
          }}
        />
      </common.Text>
      <common.Flex flexDirection="column" alignItems="center">
        <common.Space pt="1" />
        <PrimaryButton stretched onClick={props.onDecline}>
          {i18n.translate('locks.accept-bonus-funds.decline')}
        </PrimaryButton>
        <common.Space top="1" />
        <PrimaryButton stretched onClick={props.onAccept}>
          {i18n.translate('locks.accept-bonus-funds.accept')}
        </PrimaryButton>
      </common.Flex>
      <common.Space pt="1">
        <common.Text textAlign="center" decoration="underline" color="primary">
          <common.Link to="/terms-conditions-bonus">
            {i18n.translate('locks.accept-bonus-funds.terms')}
          </common.Link>
        </common.Text>
      </common.Space>
    </common.Flex>
  )
}

AcceptBonusFunds.propTypes = {
  bonusAmount: PropTypes.number,
  bonusPercentage: PropTypes.number,
  maxBonusCents: PropTypes.number,
  minDepositAmount: PropTypes.number,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
}
