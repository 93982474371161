import * as R from 'ramda'

import * as types from './types'

export function reducer(state = [], action) {
  switch (action.type) {
    case types.UPDATED: {
      // TODO: This is an amazing workaround for difference between staging and
      // production BE API (`GET /game_providers` response format is not
      // compatible) that kills container immediately.
      //
      // Fix the BE API or frontend to handle data correctly.
      //
      // P.S. Yes, I am sad.
      try {
        if (R.isNil(action.payload)) {
          return state
        }

        return R.map(R.toLower, action.payload)
      } catch (error) {
        // Everything went wrong. Please haz empty array to survive.
        return []
      }
    }

    default: {
      return state
    }
  }
}
