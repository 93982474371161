import React from 'react'

import * as Common from '@rushplay/common'

import * as Utils from '../../utils'

import { CollectNumber } from './collect-number'
import { VerifyNumber } from './verify-number'

export function PhoneVerification() {
  const query = Utils.Hooks.useQuery()

  return (
    <Common.Flex justifyContent="center">
      <Common.Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width={[null, '552px']}
        backgroundColor="white"
        borderRadius="4px"
        shadows={2}
        py="4"
      >
        {query.step == '2' ? <VerifyNumber /> : <CollectNumber />}
      </Common.Box>
    </Common.Flex>
  )
}
