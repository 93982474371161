import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { Logo } from '../../components/logo'

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;
  color: white;

  background-color: ${t.color('simpleorange')};

  ${t.mediaQuery.sm`
    flex-wrap: nowrap;
  `};
`

const InnerWrapper = styled.div`
  padding: ${t.spacing(1)};
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  ${t.mediaQuery.sm`
    flex-wrap: nowrap;
  `};
`

function Component(props) {
  return (
    <Wrapper>
      <InnerWrapper>
        <Route path="/:page([a-zA-Z\-]+)" component={Logo} />
        <Link to="/logout">{props.translate('locks.logout')}</Link>
      </InnerWrapper>
    </Wrapper>
  )
}

export const LockedTopbar = withTranslate(Component)

Component.propTypes = {
  translate: PropTypes.func.isRequired,
}
