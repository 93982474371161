import PropTypes from 'prop-types'
import React from 'react'

import { Flex, Space, Text } from '@rushplay/common'

import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function SourceOfWealth(props) {
  return (
    <React.Fragment>
      <HtmlContent
        html={{
          __html: props.translate('source-of-wealth.full-message'),
        }}
      />

      <Space paddingTop={2}>
        <Flex justifyContent="center">
          {props.isDismissable ? (
            <PrimaryButton onClick={props.onDismiss}>
              {props.translate('ok')}
            </PrimaryButton>
          ) : (
            <Text textAlign="center" color="red" fontSize={1} fontWeight="bold">
              {props.translate('source-of-wealth.undismissable-notice')}
            </Text>
          )}
        </Flex>
      </Space>
    </React.Fragment>
  )
}

SourceOfWealth.propTypes = {
  isDismissable: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}
