import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import url from 'url'

import { Box, Link, Space, Text } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { Card } from '../../components/common/card'
import { H1 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

import { SelfExclusion } from './self-exclusion'

function Component(props) {
  return (
    <SelfExclusion>
      {exclusion => {
        const { query } = url.parse(props.location.search, true, true)

        return (
          <React.Fragment>
            <Space py={1}>
              <H1 align="center">
                {props.translate('self-exclusion-period.headline')}
              </H1>
            </Space>
            <Box maxWidth="420px" margin="auto">
              <Card>
                <Text
                  textAlign="center"
                  color="gray"
                  fontSize={1}
                  lineHeight={1.5}
                >
                  <HtmlContent
                    html={{
                      __html: props.translate(
                        `self-exclusion-period.popup-content`,
                        {
                          amount: props.translate(
                            R.pathOr(
                              query.period,
                              ['labelKey'],
                              R.find(
                                R.propEq('value', query.period),
                                exclusion.options
                              )
                            )
                          ),
                        }
                      ),
                    }}
                  />
                </Text>
                <Space paddingTop={1} paddingBottom={2}>
                  <PrimaryButton
                    stretched
                    onClick={() => exclusion.onSubmit(query.period)}
                  >
                    {props.translate('player-limits.confirm')}
                  </PrimaryButton>
                </Space>

                <Text textAlign="center" color="gray">
                  <Link to="/self-exclusion">{props.translate('cancel')}</Link>
                </Text>
              </Card>
            </Box>
          </React.Fragment>
        )
      }}
    </SelfExclusion>
  )
}

export const SelfExclusionConfirmation = withTranslate(Component)

Component.propTypes = {
  location: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
}
