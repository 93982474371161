import * as R from 'ramda'
import { connect, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { isToday, isTomorrow } from 'date-fns'

import * as offers from '@rushplay/offers'
import * as analytics from '@rushplay/analytics'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as casino from '@rushplay/casino'
import * as locks from '@rushplay/compliance/locks'
import * as Triggers from '@rushplay/triggers'
import {
  getAffiliateClickId as getAffiliateClickIdFromSession,
  getCountryCode as getCountryCodeFromSession,
  getCurrency as getCurrencyFromSession,
  getPhoneVerification,
  isSessionActive,
} from '@rushplay/session'

import * as ui from './ui'
import * as configuration from './configuration'
import * as Constants from './constants'
import * as lookup from './lookup'
import * as session from './session'
import * as countries from './countries'
import * as minTransactionAmount from './minTransactionAmount.js'
import { clientTypes } from './client-type'

export function getPlayerLanguage(state) {
  const supportedLanguages = configuration.getLanguages(state.configuration)
  return session.getLanguage(state.session, {
    supportedLanguages,
  })
}

export function getBasename(state) {
  const blocked = lookup.getBlock(state.lookup)

  if (blocked) {
    return
  }

  return configuration.getBasename(state.configuration)
}

export function getLanguage(state) {
  const blocked = lookup.getBlock(state.lookup)

  const defaultLanguage = configuration.getDefaultLanguage(state.configuration)

  if (blocked) {
    return defaultLanguage
  }

  const playerLanguage = getPlayerLanguage(state)
  const appLanguage = configuration.getPreferredLanguage(state.configuration)
  return appLanguage || playerLanguage || defaultLanguage
}

export const withLanguage = connect(state => ({ language: getLanguage(state) }))

export const withLicense = connect(state => ({
  license: jurisdiction.getLicense(state.jurisdiction),
}))

export const getCurrency = createSelector(
  [
    state => isSessionActive(state.session),
    state => getCurrencyFromSession(state.session),
    state => lookup.getBrandCurrency(state.lookup),
  ],
  (authenticated, sessionCurrency, marketCurrency) => {
    return authenticated ? sessionCurrency : marketCurrency
  }
)

export function useCurrency() {
  return useSelector(getCurrency)
}

export function useLicense() {
  return useSelector(state => jurisdiction.getLicense(state.jurisdiction))
}

export function useCountryCode() {
  return useSelector(getCountryCode)
}

export function useBrand() {
  return useSelector(state => configuration.getBrand(state.configuration))
}

export function getLocale(state) {
  return getLanguage(state)
}

export const getCountryCode = createSelector(
  [
    state => isSessionActive(state.session),
    state => getCountryCodeFromSession(state.session),
    state => lookup.getCountryCode(state.lookup),
  ],
  (authenticated, sessionCountryCode, lookupCountryCode) => {
    return authenticated ? sessionCountryCode : lookupCountryCode
  }
)

/**
 * This should not be used directly.
 * The hook version should be used instead (/src/utils/hooks)
 */
export const getCountryCallingCode = createSelector(
  state => countries.getCountries(state.countries),
  getCountryCode,
  (enabledCountries, countryCode) => {
    const country = countries.getCountry(enabledCountries, countryCode)
    return R.path(['countryCode'], country)
  }
)

export const getCountry = createSelector(
  state => countries.getCountries(state.countries),
  state => lookup.getCountryCode(state.lookup),
  (enabledCountries, countryCode) => {
    return (
      R.find(R.propEq('alpha2', countryCode), enabledCountries) ||
      R.head(enabledCountries)
    )
  }
)

export const getWeekdayInWords = createSelector(
  [getLanguage, (state, props) => new Date(props.dateString)],
  (locale, date) => date.toLocaleString(locale, { weekday: 'long' })
)

export const getMinDepositAmountInCents = createSelector(
  [getCurrency],
  currency => minTransactionAmount.getMinDepositAmountInCents(currency)
)

export const getClaimableDepositOffers = createSelector(
  [
    state => R.pathOr({}, ['offers', 'depositOffers'], state),
    (state, props) => props.depositNumber,
  ],
  (offers, depositNumber) => {
    return R.pipe(
      R.defaultTo([{ packages: [] }]),
      R.sort(R.ascend(R.prop('maxDepositNumber'))),
      R.filter(offer => parseInt(depositNumber) === offer.maxDepositNumber - 1)
    )(offers)
  }
)

export function getWelcomeOffer(state, amountCents = 0) {
  return R.head(
    offers.getCalculatedOffers(state.offers, {
      depositNumber: 0,
      amountCents,
    })
  )
}

export function getWelcomeOfferId(state) {
  return R.path(['id'], getWelcomeOffer(state))
}

/**
 * Get lowest range of welcome offer (Min deposit to claim it) in cents
 * @param {*} state
 * @returns {number}
 */
export function getWelcomeOfferMinDepositLimit(state) {
  return R.path(
    ['depositOffers', 0, 'packages', 0, 'rangeLimits', 0],
    state.offers
  )
}

/**
 * Check if Welcome Offer is claimable
 * @param {*} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {Boolean}
 */
export const isWelcomeOfferClaimable = createSelector(
  [
    getWelcomeOfferMinDepositLimit,
    (state, props) => props.amountCents,
    (state, props) => props.orderType,
  ],
  (welcomeOfferMinDepositLimit, amountCents, orderType) =>
    welcomeOfferMinDepositLimit <= amountCents ||
    orderType === Constants.TransactionType.AUTH
)
/**
 * Get Welcome Offer Id if it's claimable
 * @param {*} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {string}
 */
export const getWelcomeOfferIdIfClaimable = createSelector(
  [isWelcomeOfferClaimable, getWelcomeOfferId],
  (isWelcomeOfferClaimable, welcomeOfferId) =>
    isWelcomeOfferClaimable ? welcomeOfferId : ''
)

/**
 * Retireve URL for translations JSON file
 * @param {Object} state
 */
export function getTranslationFileUrl(state) {
  const cdnUrl = configuration.getCdnUrl(state.configuration)
  const language = getLanguage(state)
  return `${cdnUrl}/locales/speedy_${R.toLower(language)}.json`
}

/**
 * Returns day from now in words (Today, tomorrow, thursday, friday etc.)
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.dateString String of date, ex: "2019-07-10T18:30:00+00:00"
 * @param {string} props.today String of "Today"
 * @param {string} props.tomorrow String of "Tomorrow"
 */
export function dayFromNowInWords(state, props) {
  const date = new Date(props.dateString)
  if (isToday(date)) {
    return props.today
  } else if (isTomorrow(date)) {
    return props.tomorrow
  }
  return getWeekdayInWords(state, { dateString: props.dateString })
}

/**
 * Retrieve affiliate click ID
 * @param {Object} state
 * @returns {Array.<string>}
 */
export function getAffiliateClickId(state) {
  return (
    getAffiliateClickIdFromSession(state.session) ||
    analytics.getClickId(state.analytics)
  )
}

export function getAffiliateSubId(state) {
  return analytics.getSubId(state.analytics)
}

export const getLiveChatDepartment = createSelector(
  [
    getLanguage,
    state => configuration.getLiveChatDepartments(state.configuration),
  ],
  (language, departments) => R.pathOr({}, [R.toLower(language)], departments)
)

export const isCancelPendingWithdrawalsVisible = createSelector(
  [state => ui.getVisibility(state.ui, { id: 'cancelPendingWithdrawals' })],
  cancelPendingWithdrawals => {
    return cancelPendingWithdrawals
  }
)

export const isSportsbookEnabled = createSelector(
  [
    state =>
      configuration.getFeature(state.configuration, { feature: 'sportsbook' }),
  ],
  sportsbookEnabled => sportsbookEnabled
)

export function useHasDepositOffers() {
  return useSelector(
    state => !R.isEmpty(R.path(['depositOffers'], state.offers))
  )
  /* Refactor so that we can use this in live casino connector.js file - useDispatch etc. */
}

export function getLiveCasinoGameTypes(state) {
  return [
    'all',
    ...casino.selectors.getGameTypes(state.casino, { queryName: 'live' }),
  ]
}

/**
 * Note: The phone verification feature and SGA consent trigger
 * is not a lock, since backend don't want
 * to maintain two different implementations of
 * the same feature (lock and non-lock).
 * But in frontend we still want to handle
 * it like a lock, therefore we add 'phone-verification' & 'sga-consent-trigger' manually.
 */
export const getLocks = createSelector(
  [
    state => locks.getLocks(state.locks),
    state => locks.isInitialized(state.locks),
    state => getPhoneVerification(state.session),
    state =>
      Triggers.getTrigger(state.triggers, {
        kind: 'popup',
        event: 'signup',
      }),
  ],
  (locks, locksInitialized, phoneVerification, sgaConsentTrigger) => {
    return R.reject(R.not, [
      locksInitialized &&
        phoneVerification &&
        !R.equals('off', phoneVerification) &&
        'phone-verification',
      locksInitialized && sgaConsentTrigger && 'sga-consent-trigger',
      ...locks,
    ])
  }
)

export function getIsMobile(state) {
  const clientType = configuration.getClientType(state.configuration)
  return R.equals(clientType, clientTypes.MOBILE_BROWSER)
}

export const getIsEmbedded = createSelector(
  [
    state => ui.getVisibility(state.ui, { id: 'game-view' }),
    getIsMobile,
    state =>
      R.startsWith(
        '/sports',
        R.path(['router', 'location', 'pathname'], state)
      ),
  ],
  (isGameView, isMobile, isSports) => {
    if (isGameView && !isSports) {
      return isMobile
    }

    return false
  }
)

export const getInitialAmount = createSelector([getCurrency], currency => {
  if (currency === 'SEK') {
    return '500'
  } else {
    return '50'
  }
})
