import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

import * as types from './types'

export * from './actions'
export * from './selectors'

export function reducer(state = {}, action) {
  switch (action.type) {
    case websockets.SESSION_EXPIRED:
    case types.CLEAR_PROMOTIONS: {
      return {}
    }

    case types.MARK_ALL_PROMOTIONS_SEEN: {
      return R.map(R.assoc('seen', true), state)
    }

    case types.MARK_PROMOTION_SEEN: {
      return R.assocPath([action.payload, 'seen'], true, state)
    }

    case websockets.PROMOTION_ENDED:
    case websockets.PROMOTION_PREVIEW_ENDED:
    case types.REMOVE_PROMOTION: {
      return R.dissoc(action.payload, state)
    }

    case types.UPDATE_PROMOTIONS: {
      return action.payload
    }

    case websockets.PROMOTION_STARTED:
    case websockets.PROMOTION_PREVIEW_STARTED:
    case types.UPDATE_PROMOTION: {
      return R.assoc(R.path(['key'], action.payload), action.payload, state)
    }

    default:
      return state
  }
}
