import PropTypes from 'prop-types'
import React from 'react'
import { v4 as generateUuid } from 'uuid'

import * as logger from '../../../logger'

export class Spinner extends React.Component {
  constructor() {
    super()

    this.id = generateUuid()
  }

  componentDidMount() {
    this.props.onMount(this.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      logger.react.error(this.props.error)
    }
  }

  componentWillUnmount() {
    this.props.onUnmount(this.id)
  }

  render() {
    return null
  }
}

Spinner.propTypes = {
  error: PropTypes.instanceOf(Error),
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
}
