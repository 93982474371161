import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Text } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

const TextWithPointer = styled(Text)`
  cursor: pointer;
  color: ${t.color('green')};
`
export const Pwa = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [showButton, setShowButton] = useState(false)
  const i18n = useI18n()

  useEffect(() => {
    const beforeInstallPrompt = e => {
      e.preventDefault()
      setShowButton(true)
      setDeferredPrompt(e)
    }
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt)
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt)
    }
  }, [])

  // uninstall the service worker (sw)
  useEffect(() => () => window['uninstall'](), [])

  return (
    <React.Fragment>
      <Helmet>
        <script src="/pwa/sw-install.js" async />
      </Helmet>
      {showButton && (
        <TextWithPointer
          onClick={() => {
            deferredPrompt.prompt()
            deferredPrompt.userChoice.then(choiceResult => {
              if (choiceResult.outcome === 'accepted') {
                setShowButton(false)
              }
              setDeferredPrompt(null)
            })
          }}
        >
          {i18n.translate('pwa.pwa-action')}
        </TextWithPointer>
      )}
    </React.Fragment>
  )
}
