import * as R from 'ramda'
import * as redux from 'redux'
import cookieMiddleware from 'redux-effects-universal-cookie'
import effects from 'redux-effects'
import localStorage from 'redux-effects-localstorage'
import location from 'redux-effects-location'
import multi from 'redux-multi'
import timeout from 'redux-effects-timeout'
import fetch, { fetchEncodeJSON } from 'redux-effects-fetch'
import { composeWithDevTools } from 'redux-devtools-extension'
import { connectRouter, push, routerMiddleware } from 'connected-react-router'
import { format } from 'date-fns'

import * as api from '@rushplay/api-client'
import * as analytics from '@rushplay/analytics'
import * as gtm from '@rushplay/analytics/gtm'
import * as netrefer from '@rushplay/analytics/netrefer'
import * as maps from '@rushplay/analytics/maps'
import * as supportChat from '@rushplay/support-chat'
// TODO: use local wrapper when @rushplay/session exports fixed
import * as _session from '@rushplay/session'
import * as casino from '@rushplay/casino'
import * as offers from '@rushplay/offers'
import * as websockets from '@rushplay/websockets'
import * as gamblingHistory from '@rushplay/compliance/gambling-history'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import * as realityCheck from '@rushplay/compliance/reality-check'
import * as i18n from '@rushplay/i18n'
import * as limits from '@rushplay/compliance/limits'
import * as locks from '@rushplay/compliance/locks'
import * as processes from '@rushplay/processes'
import * as lottery from '@rushplay/casino/lottery'
import * as Triggers from '@rushplay/triggers'
import timestamp from '@rushplay/redux-effects-timestamp'
import { debounceMiddleware } from '@rushplay/common'

import * as GameProviders from './game-providers'
import * as configuration from './configuration'
import * as gameCatalog from './game-catalog'
import * as inventory from './inventory'
import * as lookup from './lookup'
import * as receipts from './receipts'
import * as session from './session'
import * as ui from './ui'
import * as notifications from './notifications'
import * as player from './player'
import * as promotions from './promotions'
import * as countries from './countries'
import * as CombinedSelectors from './combined-selectors'
import { gtmEvents, mapsEvents, netreferEvents } from './analytics'

/**
 * Remove parts of state due to performance/security reasons
 */
const stateSanitizer = R.pipe(
  // Remove i18n state for speed up
  R.dissocPath(['i18n'])
)

const devToolsOptions = { stateSanitizer }

export default function createStore({ cookies, history, initialState }) {
  return redux.createStore(
    redux.combineReducers({
      analytics: analytics.reducer,
      api: api.reducer,
      countries: countries.reducer,
      lottery: lottery.reducer,
      casino: casino.reducer,
      configuration: configuration.reducer,
      gameCatalog: gameCatalog.reducer,
      gameProviders: GameProviders.reducer,
      gamblingHistory: gamblingHistory.reducer,
      i18n: i18n.reducer,
      inventory: inventory.reducer,
      jurisdiction: jurisdiction.reducer,
      limits: limits.reducer,
      locks: locks.reducer,
      lookup: lookup.reducer,
      notifications: notifications.reducer,
      offers: offers.reducer,
      player: player.reducer,
      processes: processes.reducer,
      promotions: promotions.reducer,
      realityCheck: realityCheck.reducer,
      receipts: receipts.reducer,
      router: connectRouter(history),
      session: session.reducer,
      supportChat: supportChat.reducer,
      triggers: Triggers.reducer,
      ui: ui.reducer,
    }),
    initialState,
    composeWithDevTools(devToolsOptions)(
      redux.applyMiddleware(
        multi,
        debounceMiddleware([api.shouldFetchDebounce]),
        lottery.middleware({
          host: state => configuration.getLottolandHost(state.configuration),
          operatorId: state =>
            configuration.getLottolandOperatorId(state.configuration),
          currencyIdSelector: CombinedSelectors.getCurrency,
        }),
        api.middleware({
          // We do not return country code in browser as requests will go
          // through Cloudflare anyway
          countryCodeSelector: process.browser
            ? null
            : state => lookup.getCountryCode(state.lookup) || null,
          defaultCacheFor: process.browser
            ? // CLIENT: No caching by default, needs to be set to 1 ms as 0
              // disables caching hence causes requests on hydration
              1
            : // SERVER: Roughly the average time to load page on client
              // completetly. This ensures we reuse cache from server requests,
              // but let client fetch fresh data if needed.
              30000,
          host: state => configuration.getApiUrl(state.configuration),
          substateSelector: state => state.api,
          tokenSelector: state => _session.getSessionToken(state.session),
        }),
        effects,
        fetch,
        fetchEncodeJSON,
        localStorage(process.browser && window.localStorage),
        routerMiddleware(history),
        location(),
        timestamp,
        timeout(),
        process.browser ? cookieMiddleware() : cookieMiddleware(cookies),
        websockets.middleware({
          bindings: {
            [websockets.LOCKS_CHANGED]: () => locks.fetch(),
            [websockets.SESSION_EXPIRED]: payload => [
              push('/'),
              R.propEq('reason', 'login_time_limit_exceeded', payload)
                ? notifications.add(
                    {
                      message: 'notification.login-time-limit-exceeded',
                      variables: {
                        limitPeriod: payload.limitPeriod,
                        limitValue: payload.limitValue / 6000,
                        expiresAt: format(payload.expiresAt, 'DD.MM.YYYY'),
                      },
                    },
                    'info'
                  )
                : notifications.add(
                    {
                      message: 'session-expired',
                    },
                    'info'
                  ),
            ],
          },
        }),
        analytics.eventQueuingMiddleware({
          events: [gtmEvents, netreferEvents, mapsEvents],
          queueSelector: state => analytics.getEventsQueue(state.analytics),
          onError: error => {
            if (process.browser && window.onerror) {
              window.onerror(error)
            }
          },
        }),
        gtm.middleware(gtmEvents),
        netrefer.middleware(netreferEvents, {
          // TODO: Get from BE (configuration)
          clientId: '8d908ecd-f205-408a-9f5a-acbb0c8c11c9',
          endpoint: 'http://tracking.netrefer.com/Tracking.svc/Track',
        }),
        maps.middleware(mapsEvents, {
          endpoint: 'https://creatives.heropartners.io/masterpostback.aspx',
          key: 'so9kmdauot7syi2x',
          reportId: '2',
        })
      )
    )
  )
}
