import PropTypes from 'prop-types'
import React from 'react'

import { ResponsiveThemeProvider } from '@rushplay/theme'

export function ThemeProvider(props) {
  return (
    <ResponsiveThemeProvider theme={props.theme} overrides={props.overrides}>
      {props.children}
    </ResponsiveThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
  overrides: PropTypes.object,
  theme: PropTypes.object,
}
