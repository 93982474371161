import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { getTime } from 'date-fns'

import * as Theme from '@rushplay/theme'
import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as promotions from '../../../promotions'
import { AltButtonInverted } from '../../../components/common/button-simple'

const TermsConditions = styled.a`
  padding-top: 5px;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  font-family: ${Theme.font('head')};
`

export function Promotion(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      display="flex"
      px={0}
      py={1}
      mx={0}
      borderTop="1px solid"
      borderColor="storePageBackground"
      backgroundColor={props.futureNotifications ? '#FFF2E' : 'white'}
    >
      <Common.Box
        size="80px"
        minWidth="80px"
        marginRight={1}
        borderRadius="5px"
        backgroundImage={`url(${i18n.translate(
          `promotions.${props.item.key}.img-src`
        )})`}
        backgroundPosition="center"
        backgroundSize="cover"
      />
      <Common.Box overflow="auto">
        <Common.Text
          color="darkgray"
          fontSize="1"
          lineHeight="20px"
          letterSpacing="1px"
          fontWeight="700"
        >
          {i18n.translate(`promotions.${props.item.key}.title`)}
        </Common.Text>
        <Common.Box
          color="darkgray"
          pt="0"
          fontSize="14px"
          lineHeight={1.08}
          letterSpacing="1px"
          fontWeight="700"
        >
          <Common.Timestamp>
            {timestamp =>
              props.active
                ? props.item.activeTo &&
                  i18n.translate(
                    'notifications-screen.promotion.active-promotion.countdown',
                    Common.toRemainingTime(
                      0,
                      getTime(props.item.activeTo),
                      timestamp
                    )
                  )
                : i18n.translate(
                    'notifications-screen.promotion.future-promotion.countdown',
                    Common.toRemainingTime(
                      0,
                      getTime(props.item.activeFrom),
                      timestamp
                    )
                  )
            }
          </Common.Timestamp>
        </Common.Box>
        <Common.Box color="darkgray" pt="4px" lineHeight={1.17} opacity="0.6">
          <TermsConditions
            href={`/promotion/${props.item.key}`}
            target="blank"
            rel="noreferrer noopener"
          >
            {i18n.translate(
              'notifications-screen.promotion.terms-conditions-link'
            )}
          </TermsConditions>
        </Common.Box>
        {props.active &&
          !R.pathEq(['campaign', 'optInState'], 'in', props.item) && (
            <Common.Space pt={1}>
              <AltButtonInverted
                onClick={props.onClaimPromotion}
                disabled={props.isLoading}
              >
                {promotions.isDepositCampaignPromotion(props.item)
                  ? i18n.translate('notifications-screen.promotion.deposit.cta')
                  : R.pathEq(
                      ['campaign', 'optInState'],
                      'automatic',
                      props.item
                    )
                  ? i18n.translate(
                      'notifications-screen.promotion.automatic.cta'
                    )
                  : i18n.translate('notifications-screen.promotion.cta')}
              </AltButtonInverted>
            </Common.Space>
          )}
      </Common.Box>
    </Common.Box>
  )
}

Promotion.propTypes = {
  active: PropTypes.bool,
  isLoading: PropTypes.bool,
  item: PropTypes.object.isRequired,
  futureNotifications: PropTypes.bool,
  onClaimPromotion: PropTypes.func.isRequired,
}
