import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

const Item = styled('div', { shouldForwardProp: common.noneOf(['active']) })`
  margin-right: 2px;
  margin-left: 2px;
  padding: ${t.spacing(0)} 12px;
  color: ${t.color('primary')};
  font-size: ${t.fontSize(0)};
  font-weight: 600;
  user-select: none;
  border-radius: ${t.spacing(0)};

  background-color: ${t.color('gray', 0.1)};

  ${props =>
    props.active && `background-color: ${t.color('primary', 0.3)(props)};`};

  &:hover {
    background-color: ${props =>
      !props.active && t.color('primary', 0.2)(props)};
  }
`

const Placeholder = styled.div`
  margin-right: 2px;
  margin-left: 2px;
  padding: 5px 4px;
  background-color: transparent;
  color: ${t.color('primary')};
  font-size: ${t.fontSize(0)};
  font-weight: 600;
  user-select: none;
`

// shortcut for inclusive range
const rangeInc = R.curry((from, to) => R.range(from, to + 1))

// wrap array with first and last values
const wrap = R.curry((first, last, input) => [first, ...input, last])

// add placeholders to gaps in pages sequence
function paginationPlaceholders(placeholder, first, last, pages) {
  return R.pipe(
    // reject all values between first and last page (exclusively)
    R.filter(x => x > first && x < last),
    // wrap with first and last pages
    wrap(first, last),
    // fill the gaps with placeholder
    R.reduce((result, page) => {
      const isFirstIteration = !result.length
      const isConsequent = result[R.dec(result.length)] === R.dec(page)

      if (isFirstIteration || isConsequent) {
        return R.concat(result, [page])
      }

      return R.concat(result, [placeholder, page])
    }, [])
  )(pages)
}

// create full URL for pagination link
const pageUrl = R.curry(function _pageUrl(base, page) {
  return `${base}/${page}`
})

export function Pagination(props) {
  if (props.total <= 1) {
    return null
  }

  const pages = paginationPlaceholders(
    '...',
    1,
    props.total,
    rangeInc(props.current - props.limit, props.current + props.limit)
  )

  return (
    <common.Flex justifyContent="center">
      <common.Space pt="2">
        <common.Flex>
          {R.map(
            page => (
              <div key={page} onClick={() => props.onNav(page)}>
                {page === props.current ? (
                  <Item active>{page}</Item>
                ) : page === '...' ? (
                  <Placeholder>{page}</Placeholder>
                ) : (
                  <common.Link to={pageUrl(props.base, page)}>
                    <Item>{page}</Item>
                  </common.Link>
                )}
              </div>
            ),
            pages
          )}
        </common.Flex>
      </common.Space>
    </common.Flex>
  )
}

Pagination.defaultProps = {
  current: 1,
  limit: 2,
  total: 0,
}

Pagination.propTypes = {
  base: PropTypes.string.isRequired,
  current: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  onNav: PropTypes.func.isRequired,
}
