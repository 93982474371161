import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import * as combinedSelectors from '../../combined-selectors'

import { Routes as Component } from './routes'

function mapStateToProps(state) {
  return {
    locks: combinedSelectors.getLocks(state),
    manualSignup: jurisdiction.getManualSignUpAllowed(state.jurisdiction),
  }
}

const connector = R.compose(
  /**
   * For reasons I can't explain connecting the withRouter gets around the issue with
   * Update Blocking (https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md)
   * Possibly related to us connecting the <Route>'s with our own Redux state or something..?
   */
  withRouter,
  connect(mapStateToProps)
)

export const Routes = connector(Component)
