import * as R from 'ramda'
import * as reselect from 'reselect'

import * as api from '@rushplay/api-client'

const INITIAL_STATE = {}

export const INIT = 'simplecasino/lookup/INIT'

export function init(payload) {
  return {
    type: INIT,
    error: payload instanceof Error,
    payload,
  }
}

export function fetchCountryData() {
  return api.fetchCountryCode({
    success: res =>
      init({
        blocked: res.value.country.blocked,
        brandCurrency: res.value.country.brandCurrency,
        countryCode: res.value.countryCode,
        name: res.value.name,
      }),
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return Object.assign(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export function getBlock(state) {
  return R.propOr(false, 'blocked', state)
}

export function getBrandCurrency(state) {
  return R.propOr('', 'brandCurrency', state)
}

export function getCountryCode(state) {
  return R.propOr('', 'countryCode', state)
}

export const getLookup = reselect.createStructuredSelector({
  blocked: getBlock,
  brandCurrency: getBrandCurrency,
  countryCode: getCountryCode,
})
