import * as R from 'ramda'
import { connect } from 'react-redux'
import { push as redirectTo } from 'react-router-redux'

import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import * as combinedSelectors from '../../../combined-selectors'
import * as inventory from '../../../inventory'
import * as notifications from '../../../notifications'
import * as ui from '../../../ui'
import { getGameUrl } from '../../../utils/get-game-url'

import { InventoryItem as Component } from './inventory-item'

function mapStateToProps(state, props) {
  return {
    isLoading: processes.isRunning(state.processes, {
      ids: [`USE_INVENTORY_ITEM_${props.item.id}`],
    }),
    language: combinedSelectors.getLanguage(state),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onActivateItem: () => {
      if (!props.item.gameDisabledForClientType) {
        //Only redirect because it's claimed in the lotto UI
        if (props.item.type === 'LotteryBagItem') {
          return dispatch([
            redirectTo('/lotto'),
            ui.toggleVisibility('notifications', false),
          ])
        }

        dispatch([
          processes.start(`USE_INVENTORY_ITEM_${props.item.id}`),
          api.useItem(props.item.id, {
            success: res => {
              const item = res.value
              const gameUrl = getGameUrl(item.gameId, {
                mode: item.blitz ? 'blitz' : 'classic',
                freeGames: item.freeGames,
              })
              const redirectUrls = {
                Freespins: gameUrl,
                FreeGamesVoucher: gameUrl,
                FeatureTriggers: gameUrl,
                Freebet: R.pathOr('/sports', ['redirectUrl'], item),
                LotteryTicketItem: '/lotto/my-tickets',
              }

              return [
                processes.stop(`USE_INVENTORY_ITEM_${item.id}`),
                redirectTo(redirectUrls[item.type]),
                ui.toggleVisibility('notifications', false),
                inventory.removeInventoryItem(item.id),
              ]
            },
            failure: () => [
              processes.stop(`USE_INVENTORY_ITEM_${props.item.id}`),
              notifications.add(
                { message: 'errors.inventory-item-not-activated' },
                'error'
              ),
            ],
            version: 1,
          }),
        ])
      }
    },
  }
}

export const InventoryItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
