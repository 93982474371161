import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'

import { NotAuthorized } from '../../components/not-authorized'

export function PrivateRoute({
  authenticated,
  component: Component,
  ...props
}) {
  return (
    <Route
      {...props}
      render={({ staticContext, ...props }) => {
        if (authenticated) {
          return <Component {...props} />
        }

        if (staticContext) {
          staticContext.status = 401
        }

        return <NotAuthorized />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
}
