import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import React from 'react'
import querystring from 'querystring'

import * as CombinedSelectors from '../combined-selectors'
import * as Countries from '../countries'

/**
 * @returns {object} An object with the data of the query string of the current URL
 */
export function useQuery() {
  const location = ReactRouter.useLocation()
  return querystring.parse(R.drop(1, location.search))
}

export function useScrolledPast(threshold) {
  const [scrolledPast, setScrolledEnough] = React.useState(false)

  React.useEffect(() => {
    function onScroll() {
      setScrolledEnough(window.pageYOffset >= threshold)
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [setScrolledEnough])

  return scrolledPast
}

export function usePreviousValue(value) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useScrollPosition() {
  const initialPosition = process.browser ? window.scrollY : 0
  const [position, setPosition] = React.useState(initialPosition)

  React.useEffect(() => {
    function handleScroll() {
      setPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll, false)

    return () => {
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, [setPosition])

  return position
}

export function useScrollDelta(timeframe) {
  const currentPosition = useScrollPosition()

  const [lastPosition, setLastPosition] = React.useState(currentPosition)

  React.useEffect(() => {
    const timerId = window.setTimeout(() => {
      setLastPosition(currentPosition)
    }, timeframe)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [currentPosition, timeframe])

  return currentPosition - lastPosition
}

export function useCountryCallingCode() {
  const dispatch = ReactRedux.useDispatch()
  const countryCallingCode = ReactRedux.useSelector(
    CombinedSelectors.getCountryCallingCode
  )

  React.useEffect(() => {
    if (!countryCallingCode) {
      dispatch(Countries.fetch())
    }
  }, [countryCallingCode])

  return countryCallingCode
}

export function useDetectScrollEnd(element) {
  const [isScrollEnd, setIsScrollEnd] = React.useState(false)
  const scrollTimer = React.useRef(null)

  React.useEffect(() => {
    if (!R.isNil(element)) {
      element.addEventListener(
        'scroll',
        () => {
          window.clearTimeout(scrollTimer.current)
          scrollTimer.current = setTimeout(() => {
            setIsScrollEnd(true)
          }, 66)
        },
        false
      )
    }
  }, [element])

  React.useEffect(() => {
    if (isScrollEnd) {
      setIsScrollEnd(false)
    }
  }, [isScrollEnd])

  return isScrollEnd
}

export function useSlider(
  numberOfItems,
  initialActiveSlide = 1,
  cycleTime = 5000
) {
  const sliderRef = React.useRef()
  const scrollEnd = useDetectScrollEnd(sliderRef.current)
  const sliderTimer = React.useRef(null)
  const [activeSlide, setActiveSlide] = React.useState(initialActiveSlide)

  function handleScrollTo(position) {
    sliderRef.current.scrollTo(position, 0)
  }

  function handleScrollBy(position) {
    sliderRef.current.scrollBy(position, 0)
  }

  const scrollToSlide = React.useCallback(() => {
    if (!R.isNil(sliderRef.current)) {
      sliderTimer.current = setTimeout(() => {
        if (activeSlide + 1 > numberOfItems) {
          handleScrollTo(0, 0)
        } else {
          sliderRef.current.scrollBy(window.innerWidth, 0)
        }
      }, cycleTime)
    }
  }, [sliderRef.current, sliderTimer.current, activeSlide])

  React.useEffect(() => {
    if (scrollEnd) {
      const nextActiveSlide =
        numberOfItems /
          ((window.innerWidth * numberOfItems) / sliderRef.current.scrollLeft) +
        1

      if (Number.isInteger(nextActiveSlide)) {
        setActiveSlide(nextActiveSlide)
      }
    }
  }, [scrollEnd, sliderRef.current, numberOfItems])

  React.useEffect(() => {
    window.clearTimeout(sliderTimer.current)
    scrollToSlide()
  }, [activeSlide])

  return {
    activeSlide,
    handleScrollTo,
    handleScrollBy,
    ref: sliderRef,
  }
}
