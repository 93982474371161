import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Icons from '@rushplay/icons'
import { useI18n } from '@rushplay/i18n'

import * as ui from '../ui'

import { Item } from './item'

export function MenuItem(props) {
  const dispatch = ReactRedux.useDispatch()
  const I18n = useI18n()
  const isExpandable = props.expanded != null

  function onClose() {
    dispatch(ui.toggleVisibility('sidebar', false))
  }

  return (
    <Item
      as={props.to ? Common.Box.withComponent(Common.Link) : Common.Box}
      to={props.to}
      onClick={props.onClick || onClose}
      display="flex"
      justifyContent="space-between"
      padding="1"
      fontFamily="head"
      fontSize={props.fontSize}
      fontWeight="600"
      lineHeight="1.4"
      color={props.color}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
      }}
      title={props.title}
      expanded={isExpandable ? !props.expanded : undefined}
    >
      {I18n.translate(props.title)}

      {isExpandable && (
        <Icons.IconWrapper>
          {props.expanded ? (
            <Icons.IconChevronDown />
          ) : (
            <Icons.IconChevronRight />
          )}
        </Icons.IconWrapper>
      )}
    </Item>
  )
}

MenuItem.defaultProps = {
  fontSize: 2,
}

MenuItem.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  expanded: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
}
