import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import { GameSearchInput } from '../../casino/game-search-input'
import { LobbyNavigation } from '../lobby-navigation'

const Navigation = styled(LobbyNavigation)`
  padding-left: 50px;
`

function getItems() {
  return R.filter(Boolean, [
    {
      path: '/casino',
      key: 'casino.nav.casino-lobby',
    },
    {
      category: 'new',
      path: '/casino/new',
      key: 'game-tile.new',
    },
    {
      category: 'live-casino',
      path: '/casino/live-casino',
      key: 'casino.nav.live-casino',
    },
    {
      category: 'slots',
      path: '/casino/slots',
      key: 'casino.nav.slots',
    },
    {
      category: 'jackpot',
      path: '/casino/jackpot',
      key: 'casino.nav.jackpot',
    },
    {
      category: 'roulette',
      path: '/casino/roulette',
      key: 'casino.nav.roulette',
    },
    {
      category: 'blackjack',
      path: '/casino/blackjack',
      key: 'casino.nav.blackjack',
    },
    {
      category: 'table-games',
      path: '/casino/table-games',
      key: 'casino.nav.table-games',
    },
    {
      category: 'scratch',
      path: '/casino/scratch',
      key: 'casino.nav.scratch',
    },
  ])
}

export function CasinoNavigation(props) {
  return (
    <Navigation
      selectedCategory={R.path(['match', 'params', 'tag'], props)}
      items={getItems()}
    >
      <GameSearchInput />
    </Navigation>
  )
}

CasinoNavigation.propTypes = {
  license: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
}
