import * as R from 'ramda'
import { connect } from 'react-redux'
import { withTheme } from 'emotion-theming'

import * as Session from '@rushplay/session'

import { Logotype as Component } from './logotype'

function mapStateToProps(state) {
  return {
    authenticated: Session.isSessionActive(state.session),
  }
}

const connector = R.compose(connect(mapStateToProps), withTheme)

export const Logotype = connector(Component)
