import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Icons from '@rushplay/icons'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const Wrapper = styled.span`
  ${css({
    overflow: 'hidden',
    transition: 'opacity 500ms ease-in-out',
    wordBreak: 'break-word',
    pt: '4px',
    color: 'orange',
  })};
`

const IconWrapper = styled(Icons.IconWrapper)`
  margin-right: 4px;
`

export function FieldResponse(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, { noRegister: true })

  const visited = field.status !== Forms.FieldStatus.PRISTINE

  const error = R.pathOr({}, [0], field.errors)

  const valid = R.isEmpty(error)
  const visuallyValid = !visited || valid
  const visible = !visuallyValid || (!valid && visited)

  if (!visible || valid) {
    return null
  }

  return (
    <Wrapper>
      <Common.Box display="grid" gridGap="4px">
        <Common.Flex alignItems="center">
          <IconWrapper>
            <Icons.IconAlert />
          </IconWrapper>
          <Common.Text fontSize={0}>
            {i18n.translate(error.translationKey, error.translationVariables)}
          </Common.Text>
        </Common.Flex>
      </Common.Box>
    </Wrapper>
  )
}

FieldResponse.propTypes = {
  scope: PropTypes.string.isRequired,
}
