import * as R from 'ramda'
import { addHours } from 'date-fns'
import { cookie } from 'redux-effects-universal-cookie'
import { push } from 'connected-react-router'

import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'
import {
  FETCH_SESSION_PROCESS,
  getPersonId,
  reducer as sessionReducer,
  session,
} from '@rushplay/session'
import { SESSION_EXPIRED } from '@rushplay/websockets'

import * as Player from '../player'
import * as notifications from '../notifications'

/**
 * @typedef {Object} SessionState
 * @property {?Array<string>} languages Player’s desired languages
 */

export const LOGOUT = 'simplecasino/session/LOGOUT'
export const LANGUAGES_UPDATED = 'simplecasino/session/LANGUAGES_UPDATED'

/**
 * Clears session-related stuff from state
 * and redirects user to /
 */
function clear() {
  return [
    { type: SESSION_EXPIRED },
    session.clear(),
    Player.clear(),
    push('/'),
    sessionStorage.removeItem('DEPOSIT_AMOUNT'),
  ]
}

export function logout() {
  return [
    { type: LOGOUT },
    api.logout({
      success: () => clear(),
      failure: () => {},
      version: 1,
    }),
  ]
}

export function keepAlive(token) {
  return api.fetchSession({
    token,
    failure: () =>
      R.flatten([
        clear(),
        notifications.add(
          {
            message: 'session-expired',
          },
          'info'
        ),
      ]),
    version: 2,
  })
}

export function refresh(token, options = {}) {
  return [
    processes.start(FETCH_SESSION_PROCESS),
    api.fetchSession({
      token,
      success: response => [
        session.update(response.value),
        cookie('token', response.value.token, {
          // TODO: Replace new Date with timestamp from redux-effects-timestamp
          expires: addHours(new Date(), 1),
          httpOnly: true,
          secure: process.env.NODE_ENV === 'production',
          path: '/',
        }),
        processes.stop(FETCH_SESSION_PROCESS),
        options.success && options.success(),
      ],
      failure: () => [
        // TODO: dispatch notification
        processes.stop(FETCH_SESSION_PROCESS),
      ],
      version: 1,
    }),
  ]
}

export function login(username, password, clientType, options = {}) {
  return api.login(username, password, clientType, {
    success: response => refresh(response.value.token, options),
    failure: error => {
      const errors = R.pathOr({}, ['value', 'errors', 'base'], error)
      const selfExclusion = R.find(
        R.pathEq(['reason'], 'self_exclusion'),
        errors
      )
      const timeout = R.find(R.pathEq(['reason'], 'timeout'), errors)
      const invalidCredentials = R.find(
        R.pathEq(['error'], 'Invalid Credentials'),
        errors
      )
      const zipRestriction = R.find(
        R.pathEq(['errorCode'], 'zip_restricted'),
        errors
      )

      if (invalidCredentials) {
        return [
          notifications.add(
            { message: 'errors.login.invalid-credentials' },
            'error'
          ),
        ]
      }

      if (selfExclusion) {
        return [
          notifications.add(
            {
              message: 'errors.login.self-exclusion-lock',
              variables: {
                expiresAt: new Date(selfExclusion.expiresAt),
              },
            },
            'info'
          ),
        ]
      }

      if (timeout) {
        return [
          notifications.add(
            {
              message: 'errors.login.timeout-lock',
              variables: {
                expiresAt: new Date(timeout.expiresAt),
              },
            },
            'info'
          ),
        ]
      }

      if (zipRestriction) {
        return [
          notifications.add(
            {
              message: 'errors.login.zip-restricted',
            },
            'info'
          ),
        ]
      }

      return [
        notifications.add(
          {
            message: 'errors.general.unknown',
          },
          'info'
        ),
      ]
    },
    version: 2,
  })
}

export function saveLanguagePreference(language) {
  return api.saveLanguagePreference(language, {
    success: res => {
      return session.update({ player: res.value })
    },
    version: 1,
  })
}

export function updateContactConsents(data, options = {}) {
  return api.updateMe(data, {
    success: res => {
      if (typeof options.onSuccess === 'function') {
        options.onSuccess()
        return notifications.add(
          {
            message: 'success.update.contact.consent',
          },
          'success'
        )
      }
      return session.update({ player: res.value })
    },
    failure: () => {
      if (typeof options.onFailure === 'function') {
        options.onFailure()
      }
      return notifications.add(
        { message: 'errors.update-contact-consent' },
        'error'
      )
    },
    version: 1,
  })
}

/**
 * Update player’s desired languages
 * @param {Array<string>} languages
 */
export function updateLanguages(languages) {
  return {
    type: LANGUAGES_UPDATED,
    payload: languages,
  }
}

/**
 * Extended `@rushplay/session` reducer
 * @param {SessionState} state
 * @param {FSA} action
 */
export function reducer(state, action) {
  switch (action.type) {
    case LANGUAGES_UPDATED: {
      return R.assoc('languages', action.payload, state)
    }

    default: {
      return sessionReducer(state, action)
    }
  }
}

export function getCardholderName(state) {
  const firstName = R.path(['player', 'address', 'firstName'], state)
  const lastName = R.path(['player', 'address', 'lastName'], state)

  return `${firstName} ${lastName}`
}

function getPreferredLanguage(state) {
  return R.path(['player', 'language'], state)
}

export function getPersonalNumber(state) {
  const id = getPersonId(state)

  if (id == null) {
    return null
  }

  return R.replace(/\D/g, '', id)
}

export function getPlayerEmail(state) {
  return R.pathOr(null, ['player', 'email'], state)
}

export function getPlayerStreet(state) {
  return R.path(['player', 'address', 'street'], state)
}

export function getPlayerState(state) {
  return R.path(['player', 'address', 'state'], state)
}

export function getPlayerZip(state) {
  return R.path(['player', 'address', 'zip'], state)
}

export function getPlayerCity(state) {
  return R.path(['player', 'address', 'city'], state)
}

export function getPlayerLastName(state) {
  return R.path(['player', 'address', 'lastName'], state)
}

export function getPlayerFirstName(state) {
  return R.path(['player', 'address', 'firstName'], state)
}

export function getMapsPlayerId(state) {
  return R.path(['player', 'mapsPlayerId'], state)
}

export function getAllowSms(state) {
  return R.path(['player', 'allowSMS'], state)
}

export function getAllowEmail(state) {
  return R.path(['player', 'allowEmail'], state)
}

export function getRequiresEmailValidation(state) {
  return R.path(['player', 'requiresEmailValidation'], state)
}

/**
 * PHONE NUMBER PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getCountryCallingCode(state) {
  return R.path(['countryCallingCode'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMobile(state) {
  return R.path(['mobile'], state)
}

export function getPlayerPhoneNumber(state) {
  const countryCode = getCountryCallingCode(state)
  const phoneNumber = getMobile(state)
  return (
    countryCode &&
    phoneNumber &&
    `${countryCode}${R.replace(/^0+/, '', phoneNumber)}`
  )
}

/**
 * Returns language based on player’s preference and supported languages
 * @param {SessionState} state
 * @param {Object} props
 * @param {Array<string>} props.supportedLanguages
 */
const LANGUAGES_FALLBACK = []
export function getLanguage(state, props) {
  const preferredLanguage = getPreferredLanguage(state)

  if (R.includes(preferredLanguage, props.supportedLanguages)) {
    return preferredLanguage
  }

  return R.find(
    language => R.includes(language, props.supportedLanguages),
    state.languages || LANGUAGES_FALLBACK
  )
}

export function getToken(state) {
  return R.pathOr(null, ['token'], state)
}

export function getPrefixedToken(state) {
  // console.log("Get prefix")
  // console.log(state)
  return state.prefixedToken || null
}

export * from '@rushplay/session'
