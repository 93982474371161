import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import * as exclusion from '@rushplay/compliance/exclusion'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import { NotFound } from '../../components/not-found'

function mapStateToProps(state) {
  return {
    values: jurisdiction.getTimeOutValues(state.jurisdiction),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFailure: () => dispatch(push('/time-out')),
  }
}

const connector = R.compose(
  recompose.setPropTypes({
    children: PropTypes.func,
    values: PropTypes.array,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  recompose.branch(
    props => R.isEmpty(props.options),
    recompose.renderComponent(NotFound)
  )
)

export const Timeout = connector(exclusion.Timeout)
