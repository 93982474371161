import * as R from 'ramda'
import { connect } from 'react-redux'
import { withTheme } from 'emotion-theming'

import * as processes from '@rushplay/processes'

import { Loader as Component } from './loader'

function mapStateToProps(state) {
  return {
    visible: processes.isAnyRunning(state.processes),
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  withTheme
)

export const Loader = connector(Component)
