import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as locks from '@rushplay/compliance/locks'
import { withTranslate } from '@rushplay/i18n'

import * as notifications from '../../notifications'

import { RGDepositThresholdPrompt as Component } from './rg-deposit-threshold-prompt'

const connector = R.compose(
  withTranslate,
  connect(
    null,
    {
      onAccept: () => [
        locks.remove('responsible-gaming-deposit-threshold-prompt'),
        api.dismissDepositThresholdPrompt({
          success: () => locks.fetch(),
          failure: () => [
            locks.fetch(),
            notifications.add(
              {
                message: 'errors.dismiss-deposit-threshold-prompt.failed',
              },
              'error'
            ),
          ],
          version: 1,
        }),
      ],
    }
  )
)

export const RGDepositThresholdPrompt = connector(Component)
