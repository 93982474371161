import * as recompose from 'recompose'
import * as R from 'ramda'

import { createColorSet } from '@rushplay/theme'

import { ThemeProvider as Component } from './theme-provider'

const breakpoints = ['0rem', '42rem', '62rem', '70rem']
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]

const baseTheme = {
  // TODO: Remove when @rushplay/theme uses `shadows`
  boxShadow: [
    '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    '0 5px 9px 0 rgba(0, 0, 0, 0.1)',
    '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
  ],
  breakpoints,
  fonts: {
    head: 'Gilroy, Helvetica, Arial, sans-serif',
    body: 'Gilroy, Helvetica, Arial, sans-serif',
    sizes: ['12px', '16px', '18px', '24px', '30px', '38px', '40px'],
  },
  fontSizes: ['12px', '16px', '18px', '24px', '30px', '38px', '40px'],
  paddings: ['8px', '16px', '24px', '32px', '48px', '64px'],
  space: ['8px', '16px', '24px', '32px', '48px', '64px'],
  colors: R.mergeAll([
    createColorSet('black', '#112E40'),
    createColorSet('mono', '#F3F5F7'),
    createColorSet('gray', '#7D838B'),
    createColorSet('green', '#18CF65'),
    createColorSet('blue', '#58B1CB'),
    createColorSet('orange', '#ff8247'),
    createColorSet('red', '#eb5769'),
    {
      background: '#FFFFFF',
      blackpearl: '#030f17',
      darkblue: '#112d40',
      darkgray: '#61696B',
      grayblue: '#213e51',
      grayish: '#ebf1f2',
      input: '#969B9C',
      inputFocus: '#112E40',
      inputInvalid: '#EC420C',
      inputTitle: '#61696B',
      inputValid: '#2DB300',
      lottobase: '#7a44aa',
      midnightBlue: '#0f1c32',
      primary: '#ff8247',
      progressbarBackground: '#CBCCCD',
      progressbarBackgroundActive: '#FF8000',
      drawerBackground: '#F4F5F6',
      secondary: '#cb5b2b',
      simpleorange: '#FF8000',
      storePageBackground: '#F4F5F6',
      storePageTitle: 'white',
      veryDarkBlue: '#112E40',
      white: '#FFFFFF',
    },
  ]),
  shadows: [
    '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    '0 5px 9px 0 rgba(0, 0, 0, 0.1)',
    '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
  ],
}

const overrides = {
  md: {
    fonts: {
      sizes: ['14px', '18px', '22px', '28px', '38px', '48px', '80px'],
    },
    fontSizes: ['14px', '18px', '22px', '28px', '38px', '48px', '80px'],
  },
}

function getSearchTheme() {
  return {
    sizes: {
      searchSize: '40px',
      searchSizeSmall: '40px',
    },
    space: {
      searchSize: '40px',
      searchSizeSmall: '40px',
    },
  }
}

function createTheme() {
  const searchTheme = getSearchTheme()

  return R.mergeDeepRight(R.mergeDeepWith(R.merge, baseTheme, searchTheme), {})
}

const connector = R.compose(
  recompose.withProps(() => ({
    overrides: overrides,
    theme: createTheme(),
  }))
)

export const ThemeProvider = connector(Component)

export * from './global-styles'
