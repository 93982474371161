import * as R from 'ramda'
import * as recompose from 'recompose'
import url from 'url'
import { connect } from 'react-redux'

import * as locks from '@rushplay/compliance/locks'
import { isSessionActive } from '@rushplay/session'

import { HomePage as Component } from './home-page'

function mapStateToProps(state, props) {
  const urlParams = R.path(['query'], url.parse(props.location.search, true))
  const redirectUrlParam = R.path(['redirectUrl'], urlParams)

  return {
    authenticated: isSessionActive(state.session),
    url: redirectUrlParam || '/casino',
    locksAreInitiated: locks.isInitialized(state.locks),
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  recompose.branch(
    props => props.authenticated && !props.locksAreInitiated,
    recompose.renderNothing
  )
)

export const HomePage = connector(Component)
