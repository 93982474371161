import * as R from 'ramda'
import * as reselect from 'reselect'

/**
 * Get player session stats
 */
export function getSessionStats(state) {
  return R.pathOr({}, ['sessionStats'], state)
}

/**
 * Get insufficient and low balance band values for player currency
 */
export const getMemoizedBalanceBands = reselect.createSelector(
  [R.pathOr({}, ['information', 'balanceBandingsCents'])],
  R.identity
)

/**
 * Get deposit count
 */
export function getDepositCount(state) {
  return R.pathOr(null, ['information', 'depositNumber'], state)
}

/**
 * Get last deposit amount in EUR
 * @returns {number}
 */
export function getLastDepositAmount(state) {
  return R.pathOr(null, ['information', 'lastDepositEurCents'], state)
}

/**
 * Get initial deposit
 * @returns {number}
 */
export function getInitialDepositAmount(state) {
  return R.pathOr(0, ['depositInformation', 'initialDeposit'], state)
}

/**
 * Get last deposit amount
 * @returns {number}
 */
export function getLastDepositAmountCents(state) {
  return R.pathOr(null, ['information', 'lastDepositCents'], state)
}

export function getDepositLimits(state) {
  return R.pathOr([], ['depositInformation', 'depositLimits'], state)
}

export const getDepositLimitRemainder = reselect.createSelector(
  [getDepositLimits],
  depositLimits =>
    R.reduce(R.min, Infinity, R.pluck('leftToDepositCents', depositLimits))
)

export const getWithdrawalInformation = reselect.createSelector(
  [R.pathOr({}, ['withdrawInformation'])],
  state => ({
    bonusBalanceCents: state.bonusBalanceCents,
    moneyBalanceCents: state.moneyBalanceCents,
    sportWagerBalanceCents: state.sportWagerBalanceCents,
    wagerBalanceCents: state.wagerBalanceCents,
  })
)

export function getWithdrawalLimits(state) {
  return R.pathOr([], ['withdrawInformation', 'withdrawLimits'], state)
}

export const getWithdrawalLimit = reselect.createSelector(
  [getWithdrawalLimits],
  limits => R.reduce(R.min, Infinity, R.pluck('limitCents', limits))
)

export const getWithdrawalLimitRemainder = reselect.createSelector(
  [getWithdrawalLimits],
  limits => R.reduce(R.min, Infinity, R.pluck('leftToWithdrawCents', limits))
)

export const getWithdrawableBalanceCents = reselect.createSelector(
  [getWithdrawalInformation],
  info => info.moneyBalanceCents
)

/**
 * Get if user has deposited
 */
export function hasDeposited(state) {
  return getDepositCount(state) > 0
}

export function getCookieConsent(state) {
  return R.pathOr(false, ['cookieConsent'], state)
}

/**
 * Get player's state (province)
 */
export function getPlayerProvince(state) {
  return R.path(['information', 'stateCode'], state)
}

/**
 * Get if there are offers available
 */
export function hasDepositOffersAvailable(state) {
  return R.pathOr(false, ['information', 'depositOffersAvailable'], state)
}
