import * as R from 'ramda'

const RECEIPTS_FALLBACK = []
export function getAll(state) {
  return R.pathOr(RECEIPTS_FALLBACK, ['items'], state)
}

export function getById(state, transactionId) {
  return R.find(R.propEq('transactionId', transactionId), state.items)
}

export function getTotalPages(state) {
  return state.totalPages
}

export function getCurrentPage(state) {
  return state.currentPage
}

export function getTotalDepositsCents(state) {
  return R.path(['totalDepositCents', 'result', 'total_deposit_cents'], state)
}
