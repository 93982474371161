import * as R from 'ramda'
import * as ReactRouterDom from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'
import { timingFunctions } from 'polished'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import {
  IconClose,
  IconMagnifyingGlass,
  IconWrapper as _IconWrapper,
} from '@rushplay/icons'

import * as FormComponents from '../form-components'
import * as Utils from '../utils'

const SEARCH_URL = '/casino/search'
const searchSize = [null, 'searchSizeSmall', 'searchSize']
const Wrapper = styled(Common.Flex)`
  z-index: 1;
  position: absolute;
  overflow: hidden;
  border-radius: 50px;
  top: 3px;
  left: 0;
  ${props =>
    css({
      height: searchSize,
      width: props.visible ? '100%' : searchSize,
      boxShadow: 'searchShadow',
      transition: `width ${props.visible ? '500ms' : '200ms'} ${timingFunctions(
        'easeOutQuint'
      )}`,
      backgroundColor: 'simpleorange',
      '&:hover': {
        color: !props.visible && 'simpleorange',
      },
    })}
`
const IconWrapper = styled(_IconWrapper)`
  position: relative;
  ${css({
    width: searchSize,
    minWidth: searchSize,
    minHeight: searchSize,
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  })}
`
const FormContent = styled(Common.Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  ${css({
    paddingLeft: searchSize,
    color: 'searchColor',
    background: 'transparent',
  })}
`
const SearchField = styled(FormComponents.InputField)`
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;

  > input {
    color: white;

    &::placeholder {
      color: white;
    }
  }
`

function Consumer(props) {
  const query = Utils.Hooks.useQuery()
  const field = Forms.useField('#/properties/q', { noRegister: true })
  const form = Forms.useFormContext()
  const history = ReactRouterDom.useHistory()

  React.useEffect(() => {
    if (field.value && field.value.length > 1) {
      history.replace(`${SEARCH_URL}?q=${field.value}`)
    } else {
      history.replace(SEARCH_URL)
    }
  }, [field.value])

  React.useEffect(() => {
    return () => {
      form.destroyFields()
    }
  }, [])

  return (
    <FormContent>
      <SearchField
        autoFocus
        placeholder="casino.search.placeholder"
        initialValue={query.q}
        scope="#/properties/q"
      />
      <IconWrapper size="18px" onClick={props.onClose}>
        <IconClose />
      </IconWrapper>
    </FormContent>
  )
}

Consumer.propTypes = {
  onClose: PropTypes.func.isRequired,
}

const dataSchema = {
  type: 'object',
  properties: {
    q: {
      type: 'string',
      minLength: 1,
    },
  },
}

export function GameSearchInput() {
  const history = ReactRouterDom.useHistory()
  const location = ReactRouterDom.useLocation()
  const [backLink, setBackLink] = React.useState('/casino')
  const visible = R.startsWith('/casino/search', location.pathname)

  React.useEffect(() => {
    if (!visible) {
      setBackLink(location.pathname)
    }
  }, [visible, location.pathname])

  return (
    <Wrapper visible={visible}>
      {visible ? (
        <IconWrapper size="18px">
          <IconMagnifyingGlass />
        </IconWrapper>
      ) : (
        <Common.Link to="/casino/search">
          <IconWrapper size="18px">
            <IconMagnifyingGlass />
          </IconWrapper>
        </Common.Link>
      )}
      <Forms.Provider schema={dataSchema} name="search-games">
        {visible && <Consumer onClose={() => history.replace(backLink)} />}
      </Forms.Provider>
    </Wrapper>
  )
}
