import * as R from 'ramda'
import * as recompose from 'recompose'
import Loadable from 'react-loadable'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as Configuration from '../../configuration'
import * as CombinedSelectors from '../../combined-selectors'
import { Spinner } from '../common/spinner'

const LanguageSwitchFlags = Loadable({
  loader: () => import('./language-switch-flags'),
  render(loaded, props) {
    const Component = loaded.LanguageSwitchFlags
    return <Component {...props} />
  },
  loading: Spinner,
})

function mapStateToProps(state) {
  return {
    countryCode: CombinedSelectors.getCountryCode(state),
    supportedLanguages: Configuration.getLanguages(state.configuration),
  }
}

export const LanguageSwitch = R.compose(
  connect(mapStateToProps),
  withRouter,
  recompose.branch(
    props => props.supportedLanguages.length <= 1 || props.countryCode !== 'FI',
    recompose.renderNothing
  )
)(LanguageSwitchFlags)
