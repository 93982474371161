import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import * as Configuration from '../../configuration'

export function PaymentProviderImages(props) {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviders(state.configuration)
  )

  if (!paymentProviders.length) {
    return null
  }

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      {R.map(
        item => (
          <LazyLoad once height="34px" key={item.name} offset={100}>
            <Common.Box
              maxHeight="34px"
              as="img"
              alt={item.name}
              p={props.padding}
              src={item.image.url}
            />
          </LazyLoad>
        ),
        paymentProviders
      )}
    </Common.Box>
  )
}

PaymentProviderImages.defaultProps = {
  padding: 2,
}

PaymentProviderImages.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
