import { connect } from 'react-redux'

import * as websockets from '@rushplay/websockets'
import * as session from '@rushplay/session'

import * as configuration from '../configuration'

import { WebSocket as Component } from './web-socket'

function mapStateToProps(state) {
  return {
    pusherConfig: configuration.getPusher(state.configuration),
    username: session.getUsername(state.session),
    sessionToken: session.getSessionToken(state.session),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    sessionToken: stateProps.sessionToken,
    onInit: () =>
      dispatchProps.onInit(stateProps.pusherConfig.key, {
        auth: {
          params: {
            username: stateProps.username,
            sessionToken: stateProps.sessionToken,
          },
        },
        cluster: stateProps.pusherConfig.cluster,
        authEndpoint: stateProps.pusherConfig.authEndpoint,
        encrypted: stateProps.pusherConfig.encrypted,
        enabledTransports: stateProps.pusherConfig.enabledTransports,
      }),
  }
}

const connector = connect(
  mapStateToProps,
  { onInit: websockets.init },
  mergeProps
)

export const WebSocket = connector(Component)
