import PropTypes from 'prop-types'
import React from 'react'
import { differenceInMinutes } from 'date-fns'

import { Text, Timestamp } from '@rushplay/common'

export function CurrentSessionTime(props) {
  return props.withContent ? (
    <Text fontSize={0} textAlign="center">
      {props.translate('current-session-time.label')}{' '}
      <Timestamp>
        {timestamp => `${differenceInMinutes(timestamp, props.loggedInAt)} min`}
      </Timestamp>
    </Text>
  ) : (
    <Timestamp>
      {timestamp => `${differenceInMinutes(timestamp, props.loggedInAt)} min`}
    </Timestamp>
  )
}

CurrentSessionTime.propTypes = {
  loggedInAt: PropTypes.instanceOf(Date).isRequired,
  withContent: PropTypes.bool,
  translate: PropTypes.func,
}
