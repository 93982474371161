import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Forms from '@rushplay/forms'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from '../../components/icons'
import { Checkbox } from '../../form-components/checkbox'
import { Input } from '../../form-components/input'
import { countries } from '../../constants'

const StyledSelect = styled.button`
  ${css({
    position: 'relative',
    width: '100%',
    fontSize: '14px',
    fontFamily: 'body',
    border: '1px solid',
    borderColor: 'input',
    borderRadius: '4px',
    lineHeight: '24px',
    py: '12px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'transparent',
  })};
`
const Arrow = styled(Icons.ChevronDown)`
  ${props =>
    css({
      color: 'input',
      position: 'absolute',
      top: '50%',
      right: '10px',
      mt: '-0.5em',
      fontSize: '16px',
      transform: props.show && 'rotate(180deg)',
    })};
`

const RemoveButton = styled.button`
  border: none;
  background-color: inherit;
  font-weight: 600;
  color: #838383;
  cursor: pointer;
`

export default function MultipleSelect(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope)
  const [show, setShow] = React.useState(false)
  const [countryArray, setCountryArray] = React.useState([])
  const [searchArray, setSearchArray] = React.useState([])

  const sortedCountries = {
    ABC: countries.filter(name => name[0]?.match(/[A,B,C]/)),
    DEF: countries.filter(name => name[0]?.match(/[D,E,F]/)),
    GHI: countries.filter(name => name[0]?.match(/[G,H,I]/)),
    JKL: countries.filter(name => name[0]?.match(/[J,K,L]/)),
    MNO: countries.filter(name => name[0]?.match(/[M,N,O]/)),
    PQR: countries.filter(name => name[0]?.match(/[P,Q,R]/)),
    STU: countries.filter(name => name[0]?.match(/[S,T,U]/)),
    VWX: countries.filter(name => name[0]?.match(/[V,W,X]/)),
    YZA: countries.filter(name => name[0]?.match(/[Y,Z,Å]/)),
  }

  function handleOnChange(value, name) {
    if (value) {
      const newCountryArray = [...countryArray, name]
      setCountryArray(newCountryArray)
      return field.onChangeValue(newCountryArray)
    } else {
      const newCountryArray = countryArray.filter(country => country !== name)
      setCountryArray(newCountryArray)
      return field.onChangeValue(newCountryArray)
    }
  }

  function handleOnSearch(e) {
    if (e?.target?.value) {
      return setSearchArray(
        countries.filter(name =>
          name?.toLowerCase()?.startsWith(e?.target?.value?.toLowerCase())
        )
      )
    }
    setSearchArray([])
  }

  return (
    <>
      <StyledSelect onClick={() => setShow(prev => !prev)}>
        {i18n.translate('source-of-funds.select')}
        <Arrow show={show} size="16px" />
      </StyledSelect>
      {show ? (
        <Common.Box
          p="8px"
          border={show ? '1px solid' : 'none'}
          borderTop="none"
          borderColor="input"
        >
          <Common.Box ml="4px" mb="7px" fontWeight="700">
            {i18n.translate('source-of-funds.search-country')}
          </Common.Box>
          <Input
            onChange={e => handleOnSearch(e)}
            placeholder={i18n.translate(
              'source-of-funds.search-country.placeholder'
            )}
          />
          <Common.Box p="4px">
            {countryArray.map(name => (
              <Common.Box
                display="inline-flex"
                m="4px"
                key={`countryArray-${name}`}
                backgroundColor="#D9D9D9"
                alignItems="center"
                borderRadius="5px"
                fontWeight="400"
                fontSize="16px"
                py="4px"
                pl="8px"
                pr="4px"
              >
                {name}
                <RemoveButton
                  onClick={() => {
                    handleOnChange(false, name)
                  }}
                >
                  <Icons.Close size="8px" />
                </RemoveButton>
              </Common.Box>
            ))}
          </Common.Box>
          {searchArray.length !== 0 ? (
            <Common.Box
              mt="16px"
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridTemplateRows="auto"
            >
              {searchArray.map(name => (
                <Common.Space key={`searchArray-${name}`} my="2px">
                  <Checkbox
                    label={name}
                    value={countryArray.includes(name)}
                    onChange={value => {
                      handleOnChange(value, name)
                    }}
                  />
                </Common.Space>
              ))}
            </Common.Box>
          ) : (
            <Common.Box px="4px">
              {Object.keys(sortedCountries).map((group, index) => (
                <React.Fragment key={group}>
                  <Common.Box
                    borderTop={index !== 0 && '1px solid #ECECEC'}
                    pt={index !== 0 && '10px'}
                    display="flex"
                    justifyContent="space-between"
                    color="#838383"
                    my="16px"
                    fontWeight="700"
                  >
                    <Common.Text letterSpacing=".3rem">{group}</Common.Text>
                    {index !== 0 ? (
                      <Common.Text
                        as="a"
                        href="#top-select"
                        textDecoration="underline"
                        cursor="pointer"
                      >
                        {i18n.translate('source-of-funds.back-to-top')}
                      </Common.Text>
                    ) : null}
                  </Common.Box>
                  <Common.Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    gridTemplateRows="auto"
                  >
                    {sortedCountries[group].map(name => (
                      <Common.Space key={`fundsCountryOrigin-${name}`} my="4px">
                        <Checkbox
                          label={name}
                          value={countryArray.includes(name)}
                          onChange={value => {
                            handleOnChange(value, name)
                          }}
                        />
                      </Common.Space>
                    ))}
                  </Common.Box>
                </React.Fragment>
              ))}
            </Common.Box>
          )}
        </Common.Box>
      ) : null}
    </>
  )
}

MultipleSelect.propTypes = {
  scope: PropTypes.string,
}
