import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import { getTime } from 'date-fns'

import * as Common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { AltButtonInverted } from '../../common/button-simple'

// TODO: refactor images as TranslationImages
const IMAGES = {
  Freespins: '/images/casino-game.svg',
  FeatureTriggers: '/images/casino.svg',
  Freebet: '/images/freebet.svg',
  FreeGamesVoucher: '/images/freegamesvoucher.png',
}

export function InventoryItem(props) {
  const i18n = useI18n()

  return (
    <Common.Box
      display="flex"
      px={0}
      py={1}
      mx={0}
      borderTop="1px solid"
      borderColor="storePageBackground"
      backgroundColor="white"
    >
      <Common.Box
        size="80px"
        minWidth="80px"
        marginRight={0}
        borderRadius="5px"
        backgroundImage={`url(${props.item.gameImage ||
          IMAGES[props.item.type]})`}
        backgroundPosition="center"
        backgroundSize="cover"
      />
      <Common.Box overflow="auto">
        <Common.Text
          color="darkgray"
          fontSize="1"
          lineHeight="20px"
          letterSpacing="1px"
          fontWeight="700"
        >
          {i18n.translate(
            `notifications-screen.inventory.${R.toLower(props.item.type)}${
              props.item.blitz ? '.blitz' : ''
            }`,
            {
              gameTitle: props.item.gameTitle,
              amount: props.item.amount,
              betAmountCents: props.item.betAmountCents,
            }
          )}
        </Common.Text>
        {getTime(props.item.activeFrom) > Date.now() && (
          <Common.Box
            color="darkgray"
            pt="0"
            fontSize="14px"
            lineHeight={1.08}
            letterSpacing="1px"
            fontWeight="700"
          >
            {i18n.translate('notifications-screen.inventory.active-from', {
              dateTime: Common.toLocaleDate(
                Date.parse(props.item.activeFrom),
                props.language
              ).dateTime,
            })}
          </Common.Box>
        )}
        <Common.Space pt={1}>
          <AltButtonInverted
            disabled={
              props.item.gameDisabledForClientType ||
              props.isLoading ||
              !props.item.active
            }
            onClick={props.onActivateItem}
          >
            {i18n.translate('notifications-screen.inventory.cta')}
          </AltButtonInverted>
        </Common.Space>
      </Common.Box>
    </Common.Box>
  )
}

InventoryItem.propTypes = {
  isLoading: PropTypes.bool,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  onActivateItem: PropTypes.func.isRequired,
}
