import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as CombinedSelectors from '../../combined-selectors'
import { HtmlContent } from '../../components/common/html-content'
import { InputField } from '../input-field'

import { toPhoneNumberFormat } from './to-phone-number-format'

export function PhoneVerificationCodeInput(props) {
  const i18n = I18n.useI18n()
  const country = ReactRedux.useSelector(state =>
    CombinedSelectors.getCountry(state)
  )
  const language = ReactRedux.useSelector(state =>
    CombinedSelectors.getLanguage(state)
  )

  const [visible, setVisible] = React.useState(false)

  const { value: phoneNumber } = Forms.useField('#/properties/phonenumber', {
    noRegister: true,
  })
  const { value: countryCallingCode } = Forms.useField(
    '#/properties/countryCallingCode',
    {
      noRegister: true,
    }
  )

  const fullNumber = `+${countryCallingCode}${R.replace(
    /^0+/,
    '',
    phoneNumber
  )}`

  function handleResend() {
    props.onResend({
      countryCallingCode: `+${countryCallingCode}`,
      language: language || 'en',
      phone: phoneNumber,
    })
    setVisible(true)
  }

  return (
    <Common.Box display="grid" gridGap={0} fontSize={1} py={0}>
      <HtmlContent
        html={{
          __html: i18n.translate('register.phone-verification.info', {
            number: toPhoneNumberFormat(country.alpha2, fullNumber),
          }),
        }}
      />
      <Common.Box
        display="grid"
        gridTemplateColumns="70% 1fr"
        gridGap={1}
        alignItems="center"
      >
        <Common.Box overflow="hidden" pb={0}>
          <Common.Box fontWeight="bold" pb={0}>
            <Common.Text content="registration.phone-verification-code.title" />
          </Common.Box>
          <InputField {...props} />
        </Common.Box>
        <Common.Flex flexDirection="column">
          {visible && (
            <Common.Box>
              {i18n.translate('register.phone-verification.code-sent')}
            </Common.Box>
          )}
          <Common.Box
            onClick={handleResend}
            textAlign="center"
            fontWeight="bold"
            pt={1}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {i18n.translate('register.phone-verification.resend')}
          </Common.Box>
        </Common.Flex>
      </Common.Box>
    </Common.Box>
  )
}

PhoneVerificationCodeInput.propTypes = {
  onResend: PropTypes.func.isRequired,
}
