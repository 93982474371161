import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'

import * as Processes from '@rushplay/processes'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as Constants from '../constants'
import * as CombinedSelectors from '../combined-selectors'
import * as FormComponents from '../form-components'
import { HtmlContent } from '../components/common/html-content'

import * as uiSchema from './ui-schema'
import { Checkbox } from './checkbox'

export function RegistrationForm(props) {
  const i18n = I18n.useI18n()
  const country = ReactRedux.useSelector(state =>
    CombinedSelectors.getCountry(state)
  )
  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['PHONE_VERIFICATION_LOADING', 'REGISTRATION_LOADING'],
    })
  )

  return (
    <React.Fragment>
      {props.step === Constants.RegistrationStep.Credentials && (
        <React.Fragment>
          <FormComponents.FieldBuilder
            uiSchema={uiSchema.step1}
            name="registration-step1"
            initialValues={{
              '#/properties/countryCallingCode': R.toString(
                country.countryCode
              ),
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/password': {
                  return FormComponents.InputPasswordField
                }

                default: {
                  return null
                }
              }
            }}
          />
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="inputInvalid" fontSize={0}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <FormComponents.SubmitButton
              onlyScopes={[
                '#/properties/email',
                '#/properties/password',
                '#/properties/phonenumber',
              ]}
              loading={loading}
            >
              {i18n.translate('register.step1.cta')}
            </FormComponents.SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}

      {props.step === Constants.RegistrationStep.Identity && (
        <React.Fragment>
          <FormComponents.FieldBuilder
            uiSchema={uiSchema.step2}
            name="registration-step2"
            initialValues={{
              '#/properties/country': country.name,
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/promotional': {
                  return AllowCheckbox
                }

                case '#/properties/phoneVerificationCode': {
                  return FormComponents.PhoneVerificationCodeInput
                }

                default: {
                  return null
                }
              }
            }}
          />
          <Common.Box pt="10px">
            <HtmlContent>
              {i18n.translate('register-drawer.terms-and-privacy-agreement')}
            </HtmlContent>
          </Common.Box>
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="inputInvalid" fontSize={0}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <FormComponents.SubmitButton loading={loading}>
              {i18n.translate('register.step2.cta')}
            </FormComponents.SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  errors: PropTypes.array,
  step: PropTypes.number.isRequired,
}

function AllowCheckbox(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: true,
  })

  return (
    <Checkbox
      id={field.name}
      name={field.name}
      checked={field.value}
      label={props.label ? props.label : i18n.translate(field.label)}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

AllowCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scope: PropTypes.string,
}

function LabelWithLink(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope)
  return (
    <Common.Box>
      {i18n.translate(field.label)}
      <Common.Box
        as={ReactRouterDom.Link}
        to={props.to}
        style={{ textDecoration: 'underline' }}
        ml="4px"
      >
        {i18n.translate(kebabCase(R.tail(props.to)))}
      </Common.Box>
    </Common.Box>
  )
}

LabelWithLink.propTypes = {
  scope: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
