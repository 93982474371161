import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { IconClose, IconWrapper } from '@rushplay/icons'
import { Space, noneOf } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

const backgroundColor = {
  success: t.color('green'),
  error: t.color('orange'),
  info: t.color('blue'),
}

export const Toast = styled('div', { shouldForwardProp: noneOf(['level']) })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: ${t.spacing(1)};
  color: ${t.color('white')};
  background-color: ${props => backgroundColor[props.level]};
  font-size: ${t.fontSize(0)};
  font-family: ${t.font('body')};
  box-shadow: 0 2px 6px 0px rgb(0, 0, 0, 0.3);
  font-weight: bold;
`

export function Notification(props) {
  const i18n = useI18n()
  return (
    <Toast onClick={props.onDismiss} level={props.level}>
      {i18n.isExistingTranslation(props.notification.message)
        ? i18n.translate(
            props.notification.message,
            props.notification.variables
          )
        : props.notification.message}
      <Space paddingLeft={0}>
        <IconWrapper>
          <IconClose />
        </IconWrapper>
      </Space>
    </Toast>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string.isRequired,
    variables: PropTypes.object,
  }).isRequired,
  level: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
  onDismiss: PropTypes.func.isRequired,
}
