import * as R from 'ramda'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as session from '@rushplay/session'

import * as Constants from '../../constants'
import * as configuration from '../../configuration'
import * as inventory from '../../inventory'
import * as player from '../../player'
import * as promotions from '../../promotions'
import * as ui from '../../ui'
import * as combinedSelectors from '../../combined-selectors'
import * as lookup from '../../lookup'

import { Topbar as Component } from './topbar'

function mapStateToProps(state, props) {
  return {
    authenticated: session.isSessionActive(state.session),
    balanceCents: session.getBalanceCents(state.session),
    countryCode: lookup.getCountryCode(state.lookup),
    depositCount: player.getDepositCount(state.player),
    features: configuration.getFeatures(state.configuration),
    hasNewNotifications:
      promotions.hasUnseenPromotions(state.promotions) ||
      inventory.hasUnseenInventory(state.inventory),
    hasSportsbook: combinedSelectors.isSportsbookEnabled(state),
    isEmbedded: combinedSelectors.getIsEmbedded(state),
    isMobile: combinedSelectors.getIsMobile(state),
    isLandingPage: R.pathEq(['pathname'], '/', props.location),
    notificationsVisible: ui.getVisibility(state.ui, { id: 'notifications' }),
    returningPlayer: ui.getVisibility(state.ui, { id: 'login' }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onShowSidebar: () => ui.toggleVisibility('sidebar', true),
      onRegister: () => ui.toggleVisibility('register', true),
      onShowDeposit: () =>
        ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true),
      onToggleNotifications: () => ui.toggleVisibility('notifications'),
    },
    dispatch
  )
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

export const Topbar = connector(Component)
