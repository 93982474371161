import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as api from '@rushplay/api-client'

import * as combinedSelectors from '../../combined-selectors'
import * as inventory from '../../inventory'
import * as promotions from '../../promotions'
import * as ui from '../../ui'

import { NotificationsScreen as Component } from './notifications-screen'

function getActivePromotions(state) {
  return R.reject(
    R.pathEq(['campaign', 'claimed'], true),
    R.values(promotions.getActivePromotions(state.promotions))
  )
}

function getFuturePromotions(state) {
  return R.values(promotions.getFuturePromotions(state.promotions))
}

function getInventoryItems(state) {
  const hasSportsbook = combinedSelectors.isSportsbookEnabled(state)
  return R.reject(
    item => R.not(hasSportsbook) && R.pathEq(['type'], 'Freebet', item),
    R.values(inventory.getInventoryItems(state.inventory))
  )
}

function mapStateToProps(state) {
  return {
    activeNotifications: R.pipe(R.sortBy(R.path(['activeFrom'])))(
      R.concat(getActivePromotions(state), getInventoryItems(state))
    ),
    futureNotifications: R.sortBy(
      R.path(['activeFrom']),
      getFuturePromotions(state)
    ),
    visible: ui.getVisibility(state.ui, { id: 'notifications' }),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () =>
      dispatch([
        api.markNotificationsAsSeen({
          success: () => [
            promotions.markAllPromotionsAsSeen(),
            inventory.markAllInventoryAsSeen(),
          ],
          version: 1,
        }),
        ui.toggleVisibility('notifications', false),
      ]),
    onFetch: () => dispatch(promotions.fetch()),
  }
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  recompose.branch(props => !props.visible, recompose.renderNothing)
)

export const NotificationsScreen = connector(Component)
