import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'

import { Checkbox } from './checkbox'

export function CheckboxField(props) {
  const field = Forms.useField(props.scope)

  return (
    <Checkbox
      label={props.label || field.label}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scope: PropTypes.string.isRequired,
}
