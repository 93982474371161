import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Session from '@rushplay/session'

import * as _Session from '../../../session'
import * as Notifications from '../../../notifications'
import * as Actions from '../actions'

import { VerifyNumber as Component } from './verify-number'

export const VerifyNumber = ReactRedux.connect(
  state => ({
    countryCallingCode: parseInt(Session.getCountryCallingCode(state.session)),
    marketingOptOut: _Session.getAllowSms(state.session),
    phoneNumber: Session.getPhoneNumber(state.session),
    token: Session.getSessionToken(state.session),
  }),
  dispatch => ({
    onResendVerificationCode: () => dispatch(Actions.requestSmsVerification()),
    onVerify: (token, verificationCode) =>
      dispatch(
        Api.verifyPhoneNumber(verificationCode, {
          success: () => _Session.refresh(token),
          failure: () =>
            Notifications.add(
              { message: 'error.verify-phone-number.verify-phone-number' },
              'error'
            ),
          version: 1,
        })
      ),
  })
)(Component)
