import * as R from 'ramda'
import * as recompose from 'recompose'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import React from 'react'

import * as Emotion from '@emotion/core'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Box, Link, noneOf } from '@rushplay/common'
import { IconBlitz } from '@rushplay/icons'
import { useI18n } from '@rushplay/i18n'

import { Amount } from '../../components/common/amount'
import { LoginRequiredOverlay } from '../login-required-overlay'
import { getGameUrl } from '../../utils/get-game-url'

const GameLink = R.compose(
  recompose.branch(props => R.isNil(props.to), recompose.renderComponent('a'))
)(Link)

const Wrapper = styled(GameLink)`
  position: relative;
  display: inline-block;
  box-shadow: ${t.boxShadow(0)};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  margin: 3px;
  width: 100%;
`

const Label = styled.div`
  color: white;
  font-family: ${t.font('head')};
  font-size: ${t.fontSize(0)};
  font-weight: 700;
  padding: 3px ${t.spacing(0)};

  ${props => Emotion.css`
    background-color: ${props.theme.colors.simpleorange};
  `}
`

const Ribbon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
`

const RibbonLabel = styled(Label)`
  text-transform: uppercase;
  margin-left: -25%;
  margin-right: -25%;
  transform: rotateZ(45deg) translate(16px);
`

const ExclusiveWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  pointer-events: none;
`

const ExclusiveLabel = styled.div`
  ${props => Emotion.css`
    background-color: ${props.theme.colors.simpleorange};
  `}

  color: white;
  font-family: ${t.font.head};
  font-size: ${t.fontSize(0)};
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-right: 1em;
  padding-left: 0.3em;
  text-align: center;
  display: inline-block;
`

const PreRelease = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const PreReleaseLabel = styled(Label)`
  text-transform: uppercase;
  margin-top: 60%;
  text-align: center;
`

const Jackpot = styled.div`
  position: absolute;
  top: ${t.spacing(0)};
  left: 0;
  pointer-events: none;
`

const JackpotLabel = styled(Label)`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 4px ${t.spacing(0)};
  text-align: center;
  white-space: nowrap;
`

const Blitz = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 20px;
  width: 25px;
  height: 25px;
  padding: 3px 0 4px 4px;
  background-color: #ebf1f2;

  ${t.mediaQuery.sm`
    width: 35px;
    height: 35px;
    padding: 4px 2px 4px 5px;
  `};
`

const ImageBackground = styled.div`
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;

  ${props => Emotion.css`
    background-image: url('${props.backgroundUrl}');
  `}

  ${props => {
    return Emotion.css`
        /* Writing media query by hand as we can’t run functions inside "css" */
        @media (min-width: ${props.theme.breakpoints.sm}) {
          height: 150px;
        }
      `
  }}
`

const Blur = styled('div', { shouldForwardProp: noneOf(['when']) })`
  ${props => (props.when ? `filter: blur(5px)` : ``)};
`

function getGamePath(id, blitzEnabled) {
  if (id) {
    if (blitzEnabled) {
      return `/casino/games/${id}/mode?referrer=casino`
    }
    return getGameUrl(id)
  }
  return ''
}

export function GameTile(props) {
  const fallbackThumbnail = `data:image/svg+xml;utf8,<svg width="190" height="160" xmlns="http://www.w3.org/2000/svg" version="1.1"><rect height="160" width="190" stroke-width="1" stroke-dasharray="5,5" fill="white" stroke="lightgray" stroke-linecap="round" rx="4" ry="4"/><text x="95" y="80" text-anchor="middle">${props.title}</text></svg>`
  const isExclusive = props.tags.includes('exclusive')
  const isHot = !isExclusive && props.tags.includes('hot')
  const isNew = !isExclusive && !isHot && props.tags.includes('new')

  const i18n = useI18n()

  return (
    <React.Fragment>
      <LazyLoad once height="160px">
        <Wrapper to={getGamePath(props.id, props.blitzEnabled)}>
          <ImageBackground
            backgroundUrl={props.imageUrl ? props.imageUrl : fallbackThumbnail}
          />
          {isExclusive && (
            <ExclusiveWrapper>
              <ExclusiveLabel>
                {i18n.translate('game-tile.exclusive')}
              </ExclusiveLabel>
            </ExclusiveWrapper>
          )}
          {isHot && (
            <Ribbon>
              <RibbonLabel>{i18n.translate('game-tile.hot')}</RibbonLabel>
            </Ribbon>
          )}
          {isNew && (
            <Ribbon>
              <RibbonLabel>{i18n.translate('game-tile.new')}</RibbonLabel>
            </Ribbon>
          )}
          {props.preRelease && (
            <PreRelease>
              <PreReleaseLabel>
                {i18n.translate('games.coming-soon')}
              </PreReleaseLabel>
            </PreRelease>
          )}
          {props.blitzEnabled && (
            <Blitz>
              <IconBlitz />
            </Blitz>
          )}
          {props.jackpotValue > 0 && (
            <Jackpot>
              <JackpotLabel>
                <Amount decimals="show">{parseInt(props.jackpotValue)}</Amount>
              </JackpotLabel>
            </Jackpot>
          )}
          {props.loginRequired && <LoginRequiredOverlay />}
        </Wrapper>
        <Blur when={props.id == null}>
          <Box maxWidth="200px" padding="8px">
            <Box paddingBottom="0" fontSize="14px" letterSpacing="0.05em">
              {props.displayProvider}
            </Box>
          </Box>
        </Blur>
      </LazyLoad>
    </React.Fragment>
  )
}

GameTile.defaultProps = {
  displayProvider: '••••••',
  color: 'black',
  tags: [],
  title: '•••••••••',
}

GameTile.propTypes = {
  blitzEnabled: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  displayProvider: PropTypes.string,
  id: PropTypes.number,
  imageUrl: PropTypes.string,
  jackpotUpdatedAt: PropTypes.number,
  jackpotValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  jackpotVelocity: PropTypes.number,
  loginRequired: PropTypes.bool,
  preRelease: PropTypes.bool,
  provider: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  backgroundUrl: PropTypes.string,
}
