import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'

import * as types from './types'

export function reducer(state = {}, action) {
  switch (action.type) {
    case types.UPDATED: {
      return R.pipe(
        R.set(R.lensPath(['currentPage']), action.payload.meta.page),
        R.set(R.lensPath(['totalPages']), action.payload.meta.total_pages),
        R.set(R.lensPath(['items']), action.payload.receipts)
      )(state)
    }
    case types.TOTALS_UPDATED:
      return R.merge(state, {
        balanceTotals: action.payload,
      })

    case types.TOTAL_DEPOSITS_UPDATED:
      return R.merge(state, {
        totalDepositCents: action.payload,
      })

    case websockets.SESSION_EXPIRED: {
      return {}
    }

    default: {
      return state
    }
  }
}
