import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Session from '@rushplay/session'

import * as Ui from './ui'
import { RegistrationDrawer } from './registration-drawer'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function Registration() {
  const history = ReactRouterDom.useHistory()
  const dispatch = ReactRedux.useDispatch()
  const authenticated = ReactRedux.useSelector(state =>
    Session.getSessionToken(state.session)
  )
  const enabled = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )
  const visible = ReactRedux.useSelector(state =>
    Ui.getVisibility(state.ui, { id: 'register' })
  )
  const registerQuery = useSafeUpdateQuery({ register: 'me' })

  React.useEffect(() => {
    if (visible) {
      history.push(`?${registerQuery}`)
      dispatch(Ui.toggleVisibility('register', false))
    }
  }, [visible])

  if (authenticated || !enabled) {
    return null
  }

  return <RegistrationDrawer />
}
