import * as types from './types'

export function reducer(state = {}, action) {
  switch (action.type) {
    case types.GAME_CATALOG_CATEGORIES_UPDATED: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
