import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Utils from '../../../utils'
import * as FormComponents from '../../../form-components'
import { H2 } from '../../../components/common/headings'

const dataSchema = {
  type: 'object',
  properties: {
    countryCallingCode: {
      type: 'string',
    },
    phoneNumber: {
      type: 'string',
      minLength: 9,
    },
    marketingOptOut: {
      type: 'boolean',
    },
  },
  required: ['countryCallingCode', 'phoneNumber'],
}

export function CollectNumber(props) {
  const i18n = I18n.useI18n()
  const query = Utils.Hooks.useQuery()
  const preferredCountryCallingCode = Utils.Hooks.useCountryCallingCode()
  const history = ReactRouterDom.useHistory()

  return (
    <Forms.Provider
      name="collect-phone-number"
      schema={dataSchema}
      onSubmit={(errors, data) => {
        if (R.isEmpty(errors)) {
          props.onSendVerificationCode(data)
          history.push('?step=2')
        }
      }}
    >
      <Common.Flex alignItems="center" flexDirection="column">
        <Common.Box mt="0px">
          <H2 align="center" color="black" weight={800}>
            {i18n.translate('collect-phone-number.step1.heading')}
          </H2>
        </Common.Box>
        <Common.Box mt={0}>
          <Common.Text
            fontSize="1.1em"
            font="head"
            lineHeight={1.5}
            align="center"
          >
            {i18n.translate('collect-phone-number.step1.description1')}
          </Common.Text>
        </Common.Box>
        <Common.Box mt={1}>
          <Common.Flex>
            <Common.Box maxWidth="100px" paddingRight="0">
              <FormComponents.SelectField
                scope="#/properties/countryCallingCode"
                initialValue={
                  query.countryCallingCode ||
                  preferredCountryCallingCode ||
                  R.pathOr(
                    '',
                    [0, 'value'],
                    props.countryCallingCodeOptions
                  ).toString()
                }
                options={props.countryCallingCodeOptions}
              />
            </Common.Box>
            <Common.Box grow="1">
              <FormComponents.InputField
                autoFocus
                autoComplete="tel"
                initialValue={query.phoneNumber}
                inputMode="numeric"
                scope="#/properties/phoneNumber"
              />
            </Common.Box>
          </Common.Flex>
        </Common.Box>
        <Common.Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          mr="10px"
          mt={0}
          width="300px"
        >
          <FormComponents.FieldResponse scope="#/properties/phoneNumber" />
        </Common.Box>
        <Common.Box
          fontSize="1.1em"
          font="head"
          lineHeight={1.5}
          align="center"
          mt={2}
        >
          {i18n.translate('collect-phone-number.step1.description2')}
        </Common.Box>
        <Common.Space mt={0}>
          <Common.Text fontSize="0">
            <FormComponents.CheckboxField
              scope="#/properties/marketingOptOut"
              initialValue={R.pathEq(['marketingOptOut'], 'true', query)}
              label="collect-phone-number.step1.marketing-opt-out-description"
            />
          </Common.Text>
        </Common.Space>
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="200px"
          mt={2}
        >
          <FormComponents.SubmitButton>
            {i18n.translate('collect-phone-number.step1.button.primary')}
          </FormComponents.SubmitButton>
          {props.optional && (
            <Common.Box
              mt="1"
              onClick={props.onDismiss}
              textDecoration="underline"
              textAlign="center"
              style={{ cursor: 'pointer' }}
            >
              {i18n.translate('collect-phone-number.step1.button.secondary')}
            </Common.Box>
          )}
        </Common.Box>
      </Common.Flex>
    </Forms.Provider>
  )
}

CollectNumber.propTypes = {
  countryCallingCodeOptions: PropTypes.array,
  optional: PropTypes.bool,
  preferredCountryCallingCode: PropTypes.number,
  onDismiss: PropTypes.func,
  onSendVerificationCode: PropTypes.func,
}
