import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import styled from '@emotion/styled'
import { ClassNames, css } from '@emotion/core'

import { SpinnerIcon } from '../common/spinner-icon'

const enterWrapper = css`
  opacity: 0;
`

const enterActiveWrapper = css`
  opacity: 1;
  transition: opacity 2000ms ease;
`

const exitWrapper = css`
  opacity: 1;
`

const exitActiveWrapper = css`
  opacity: 0;
  transition: opacity 500ms ease;
`

const enterInner = css`
  transform: translateY(100%);
`

const enterActiveInner = css`
  transform: translateY(0%);
  transition: transform 500ms ease;
`

const exitInner = css`
  transform: translateY(0%);
`

const exitActiveInner = css`
  transform: translateY(100%);
  transition: transform 500ms ease;
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
`

const InnerWrapper = styled.div`
  padding: 8px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: white;
`

export function Loader(props) {
  return (
    // Wrapped with ClassNames to be able to get the class for custom class on render
    <ClassNames>
      {classNames => (
        <CSSTransition
          classNames={{
            enter: classNames.css`${enterWrapper}`,
            enterActive: classNames.css`${enterActiveWrapper}`,
            exit: classNames.css`${exitWrapper}`,
            exitActive: classNames.css`${exitActiveWrapper}`,
          }}
          in={props.visible}
          // We make the enter animation take 2s. This way we're forcing the delay as it won't reach "entered" until after 2s
          timeout={{ enter: 2000, exit: 500 }}
          unmountOnExit
        >
          {animationState => (
            // ClassNames needs to wrap my transition again to be able to read the custom classes.
            // Since this component will be unmounted we can't load the classes with the help of the topmost classNames
            <ClassNames>
              {classNames2 => (
                <Wrapper>
                  <CSSTransition
                    classNames={{
                      enter: classNames2.css`${enterInner}`,
                      enterActive: classNames2.css`${enterActiveInner}`,
                      exit: classNames2.css`${exitInner}`,
                      exitActive: classNames2.css`${exitActiveInner}`,
                    }}
                    /*
                     * Here is where we activate the actual animation.
                     * If we didn't have the delayed first animation CSSTransition would apply the exit and exit-active classes
                     * which would add the exit-animations which will show the component even though it might not have entered.
                     */
                    in={animationState === 'entered'}
                    timeout={500}
                    unmountOnExit
                  >
                    <InnerWrapper>
                      <SpinnerIcon />
                    </InnerWrapper>
                  </CSSTransition>
                </Wrapper>
              )}
            </ClassNames>
          )}
        </CSSTransition>
      )}
    </ClassNames>
  )
}

Loader.propTypes = {
  theme: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
}
