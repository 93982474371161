import * as Analytics from '@rushplay/analytics'

import * as Types from './types'

export function openWallet(payload) {
  return Analytics.queueEvent({
    type: Types.OPEN_WALLET,
    payload,
  })
}

export function openSideMenu(payload) {
  return Analytics.queueEvent({
    type: Types.OPEN_SIDE_MENU,
    payload,
  })
}

export function clickSideMenuItem(payload) {
  return Analytics.queueEvent({
    type: Types.CLICK_SIDE_MENU_ITEM,
    payload,
  })
}

export function acceptCookieConsent() {
  return Analytics.queueEvent({
    type: Types.ACCEPT_COOKIE_CONSENT,
  })
}
