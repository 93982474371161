import * as R from 'ramda'

/**
 * Get available notification IDs
 */
export function getAll(state) {
  return R.reject(R.isNil, R.pluck('id', state))
}

/**
 * Get notification object
 */
export function getOne(state, props) {
  return state[props.id]
}
