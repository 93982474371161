import PropTypes from 'prop-types'
import React from 'react'

import { Image, Link, Space } from '@rushplay/common'

export function ImageLinkItem(props) {
  return (
    <Space paddingRight={1} paddingBottom={1}>
      <Link to={props.to} target={props.target} rel={props.rel}>
        <Image size="fixed" height={34} src={props.src} alt={props.alt} />
      </Link>
    </Space>
  )
}

ImageLinkItem.defaultProps = {
  rel: 'noopener noreferrer',
}

ImageLinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
}
