import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import { ClassNames, css } from '@emotion/core'

const transition = css`
  transition-property: opacity;
  transition-timing-function: ease;
  transition-duration: 300ms;
`

const enter = css`
  opacity: 0;
`

const enterActive = css`
  opacity: 1;
  ${transition};
`

const exit = css`
  opacity: 1;
`

const exitActive = css`
  opacity: 0;
  ${transition};
`

export function Fade(props) {
  return (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          classNames={{
            enter: css`
              ${enter}
            `,
            enterActive: css`
              ${enterActive}
            `,
            exit: css`
              ${exit}
            `,
            exitActive: css`
              ${exitActive}
            `,
          }}
          in={props.in}
          mountOnEnter
          timeout={300}
          unmountOnExit
        >
          {props.children}
        </CSSTransition>
      )}
    </ClassNames>
  )
}
Fade.propTypes = {
  in: PropTypes.bool,
  children: PropTypes.node,
}
