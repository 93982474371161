import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import { FieldResponse } from './field-response'
import { Input } from './input'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

export function InputField(props) {
  const i18n = I18n.useI18n()
  const form = Forms.useFormContext()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  const placeholder = props.placeholder || field.placeholder

  return (
    <Common.Box display="grid" gridGap={0} width="100%">
      <Input
        visited={
          !R.includes(field.status, [
            Forms.FieldStatus.PRISTINE,
            Forms.FieldStatus.ABSENT,
          ])
        }
        valid={R.isEmpty(field.errors)}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        className={props.className}
        disabled={props.disabled}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        id={`${form.name}-${field.name}`}
        prependIcon={props.prependIcon}
        placeholder={
          i18n.isExistingTranslation(placeholder)
            ? i18n.translate(placeholder)
            : ''
        }
        type={props.type}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  normalize: value => value || undefined,
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  prependIcon: PropTypes.element,
  scope: PropTypes.string,
  type: PropTypes.string,
}
