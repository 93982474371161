import * as React from 'react'
import PropTypes from 'prop-types'

const SseConnectionContext = React.createContext()

export function SseConnectionProvider(props) {
  const [, setSources] = React.useState([])

  const subscribe = React.useCallback(
    config => {
      const eventSource = new EventSource(config.url)

      setSources(sources => [...sources, eventSource])

      eventSource.addEventListener('message', config.callback)
      eventSource.addEventListener('close', () => {
        unsubscribe(eventSource)
      })

      return () => {
        unsubscribe(eventSource)
      }
    },
    [setSources]
  )

  const unsubscribe = React.useCallback(
    eventSource => {
      eventSource.close()
      setSources(sources => sources.filter(source => source !== eventSource))
    },
    [setSources]
  )

  const value = React.useMemo(
    () => ({
      subscribe,
      unsubscribe,
    }),
    [subscribe, unsubscribe]
  )

  return (
    <SseConnectionContext.Provider value={value}>
      {props.children}
    </SseConnectionContext.Provider>
  )
}

SseConnectionProvider.propTypes = {
  children: PropTypes.node,
}

export function useContext() {
  return React.useContext(SseConnectionContext)
}
