import * as R from 'ramda'
import * as Recompose from 'recompose'
import * as DateFns from 'date-fns'
import * as ReduxEffects from 'redux-effects'
import * as ReactRedux from 'react-redux'
import * as ReduxEffectsUniversalCookie from 'redux-effects-universal-cookie'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as I18n from '@rushplay/i18n'

import * as Player from '../player'
import * as Ui from '../ui'
import * as Analytics from '../analytics'

import { CookieBanner as Component } from './cookie-banner'

function mapStateToProps(state) {
  return {
    licenseId: Jurisdiction.getLicense(state.jurisdiction),
    visible: Ui.getVisibility(state.ui, { id: 'cookie-banner' }),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onHandleSubmit: () => {
      dispatch([
        Analytics.acceptCookieConsent(),
        ReduxEffectsUniversalCookie.cookie('cookie_consent', true, {
          httpOnly: false,
          // TODO: Replace new Date with timestamp from redux-effects-timestamp
          expires: DateFns.addDays(new Date(), 180),
          path: '/',
        }),
        Ui.toggleVisibility('cookie-banner', false),
        Player.updateCookieConsent(true),
      ])
    },
    onSetVisibility: () =>
      dispatch(
        ReduxEffects.bind(
          ReduxEffectsUniversalCookie.cookie('cookie_consent'),
          cookieConsent => Ui.toggleVisibility('cookie-banner', !cookieConsent)
        )
      ),
  }
}

const connector = R.compose(
  I18n.withTranslate,
  ReactRedux.connect(mapStateToProps, mapDispatchToProps),
  Recompose.branch(
    props => R.equals(props.licenseId, 'curacao'),
    Recompose.renderNothing
  )
)

export const CookieBanner = connector(Component)
