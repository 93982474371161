import PropTypes from 'prop-types'
import React from 'react'

import { Box, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { H1 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { NotFound } from '../../components/not-found'
import { PrimaryButton } from '../../components/common/button'

export function PromotionTermsConditions(props) {
  const i18n = useI18n()
  const promotionTitleKey = `promotions.${props.match.params.key}.title`

  if (!i18n.isExistingTranslation(promotionTitleKey)) {
    return (
      <Box position="relative">
        <Box
          paddingBottom={[1, null, 3]}
          display="flex"
          justifyContent="center"
          position="sticky"
          top="88px"
          backgroundColor="grayish"
        >
          <PrimaryButton
            onClick={() => {
              if (window) {
                window.close()
              }
            }}
          >
            {i18n.translate('bonus.terms-conditions.close')}
          </PrimaryButton>
        </Box>
        <NotFound />
      </Box>
    )
  }

  return (
    <Box maxWidth="800" margin="auto" position="relative">
      <Box
        paddingBottom={[1, null, 3]}
        display="flex"
        justifyContent="center"
        position="sticky"
        top="88px"
        backgroundColor="grayish"
      >
        <PrimaryButton
          onClick={() => {
            if (window) {
              window.close()
            }
          }}
        >
          {i18n.translate('bonus.terms-conditions.close')}
        </PrimaryButton>
      </Box>
      <Space paddingBottom={[0, null, 4]}>
        <H1 align="center" color="gray">
          {i18n.translate(promotionTitleKey)}
        </H1>
      </Space>
      <HtmlContent
        html={{
          __html: i18n.translate(
            `promotions.${props.match.params.key}.terms-conditions`
          ),
        }}
      />
    </Box>
  )
}

PromotionTermsConditions.propTypes = {
  match: PropTypes.object.isRequired,
}
