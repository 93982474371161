import * as DateFns from 'date-fns'
import * as ReduxUniversalCookie from 'redux-effects-universal-cookie'
import * as R from 'ramda'

import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'

import * as Types from './types'

/**
 * Update session stats
 */
export function updateSessionStats(sessionStats) {
  return {
    type: Types.SESSION_STATS_UPDATED,
    payload: sessionStats,
  }
}

/**
 * Clear session stats
 */
export function clearSessionStats() {
  return {
    type: Types.SESSION_STATS_CLEARED,
  }
}

/**
 * Clear player data
 */
export function clear() {
  return {
    type: Types.CLEARED,
  }
}

/**
 * Update player info
 */
export function updatePlayerInfo(payload) {
  return [
    {
      type: Types.INFORMATION_UPDATED,
      error: payload instanceof Error,
      payload,
    },
    ReduxUniversalCookie.cookie(
      'deposit_count',
      R.path(['depositNumber'], payload),
      {
        httpOnly: false,
        // TODO: Replace new Date with timestamp from redux-effects-timestamp
        expires: DateFns.addDays(new Date(), 180),
        path: '/',
      }
    ),
  ]
}

/**
 * Update player's deposit count; cookie value must be updated explicitly when
 * updating deposit count with this action.
 *
 * Cookie can’t be updated from here as redux-effects-universal-cookie calls
 * update loop on server.
 *
 * @param {number} depositCount
 * @returns {Object} Redux action
 */
export function updateDepositCount(depositCount) {
  return {
    type: Types.DEPOSIT_COUNT_UPDATED,
    payload: depositCount,
  }
}

export function updateDepositInformation(payload) {
  return {
    type: Types.DEPOSIT_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * Fetch player’s deposit limits information
 * @returns {Object} Redux Effect’s fetch action
 */
export function fetchDepositInformation() {
  return [
    Processes.start('FETCH_DEPOSIT_INFORMATION'),
    Api.fetchDepositInformation({
      success: res => [
        Processes.stop('FETCH_DEPOSIT_INFORMATION'),
        updateDepositInformation(res.value),
      ],
      failure: res => {
        const error =
          res instanceof Error
            ? new Error('errors.fetch-failed')
            : new Error(`errors.${res.message || 'general.unknown'}`)
        return [
          Processes.stop('FETCH_DEPOSIT_INFORMATION'),
          updateDepositInformation(error),
        ]
      },
      version: 1,
    }),
  ]
}

export function fetchSessionStats() {
  return Api.fetchSessionStats({
    success: ({ value }) => {
      return updateSessionStats(value)
    },
    version: 2,
  })
}

export function updateWithdrawInformation(payload) {
  return {
    type: Types.WITHDRAW_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * Fetch player’s withdrawal limits and wagering information
 * @returns {Object} Redux Effect’s fetch action
 */
export function fetchWithdrawInformation() {
  return [
    Processes.start('FETCH_WITHDRAW_INFORMATION'),
    Api.fetchWithdrawInformation({
      success: res => [
        Processes.stop('FETCH_WITHDRAW_INFORMATION'),
        updateWithdrawInformation(res.value),
      ],
      failure: res => {
        const error =
          res instanceof Error
            ? new Error('errors.fetch-failed')
            : new Error(res.value)
        return [
          Processes.stop('FETCH_WITHDRAW_INFORMATION'),
          updateWithdrawInformation(error),
        ]
      },
      version: 1,
    }),
  ]
}

export function updateCookieConsent(payload) {
  return {
    type: Types.COOKIE_CONSENT_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function fetch(config = {}) {
  return Api.fetchPlayerInfo({
    success: res => [
      updatePlayerInfo(res.value.result),
      config.success && config.success(res),
    ],
    failure: () => [
      config.silent ? null : updatePlayerInfo(new Error('errors.fetch-failed')),
      config.failure && config.failure(),
    ],
    version: 2,
  })
}
