import * as R from 'ramda'

// Notifications section requires inventory items and
// promotions in one mixed list sorted by createdAt for
// inventory and activeFrom for promotions
export function getInventoryItems(state) {
  return R.map(
    item =>
      R.merge(
        {
          notificationType: 'inventory',
          orderDate: R.path(['createdAt'], item),
        },
        item
      ),
    state
  )
}

export function getInventoryItem(state, props) {
  const items = getInventoryItems(state)
  return R.find(R.propEq('type', props.type), R.values(items))
}

export function hasUnseenInventory(state) {
  return R.findIndex(R.pathEq(['seen'], false), R.values(state)) >= 0
}
