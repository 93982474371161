import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as FormComponents from '../../form-components'
import { Button } from '../../components/common/button'
import { H2 } from '../../components/common/headings'
import { SelectField } from '../../form-components/select-field'

import MultipleSelect from './multiple-select'
import { dataSchema } from './data-schema'
import { uiSchema } from './ui-schema'

export function SourceOfFunds(props) {
  const i18n = I18n.useI18n()
  if (props.rejected) {
    return (
      <Common.Box textAlign="center">
        <H2 color="orange">{i18n.translate('source-of-funds.title')}</H2>
        <Common.Space py="0">
          <Common.Text align="center" weight="bold">
            {i18n.translate('source-of-funds.rejected')}
          </Common.Text>
        </Common.Space>
      </Common.Box>
    )
  }

  return (
    <Common.Box padding={[2, 3]} textAlign="center">
      <Common.Space padding={0}>
        <Common.Box color="g-text" fontWeight="bold">
          {i18n.translate('source-of-funds.title')}
        </Common.Box>
      </Common.Space>
      {!props.dismissable && (
        <Common.Space padding={0}>
          <Common.Text fontSize={0} color="g-text" weight="bold">
            {i18n.translate('source-of-funds.last-time-alert')}
          </Common.Text>
        </Common.Space>
      )}
      <Forms.Provider
        schema={dataSchema()}
        name="source-of-funds"
        onSubmit={(errors, data) =>
          R.isEmpty(errors) && handleSubmit(data, props.onSubmit)
        }
      >
        <FormComponents.FieldBuilder
          {...props}
          getCustomField={scope => {
            if (scope === '#/properties/fundsCountryOrigin') {
              return MultipleSelect
            }
            if (
              scope === '#/properties/placeOfBirth' ||
              scope === '#/properties/nationality'
            ) {
              return SelectCountryField
            }
          }}
          name="source-of-funds"
          uiSchema={uiSchema}
        />
        <Common.Space pt={1}>
          <FormComponents.SubmitButton>
            {i18n.translate('source-of-funds.submit')}
          </FormComponents.SubmitButton>
        </Common.Space>
      </Forms.Provider>
      <Common.Space pb={4} px={1}>
        <Common.Flex flexDirection="column" alignItems="center">
          {props.dismissable ? (
            <Common.Space pb={1} pt={2}>
              <Button onClick={props.onDismiss}>
                {i18n.translate('source-of-funds.dismiss')}
              </Button>
            </Common.Space>
          ) : (
            <Common.Box
              py="2"
              textAlign="center"
              color="info"
              fontSize={1}
              fontWeight="bold"
            >
              {i18n.translate('source-of-funds.last-time-alert')}
            </Common.Box>
          )}
          <Common.Link to="/logout">
            {i18n.translate('source-of-funds.log-out')}
          </Common.Link>
        </Common.Flex>
      </Common.Space>
    </Common.Box>
  )
}

function handleSubmit(fields, onSubmit) {
  onSubmit({
    employment: fields.employment,
    occupation: fields.occupation,
    salary: getSalary(fields),
    sourcesOfIncome: getSources(fields),
    nationality: fields.nationality,
    placeOfBirth: fields.placeOfBirth,
    idNumber: fields.idCardNumber,
    anticipatedYearlyDeposits: getDeposits(fields),
    sourcesOfFunds: getSourcesOfDeposits(fields),
    fundsCountryOrigin: fields.fundsCountryOrigin,
  })
}

function getDeposits(fields) {
  switch (fields.depositsLevel) {
    case '5000': {
      return '<=5000'
    }
    case '30001': {
      return '>30001'
    }
    default: {
      return fields.depositsLevel
    }
  }
}

function SelectCountryField(props) {
  return <SelectField {...props} contentTranslated />
}

function getSalary(fields) {
  if (fields.salary === '80001') {
    return '>80001'
  }
  return fields.salary
}

function getSources(fields) {
  return fields.otherSourcesField
    ? R.append(
        fields.otherSourcesField,
        R.remove(
          R.findIndex(i => i == 'Other')(fields.otherSources),
          1,
          fields.otherSources
        )
      )
    : fields.otherSources
}

function getSourcesOfDeposits(fields) {
  return fields.sourceOfDepositsOtherField
    ? R.append(
        fields.sourceOfDepositsOtherField,
        R.remove(
          R.findIndex(i => i == 'Other')(fields.sourceOfDeposits),
          1,
          fields.sourceOfDeposits
        )
      )
    : fields.sourceOfDeposits
}

SourceOfFunds.propTypes = {
  dismissable: PropTypes.bool,
  rejected: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
