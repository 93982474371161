import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import QueryString from 'querystring'
import React from 'react'

function parseSearchParams(searchString) {
  return QueryString.parse(R.drop(1, R.defaultTo('', searchString)))
}

function mergeSearchParams(left, right) {
  const queryString = QueryString.stringify({
    ...parseSearchParams(left),
    ...parseSearchParams(right),
  })

  if (queryString.length === 0) {
    return ''
  }

  return `?${queryString}`
}

export function Redirect(props) {
  const location = ReactRouter.useLocation()
  const { preserveQuery, ...redirectProps } = props

  if (preserveQuery) {
    const to = typeof props.to === 'string' ? { pathname: props.to } : props.to

    return (
      <ReactRouter.Redirect
        {...redirectProps}
        from={props.from}
        to={{
          ...to,
          search: mergeSearchParams(location.search, to.search),
        }}
      />
    )
  }

  return <ReactRouter.Redirect {...redirectProps} />
}

Redirect.propTypes = {
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  preserveQuery: PropTypes.bool,
}
