import * as types from './types'

/**
 * @param {Object} notification Notification message data object
 * @param {string} notification.message Translation key for notification message
 * @param {Object} notification.variables Translation variables object
 * @param {("success"|"error"|"info")} level
 */
export function add(notification, level) {
  return {
    type: types.ADDED,
    payload: {
      notification,
      level,
    },
  }
}

/**
 * Function for removing a specific notification
 * @param {number} index
 */
export function dismiss(index) {
  return {
    type: types.DISMISSED,
    payload: index,
  }
}

/**
 * Function for clearing all notification
 */
export function dismissAll() {
  return {
    type: types.DISMISSED_ALL,
  }
}
