import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Link, Text } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

const Wrapper = styled.li`
  padding-bottom: ${t.spacing(2)};
  margin-top: 4px;
  margin-bottom: 4px;

  @media only screen and (min-width: 600px) {
    padding-bottom: ${t.spacing(0)};
  }
`

export function InfoLink(props) {
  const i18n = new useI18n()

  return (
    <Wrapper>
      <Link to={props.link} target={props.target}>
        <Text color="gray">{i18n.translate(props.translationKey)}</Text>
      </Link>
    </Wrapper>
  )
}

InfoLink.propTypes = {
  translationKey: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
}
