import React from 'react'

import { Flex, Space, Text } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { H1 } from './common/headings'
import { PrimaryButtonLink } from './common/button-link'

export const NotAuthorized = withTranslate(props => {
  if (props.staticContext) {
    props.staticContext.status = 401
  }

  return (
    <Flex wide column alignItems="center" justifyContent="center">
      <H1 color="black">{props.translate('errors.not-authorized.title')}</H1>
      <Space paddingTop={2} paddingBottom={4}>
        <Text size={1}>{props.translate('errors.not-authorized.content')}</Text>
      </Space>

      <PrimaryButtonLink to="/">
        {props.translate('errors.not-authorized.link')}
      </PrimaryButtonLink>
    </Flex>
  )
})
