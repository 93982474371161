import * as R from 'ramda'

import * as Constants from '../constants'

const VISIBILITY_TOGGLED = 'simplecasino/VISIBILITY_TOGGLED'

export function getVisibility(state, props) {
  return R.pathOr(false, [props.id], state)
}

export function toggleVisibility(id, value) {
  return {
    type: VISIBILITY_TOGGLED,
    payload: { id, value },
  }
}

export function getTransactionType(state) {
  return R.head(
    R.values(
      R.pickBy(id => getVisibility(state, { id: id }) === true, [
        Constants.TransactionType.DEPOSIT,
        Constants.TransactionType.WITHDRAWAL,
      ])
    )
  )
}

export function reducer(state = {}, action) {
  switch (action.type) {
    case VISIBILITY_TOGGLED: {
      return R.assocPath(
        [action.payload.id],
        R.defaultTo(!state[action.payload.id], action.payload.value),
        state
      )
    }

    default: {
      return state
    }
  }
}
