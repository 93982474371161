import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import { NotFound } from '../components/not-found'
import { Redirect } from '../router/redirect'

import { AcceptBonusFunds } from './accept-bonus-funds'
import { EmailVerification } from './email-verification'
import { FreeText } from './free-text/'
import { LockCard } from './lock-card'
import { PhoneVerification } from './phone-verification'
import { RGDepositThresholdPrompt } from './rg-deposit-threshold-prompt'
import { SourceOfFunds } from './source-of-funds'
import { SourceOfWealth } from './source-of-wealth'
import { TermsAndConditionsConsent } from './terms-and-conditions-consent'

const LOCK_PAGES = {
  'accept-bonus-funds': AcceptBonusFunds,
  'email-verification': EmailVerification,
  'phone-verification': PhoneVerification,
  'responsible-gaming-deposit-threshold-prompt': RGDepositThresholdPrompt,
  'source-of-funds-verification': SourceOfFunds,
  'source-of-wealth-verification': SourceOfWealth,
  'terms-and-conditions-consent': TermsAndConditionsConsent,
  'unread-messages': FreeText,
}

export function LockPageContainer(props) {
  const urlLock = R.path(['match', 'params', 'lock'], props)
  const Component = LOCK_PAGES[urlLock]

  if (!Component) {
    return <NotFound />
  }

  if (R.not(R.includes(urlLock, props.locks))) {
    return <Redirect to="/" preserveQuery />
  }

  // email-verification is an exception
  // because it should feel like an extension of the registration flow
  if (urlLock === 'email-verification') {
    return <Component {...props} />
  }

  return (
    <LockCard>
      <Component {...props} />
    </LockCard>
  )
}

LockPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
  locks: PropTypes.array.isRequired,
}
