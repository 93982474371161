import * as R from 'ramda'

import * as websockets from '@rushplay/websockets'
import * as realityCheck from '@rushplay/compliance/reality-check'

import * as types from './types'
import * as actions from './actions'

export * from './actions'
export * from './selectors'
export * from './types'

export function reducer(state = [], action) {
  if (action.error) {
    if (action.payload.message) {
      return R.append(
        {
          notification: {
            message: action.payload.message,
            variables: action.payload.variables,
          },
          level: 'error',
          id: state.length,
        },
        state
      )
    } else {
      return state
    }
  }
  switch (action.type) {
    // TODO: Remove when notifications are a separate module (will use a notifications action creator (and type) in compliance)
    case realityCheck.SET_SUCCESSFULLY: {
      return R.append(
        {
          id: state.length,
          notification: { message: 'reality-check.success' },
          level: 'success',
        },
        state
      )
    }
    case types.ADDED: {
      if (
        action.payload.notification.message &&
        R.includes(action.payload.level, ['success', 'error', 'info'])
      ) {
        const nextState = R.reduce(
          (nextState, notification) => {
            return R.path(['message'], notification) === action.payload.message
              ? reducer(state, actions.dismiss(R.path(['id'], notification)))
              : state
          },
          state,
          state
        )

        return R.append(
          R.mergeDeepRight(action.payload, { id: state.length }),
          nextState
        )
      } else {
        return state
      }
    }
    case types.DISMISSED: {
      return R.update(action.payload, null, state)
    }
    case websockets.SESSION_EXPIRED:
    case types.DISMISSED_ALL: {
      return []
    }
    default: {
      return state
    }
  }
}
