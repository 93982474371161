import * as R from 'ramda'
import { getTime } from 'date-fns'

export function getDepositCampaigns(state) {
  return R.reject(
    promotion =>
      R.isNil(promotion.campaign) ||
      !R.pathEq(['campaign', 'rewardTrigger'], 'deposit', promotion) ||
      Date.now() < getTime(promotion.activeFrom) ||
      R.pathEq(['campaign', 'claimed'], true, promotion),
    state
  )
}

// Notifications section requires inventory items and
// promotions in one mixed list sorted by createdAt for
// inventory and activeFrom for promotions, ergo adding
// orderDate prop
export function getActivePromotions(state) {
  return R.pipe(
    R.reject(
      promotion =>
        Date.now() <= getTime(promotion.activeFrom) || promotion.lobbyBanner
    ),
    R.map(item =>
      R.merge(
        {
          notificationType: 'promotion',
          orderDate: R.path(['activeFrom'], item),
        },
        item
      )
    )
  )(state)
}

export function getFuturePromotions(state) {
  return R.filter(
    promotion => Date.now() < getTime(R.path(['activeFrom'], promotion)),
    state
  )
}

export function getLobbyBanners(state) {
  return R.pipe(
    R.filter(
      promotion =>
        Date.now() > getTime(R.path(['activeFrom'], promotion)) &&
        promotion.lobbyBanner
    ),
    R.values
  )(state)
}

export function hasUnseenPromotions(state) {
  return R.findIndex(R.pathEq(['seen'], false), R.values(state)) >= 0
}

export function isDepositCampaignPromotion(promotion) {
  return R.pathEq(['campaign', 'rewardTrigger'], 'deposit', promotion)
}
