import * as R from 'ramda'

import { SESSION_EXPIRED } from '@rushplay/websockets'

import * as types from './types'

export * from './actions'
export * from './types'
export * from './selectors'

export function reducer(state = {}, action) {
  switch (action.type) {
    case types.SESSION_STATS_UPDATED:
      return R.assoc('sessionStats', action.payload, state)

    case types.SESSION_STATS_CLEARED:
      return R.dissoc('sessionStats', state)

    case types.DEPOSIT_COUNT_UPDATED:
      return R.assocPath(
        ['information', 'depositNumber'],
        action.payload,
        state
      )

    case types.INFORMATION_UPDATED:
      return R.assoc('information', action.payload, state)

    case types.DEPOSIT_INFORMATION_UPDATED:
      return R.assoc('depositInformation', action.payload, state)

    case types.WITHDRAW_INFORMATION_UPDATED:
      return R.assoc('withdrawInformation', action.payload, state)

    case types.COOKIE_CONSENT_UPDATED:
      return R.assoc('cookieConsent', action.payload, state)

    case SESSION_EXPIRED:
    case types.CLEARED:
      return {}

    default: {
      return state
    }
  }
}
