import { connect } from 'react-redux'

import * as combinedSelectors from '../../../combined-selectors'

import { Amount as Component } from './amount'

function mapStateToProps(state, props) {
  return {
    currency: props.currency || combinedSelectors.getCurrency(state),
    locale: combinedSelectors.getLocale(state),
  }
}

export const Amount = connect(mapStateToProps)(Component)
