import * as React from 'react'
import PropTypes from 'prop-types'

import * as T from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

const MinifiedLogo = styled('div', {
  shouldForwardProp: Common.noneOf(['src', 'width']),
})`
  height: 40px;
  width: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
  background-image: url('/images/simplecasino/logo-min.svg');
  background-position: center;

  ${T.mediaQuery.sm`
    width: ${props => `${props.width}px`};
    background-image: ${props => `url(${props.src})`};
    background-size: ${props => `${props.width}px`};
  `};
`

export function Logotype(props) {
  const dark = props.dark ? '-dark' : ''
  const src = `/images/simplecasino/logo-${props.variant}${dark}.svg`

  if (props.minifyLogo && props.authenticated) {
    return <MinifiedLogo width={props.width} title="simplecasino" src={src} />
  }

  return (
    <Common.Image
      alt="simplecasino"
      src={src}
      width={props.width}
      height={props.height}
      size="fixed"
    />
  )
}

Logotype.defaultProps = {
  width: 'auto',
  variant: 'normal',
}

Logotype.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dark: PropTypes.bool,
  height: PropTypes.string,
  minifyLogo: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['normal', 'alt', 'dark']),
  width: PropTypes.string,
}
