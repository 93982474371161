import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { createStructuredSelector } from 'reselect'

import * as i18n from '@rushplay/i18n'

import * as combinedSelectors from '../combined-selectors'

import { createKeyResolver } from './create-key-resolver'

function stateResolver(state) {
  return state.i18n
}

const variableResolver = createStructuredSelector({
  brandEmail: () => 'support@simplecasino.com',
  brandName: () => 'Simple Casino',
  currencyCode: combinedSelectors.getCurrency,
  locale: combinedSelectors.getLocale,
})

function TranslationProviderBase(props) {
  const keyResolver = useMemo(
    () => createKeyResolver('simplecasino', props.language, props.license),
    [props.language, props.license]
  )
  return (
    <i18n.TranslationProvider
      keyResolver={keyResolver}
      stateResolver={stateResolver}
      variableResolver={variableResolver}
    >
      {props.children}
    </i18n.TranslationProvider>
  )
}

TranslationProviderBase.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string,
  license: PropTypes.string,
}

const connector = R.compose(
  combinedSelectors.withLanguage,
  combinedSelectors.withLicense
)

export const TranslationProvider = connector(TranslationProviderBase)
