import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as T from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Icons from '@rushplay/icons'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { IconCircle } from './components/common/icon-circle'
import { ScrollLock } from './utils/scroll-lock'
import { useSafeUpdateQuery } from './use-safe-update-query'

const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
`

const Wrapper = styled(motion.div)`
  ${css({
    width: ['100%', null, '350px'],
    backgroundColor: 'drawerBackground',
  })};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 6;
  flex-shrink: 0;
  line-height: 1.25;
`

const BackWrapper = styled.div`
  ${css({
    fontSize: 6,
  })};

  cursor: pointer;
`

const ScrollableWrapper = styled.div`
  ${css({
    px: 1,
  })};
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${T.color('simpleorange')};
      }
    }
  }
`

export function QueryDrawer(props) {
  const location = ReactRouterDom.useLocation()
  const closeQuery = useSafeUpdateQuery(
    R.mergeDeepRight({ [props.activeQueryName]: null }, props.additionalQueries)
  )
  const query = new URLSearchParams(location.search)
  const active = query.has(props.activeQueryName)

  return (
    <AnimatePresence>
      {active && (
        <ReactRouterDom.Link to={`?${closeQuery}`} key="overlay">
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
        </ReactRouterDom.Link>
      )}

      {active && (
        <Wrapper
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
          transition={{
            x: { type: 'spring', stiffness: 200, damping: 25 },
            default: { duration: 0.3 },
          }}
          key={props.activeQueryName}
        >
          <ScrollLock />
          <Common.Box
            p="12px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexShrink="0"
            backgroundColor="white"
          >
            {props.onSecondaryAction ? (
              <BackWrapper onClick={props.onSecondaryAction}>
                <Icons.IconWrapper size="20px">
                  <Icons.IconChevronLeft />
                </Icons.IconWrapper>
              </BackWrapper>
            ) : (
              <Common.Box width="20px" />
            )}
            <Common.Box
              fontSize={2}
              fontWeight="bold"
              display="inline-flex"
              overflow="hidden"
              fontFamily="head"
              color="black"
            >
              {props.title}
            </Common.Box>
            <ReactRouterDom.Link to={`?${closeQuery}`}>
              <IconCircle
                hoverColor="primary"
                style={{ boxShadow: '0px 2px 4px rgba(97, 105, 107, 0.25)' }}
              >
                <Icons.IconWrapper size="16px">
                  <Icons.IconClose />
                </Icons.IconWrapper>
              </IconCircle>
            </ReactRouterDom.Link>
          </Common.Box>
          <ScrollableWrapper>{props.children}</ScrollableWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

QueryDrawer.defaultProps = {
  additionalQueries: {},
}

QueryDrawer.propTypes = {
  activeQueryName: PropTypes.string.isRequired,
  additionalQueries: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  onSecondaryAction: PropTypes.func,
}
