import * as R from 'ramda'

export function createKeyResolver(brand, language, license) {
  return function keyResolver(keys, key) {
    if (typeof key === 'string') {
      const overrides = [
        `${language}:speedy.${brand}.${license}.${key}`,
        `${language}:speedy.${license}.${key}`,
        `${language}:speedy.${brand}.${key}`,
      ]
      return R.reduce(
        (resolvedKey, override) =>
          R.includes(override, keys) ? R.reduced(override) : resolvedKey,
        `${language}:speedy.${key}`,
        overrides
      )
    }
  }
}
