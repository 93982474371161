import * as ReactRedux from 'react-redux'
import DateFns from 'date-fns'
import React from 'react'
import { motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'
import { Box, Flex, Space, Text, toLocaleDate } from '@rushplay/common'
import {
  useTotals,
  useTransactions,
} from '@rushplay/compliance/gambling-history'

import * as FormComponents from '../../form-components'
import * as CombinedSelectors from '../../combined-selectors'
import { Amount } from '../../components/common/amount'
import { Card } from '../../components/common/card'
import { H1, H3, H4 } from '../../components/common/headings'
import { PrimaryButton } from '../../components/common/button'

import { dateRangeFormSchema, dateRangeFormUiSchema } from './schema'

const MOUNT_POINTS = {
  '@rushplay/compliance/gambling-history': ['gamblingHistory'],
  '@rushplay/processes': ['processes'],
}

const ItemWrapper = styled(Flex)`
  background-color: ${t.color('gray1', 0.3)};
  border-radius: 5px;
  min-height: 98px;
`

const StakeWrapper = styled(Flex)`
  background-color: ${t.color('white')};
  width: 25%;
  margin-left: auto;
`

const ReturnWrapper = styled(Flex)`
  width: 25%;
`

const schema = dateRangeFormSchema()
// we should take the beginning of the day, but it does not hurt to use .now()
const now = new Date(Date.now())
const monthAgo = DateFns.startOfDay(DateFns.subDays(now, 30))
const endOfToday = DateFns.endOfDay(now)

export function GamblingHistory() {
  const i18n = I18n.useI18n()

  const [showTotals, setShowTotals] = React.useState(false)
  const [historyStartDate, setHistoryStartDate] = React.useState(
    DateFns.getTime(monthAgo)
  )
  const [historyEndDate, setHistoryEndDate] = React.useState(
    DateFns.getTime(endOfToday)
  )
  const [error, setError] = React.useState('')

  const useTransactionProps = React.useMemo(
    () => ({
      mountPoints: MOUNT_POINTS,
      startDate: historyStartDate,
      endDate: historyEndDate,
    }),
    [MOUNT_POINTS, historyStartDate, historyEndDate]
  )

  const transactions = useTransactions({ ...useTransactionProps })

  const totals = useTotals({
    mountPoints: MOUNT_POINTS,
  })

  React.useEffect(() => {
    transactions.onFetch()
    return () => transactions.onClear()
  }, [transactions.onClear, transactions.onFetch])

  function handleShowTotals() {
    setShowTotals(prevState => !prevState)
    if (totals.stale) {
      totals.onFetch()
    }
  }

  return (
    <Box maxWidth="600px" margin="auto">
      <Space p={1}>
        <H1 align="center">{i18n.translate('gambling-history.title')}</H1>
        <Text textAlign="center">
          {i18n.translate('gambling-history.description')}
        </Text>
      </Space>
      <Card>
        <Flex flexDirection="column">
          <Space py={1}>
            <Space py={1}>
              <Flex justifyContent="center">
                <Space px={1}>
                  <PrimaryButton
                    disabled={totals.loading}
                    onClick={handleShowTotals}
                  >
                    {showTotals
                      ? i18n.translate('gambling-history.hide-totals')
                      : i18n.translate('gambling-history.show-totals')}
                  </PrimaryButton>
                </Space>
              </Flex>
            </Space>

            <Forms.Provider
              name="history-date-range"
              schema={schema}
              onSubmit={(formErrors, data) => {
                setError('')
                if (
                  Object.keys(formErrors)?.length === 0 &&
                  formErrors.constructor === Object
                ) {
                  const startDate = DateFns.getTime(
                    DateFns.startOfDay(
                      new Date(
                        data.startYear,
                        // we need to do -1 to start counting months from 0
                        data.startMonth - 1,
                        data.startDay
                      )
                    )
                  )
                  const endDate = DateFns.getTime(
                    DateFns.endOfDay(
                      new Date(
                        data.endYear,
                        // we need to do -1 to start counting months from 0
                        data.endMonth - 1,
                        data.endDay
                      )
                    )
                  )
                  // TODO: do not validate post submit
                  if (DateFns.differenceInDays(endDate, startDate) > 30) {
                    setError('max-range-of-dates')
                  } else if (DateFns.differenceInMonths(now, startDate) > 6) {
                    setError('max-start-date')
                  } else {
                    setHistoryStartDate(startDate)
                    setHistoryEndDate(endDate)
                  }
                }
              }}
            >
              <FormComponents.FieldBuilder
                uiSchema={dateRangeFormUiSchema}
                name="history-date-range-fields"
                initialValues={{
                  '#/properties/startDay': DateFns.format(monthAgo, 'D'),
                  '#/properties/startMonth': DateFns.format(monthAgo, 'M'),
                  '#/properties/startYear': DateFns.format(monthAgo, 'YYYY'),
                  '#/properties/endDay': DateFns.format(endOfToday, 'D'),
                  '#/properties/endMonth': DateFns.format(endOfToday, 'M'),
                  '#/properties/endYear': DateFns.format(endOfToday, 'YYYY'),
                }}
              />
              <Space pt={0} pb={1}>
                <FormComponents.SubmitButton disabled={transactions.loading}>
                  {i18n.translate('gambling-history.trigger-date-filter')}
                </FormComponents.SubmitButton>
              </Space>
            </Forms.Provider>
          </Space>

          {!totals.stale && showTotals && (
            <Space paddingBottom={1}>
              <ItemWrapper justifyContent="space-between">
                <Space px={1} py={1}>
                  <H3 color="primary">
                    {i18n.translate('gambling-history.total')}
                  </H3>
                </Space>
                <StakeWrapper justifyContent="center">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <H4>{i18n.translate('gambling-history.stake')}</H4>
                    <Space paddingTop={0}>
                      <Amount currency={totals.data.currency} decimals="show">
                        {totals.data.betAmountCents}
                      </Amount>
                    </Space>
                  </Flex>
                </StakeWrapper>
                <ReturnWrapper justifyContent="center">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <H4>{i18n.translate('gambling-history.return')}</H4>
                    <Space paddingTop={0}>
                      <Amount currency={totals.data.currency} decimals="show">
                        {totals.data.winAmountCents}
                      </Amount>
                    </Space>
                  </Flex>
                </ReturnWrapper>
              </ItemWrapper>
            </Space>
          )}
          {error && (
            <Space pt={0} pb={1}>
              <Text color="inputInvalid" fontSize={0}>
                {i18n.translate(`errors.gambling-history.${error}`)}
              </Text>
            </Space>
          )}
          {!error && transactions.data && (
            <TransactionsList
              transactions={transactions.data}
              empty={transactions.empty && !transactions.stale}
              loading={transactions.loading && transactions.data.length === 0}
            />
          )}
          {transactions.onFetchMore && (
            <Flex justifyContent="center">
              <Space paddingTop={2}>
                <PrimaryButton
                  disabled={transactions.loading || error}
                  onClick={transactions.onFetchMore}
                >
                  {i18n.translate('gambling-history.load-more')}
                </PrimaryButton>
              </Space>
            </Flex>
          )}
        </Flex>
      </Card>
    </Box>
  )
}

function TransactionsList(props) {
  const i18n = I18n.useI18n()
  const language = ReactRedux.useSelector(state =>
    CombinedSelectors.getLanguage(state)
  )

  if (props.loading) {
    return <TransactionsSkeleton />
  }

  if (props.empty) {
    return (
      <Text textAlign="center">
        {i18n.translate('gambling-history.not-found')}
      </Text>
    )
  }

  return props.transactions.map(item => (
    <Space paddingTop={0} key={item.createdAt}>
      <ItemWrapper justifyContent="space-between">
        <Space px={1} py={1}>
          <Flex flexDirection="column">
            {toLocaleDate(item.createdAt, language).dateTime}
            <Space py={0}>
              <H3 color="primary">{item.gameTitle}</H3>
            </Space>
            {'unknown' !== item.type &&
              i18n.translate(`gambling-history.${item.type}.bet-type`)}
          </Flex>
        </Space>
        <StakeWrapper justifyContent="center">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <H4>{i18n.translate('gambling-history.stake')}</H4>
            <Space paddingTop={0}>
              <Amount currency={item.currency} decimals="show">
                {item.values.bet}
              </Amount>
            </Space>
          </Flex>
        </StakeWrapper>
        <ReturnWrapper justifyContent="center">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <H4>{i18n.translate('gambling-history.return')}</H4>
            <Space paddingTop={0}>
              <Amount currency={item.currency} decimals="show">
                {item.values.win}
              </Amount>
            </Space>
          </Flex>
        </ReturnWrapper>
      </ItemWrapper>
    </Space>
  ))
}

function TransactionsSkeleton() {
  return (
    <Box maxWidth="600px" margin="auto">
      <Card>
        {Array.from(Array(10).keys()).map(item => (
          <motion.div
            key={item}
            initial={{
              scale: 0.8,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            transition={{
              type: 'spring',
              duration: 0.25,
              delay: 0.1 * item,
              opacity: {
                yoyo: Infinity,
                duration: 0.5,
              },
            }}
          >
            <Space pb={0}>
              <ItemWrapper>
                <StakeWrapper />
                <ReturnWrapper />
              </ItemWrapper>
            </Space>
          </motion.div>
        ))}
      </Card>
    </Box>
  )
}

export default GamblingHistory
