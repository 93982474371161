import PropTypes from 'prop-types'
import React from 'react'

import { Flex, Link, Space, Text } from '@rushplay/common'

import { H2 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function RGDepositThresholdPrompt(props) {
  return (
    <React.Fragment>
      <H2 align="center">
        {props.translate('responsible-gaming-deposit-threshold-prompt.title')}
      </H2>
      <Space paddingTop="1">
        <Text textAlign="center" lineHeight="1.5">
          <HtmlContent
            html={{
              __html: props.translate(
                `${props.license}.responsible-gaming-deposit-threshold-prompt.content`
              ),
            }}
          />
        </Text>
      </Space>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Space py="1">
          <PrimaryButton onClick={props.onAccept}>
            {props.translate(
              'responsible-gaming-deposit-threshold-prompt.button'
            )}
          </PrimaryButton>
        </Space>
        <Text textAlign="center" color="gray" fontSize="0">
          <Link to="/money-limits" onClick={props.onAccept}>
            {props.translate(
              'responsible-gaming-deposit-threshold-prompt.limits'
            )}
          </Link>
        </Text>
      </Flex>
    </React.Fragment>
  )
}

RGDepositThresholdPrompt.propTypes = {
  license: PropTypes.string,
  translate: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
}
