import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import * as icons from '@rushplay/icons'
import styled from '@emotion/styled'
import { Flex, Space, noneOf } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { Amount } from '../components/common/amount'

const ReceiptAmount = styled(Flex, {
  shouldForwardProp: noneOf(['greenText']),
})`
  color: ${props => t.color(props.greenText ? 'green' : 'orange')};
`

const Wrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
`

const Trigger = styled('div', { shouldForwardProp: noneOf(['active']) })`
  padding: ${t.spacing(2)};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${props => t.color(props.active ? 'black' : 'white')};
  color: ${props => t.color(props.active ? 'white' : 'gray')};
  user-select: none;
  transition: background-color 150ms ease;
  font-size: ${t.fontSize(1)};
  line-height: 1;
  font-family: ${t.font.head};
  &:hover {
    ${props =>
      !props.active && `background-color: ${t.color('primary', 0.1)(props)}`};
    color: ${t.color('primary')};
  }
`

const Content = styled.div`
  background-color: ${t.color('gray', 0.05)};
  padding: ${t.spacing(2)};
  line-height: 1.25;
`

class Component extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

    this.date = new Date(this.props.date).toLocaleDateString()
  }

  render() {
    return (
      <Wrapper>
        <Trigger
          active={this.state.open}
          onClick={() => this.setState(state => ({ open: !state.open }))}
        >
          <Flex wide justifyContent="space-between" alignItems="center">
            <ReceiptAmount greenText={this.props.amount > 0}>
              {this.props.amount > 0 && <Space paddingRight="0">+</Space>}
              <Amount fixed currency={this.props.currency}>
                {this.props.amount}
              </Amount>
            </ReceiptAmount>
            <Space paddingLeft="0">{this.date}</Space>
          </Flex>
          <Space paddingLeft={1}>
            <icons.IconWrapper>
              {this.state.open ? (
                <icons.IconChevronUp />
              ) : (
                <icons.IconChevronDown />
              )}
            </icons.IconWrapper>
          </Space>
        </Trigger>
        {this.state.open && (
          <Content>
            {R.equals(this.props.provider, 'swish')
              ? this.props.translate('wallet.receipts.information.swish')
              : this.props.translate('wallet.receipts.information', {
                  provider: this.props.provider,
                  date: this.date,
                })}
          </Content>
        )}
      </Wrapper>
    )
  }
}

export const Receipt = withTranslate(Component)

Component.defaultProps = {
  provider: 'Hero Gaming',
}

Component.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  date: PropTypes.string,
  provider: PropTypes.string,
  translate: PropTypes.func.isRequired,
}
