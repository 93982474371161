import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { H2 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

export function TermsAndConditionsConsent(props) {
  const i18n = I18n.useI18n()

  return (
    <React.Fragment>
      <Common.Box as={H2} py={1} textAlign="center">
        {i18n.translate('locks.terms-and-conditions-consent.title')}
      </Common.Box>

      <Common.Box display="flex" justifyContent="center" py={1}>
        <PrimaryButton onClick={props.onAccept}>
          {i18n.translate('locks.terms-and-conditions-consent.accept')}
        </PrimaryButton>
      </Common.Box>

      <Common.Box py={1} textAlign="center">
        <HtmlContent
          html={{
            __html: i18n.translate(
              `locks.terms-and-conditions-consent.${props.license}.content`
            ),
          }}
        />
      </Common.Box>
    </React.Fragment>
  )
}

TermsAndConditionsConsent.propTypes = {
  license: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
}
