import PropTypes from 'prop-types'
import React from 'react'

import { Link } from '@rushplay/common'

import { Logotype } from '../components/common/logotype'

export function Logo(props) {
  return (
    <Link to="/">
      <Logotype width="140" height="44" minifyLogo={props.minifyLogo} />
    </Link>
  )
}

Logo.propTypes = {
  minifyLogo: PropTypes.bool,
}
