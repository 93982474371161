import * as R from 'ramda'
import * as React from 'react'
import { useSelector } from 'react-redux'

import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Session from '@rushplay/session'

import * as Ui from '../ui'

import { MenuItem } from './menu-item'

export function Settings() {
  const [expanded, setExpanded] = React.useState(false)

  const authenticated = useSelector(state =>
    Session.isSessionActive(state.session)
  )
  const settingsAvailability = useSelector(state =>
    Jurisdiction.getSettingsAvailability(state.jurisdiction)
  )
  const isMoneyLimitsConfigurable = useSelector(state =>
    R.not(R.isEmpty(Jurisdiction.getMoneyLimitTypes(state.jurisdiction)))
  )
  const isRealityCheckConfigurable = useSelector(state =>
    Jurisdiction.getRealityCheckConfigurability(state.jurisdiction)
  )
  const isSessionLimitsConfigurable = useSelector(state =>
    R.not(R.isEmpty(Jurisdiction.getSessionLimitTypes(state.jurisdiction)))
  )
  const isTimeOutConfigurable = useSelector(state =>
    R.not(R.isEmpty(Jurisdiction.getTimeOutValues(state.jurisdiction)))
  )
  const isSideBardOpen = useSelector(state =>
    Ui.getVisibility(state.ui, { id: 'sidebar' })
  )

  React.useEffect(() => {
    !isSideBardOpen && setExpanded(false)
  })

  if (!authenticated) {
    return null
  }

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => setExpanded(!expanded)}
        title="side-bar.settings"
        expanded={expanded}
        color="veryDarkBlue"
      />
      {expanded && (
        <Common.Box backgroundColor="white" mx={-3}>
          <Common.Space pl="3">
            <Common.Text fontSize="0">
              <MenuItem
                fontSize="1"
                to="/my-bets"
                title="side-bar.my-bets"
                color="darkgray"
              />
              <MenuItem
                fontSize="1"
                to="/receipts"
                title="side-bar.receipts"
                color="darkgray"
              />

              {isRealityCheckConfigurable && (
                <MenuItem
                  fontSize="1"
                  to="/reality-check"
                  title="side-bar.reality-check"
                  color="darkgray"
                />
              )}

              {isMoneyLimitsConfigurable && (
                <MenuItem
                  fontSize="1"
                  to="/money-limits"
                  title="side-bar.money-limits"
                  color="darkgray"
                />
              )}

              {isSessionLimitsConfigurable && (
                <MenuItem
                  fontSize="1"
                  to="/session-limits"
                  title="side-bar.login-time-limits"
                  color="darkgray"
                />
              )}

              <MenuItem
                fontSize="1"
                to="/gambling-history"
                title="side-bar.gambling-history"
                color="darkgray"
              />

              {isTimeOutConfigurable && (
                <MenuItem
                  fontSize="1"
                  to="/time-out"
                  title="side-bar.time-out"
                  color="darkgray"
                />
              )}

              {settingsAvailability.selfExclusion && (
                <MenuItem
                  fontSize="1"
                  to="/self-exclusion"
                  title="side-bar.self-exclusion"
                  color="darkgray"
                />
              )}

              <MenuItem
                fontSize="1"
                to="/my-profile"
                title="side-bar.my-profile"
                color="darkgray"
              />
            </Common.Text>
          </Common.Space>
        </Common.Box>
      )}
    </React.Fragment>
  )
}
