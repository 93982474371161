import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import * as combinedSelectors from '../combined-selectors'

import { LockPageContainer as Component } from './lock-page-container'

function mapStateToProps(state) {
  return {
    locks: combinedSelectors.getLocks(state),
    license: jurisdiction.getLicense(state.jurisdiction),
  }
}

const connector = connect(mapStateToProps)

export const LockPageContainer = connector(Component)
