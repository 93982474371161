export const SESSION_STATS_UPDATED = 'simplecasino/player/SESSION_STATS_UPDATED'
export const SESSION_STATS_CLEARED = 'simplecasino/player/SESSION_STATS_CLEARED'
export const INFORMATION_UPDATED = 'simplecasino/player/INFORMATION_UPDATED'
export const DEPOSIT_INFORMATION_UPDATED =
  'simplecasino/player/DEPOSIT_INFORMATION_UPDATED'
export const WITHDRAW_INFORMATION_UPDATED =
  'simplecasino/player/WITHDRAW_INFORMATION_UPDATED'
export const DEPOSIT_COUNT_UPDATED = 'simplecasino/player/DEPOSIT_COUNT_UPDATED'
export const COOKIE_CONSENT_UPDATED =
  'simplecasino/player/COOKIE_CONSENT_UPDATED'
export const INVENTORY_STORE_SEEN_UPDATED =
  'simplecasino/player/INVENTORY_STORE_SEEN_UPDATED'
export const CLEARED = 'simplecasino/player/CLEARED'
