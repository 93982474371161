import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import url from 'url'

import { Box, Link, Space, Text } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { Card } from '../../components/common/card'
import { H1 } from '../../components/common/headings'
import { HtmlContent } from '../../components/common/html-content'
import { PrimaryButton } from '../../components/common/button'

import { Timeout } from './timeout'

function Component(props) {
  const { query } = url.parse(props.location.search, true, true)

  return (
    <Timeout>
      {timeout => (
        <Box maxWidth="400px" margin="auto">
          <Space py={1}>
            <H1 align="center">
              {props.translate('time-out-period.headline')}
            </H1>
          </Space>
          <Card>
            <Text color="gray" fontSize={1} textAlign="center" lineHeight={1.5}>
              <HtmlContent
                html={{
                  __html: props.translate(`time-out-period.popup-content`, {
                    amount: props.translate(
                      R.pathOr(
                        query.period,
                        ['labelKey'],
                        R.find(R.propEq('value', query.period), timeout.options)
                      )
                    ),
                  }),
                }}
              />
            </Text>
            <Space paddingTop={1} paddingBottom={2}>
              <PrimaryButton
                stretched
                onClick={() => timeout.onSubmit(query.period)}
              >
                {props.translate('player-limits.confirm')}
              </PrimaryButton>
            </Space>
            <Text textAlign="center" color="gray">
              <Link to="/time-out">{props.translate('cancel')}</Link>
            </Text>
          </Card>
        </Box>
      )}
    </Timeout>
  )
}

export const TimeoutConfirmation = withTranslate(Component)

Component.propTypes = {
  translate: PropTypes.func.isRequired,
  location: PropTypes.object,
}
