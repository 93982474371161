import React from 'react'

import { Flex, Space, Text } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import { H1 } from './common/headings'
import { PrimaryButtonLink } from './common/button-link'

export const NotFound = withTranslate(props => {
  if (props.staticContext) {
    props.staticContext.status = 404
  }

  return (
    <Flex wide column alignItems="center" justifyContent="center">
      <H1 color="black">404</H1>
      <Space paddingTop={2} paddingBottom={4}>
        <Text size={1}>{props.translate('page-not-found.title')}</Text>
      </Space>

      <PrimaryButtonLink to="/">
        {props.translate('page-not-found.link')}
      </PrimaryButtonLink>
    </Flex>
  )
})
