import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Emotion from '@emotion/core'
import styled from '@emotion/styled'

import * as Icons from '../../components/icons.js'
import { AltButtonLink } from '../../components/common/button-link'
import { GameTile as CustomisableGameTile } from '../../casino/game-tile/game-tile.js'
import { GameQuery } from '../../casino/game-query'
import { GameTile } from '../../casino/game-tile'
import { Spinner } from '../../components/common/spinner'

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, minmax(170px, 1fr));
  grid-gap: 15px;

  ${props => {
    return Emotion.css`
        /* Writing media query by hand as we can’t run functions inside "css" */
        @media (min-width: ${props.theme.breakpoints.sm}) {
          grid-template-columns: repeat(auto-fill, minmax(170px, 1fr))
        }
      `
  }}
`

const mapIndexed = R.addIndex(R.map)

export function CustomisableRows(props) {
  const i18n = I18n.useI18n()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  return !props.isLoading ? (
    <>
      {!R.isEmpty(props.customisableRows) ? (
        R.map(
          category => (
            <div key={category.key}>
              <Common.Box
                py={1}
                color="darkblue"
                fontSize={[null, '24px', null, '28px']}
                fontWeight="600"
              >
                {i18n.translate(`landing-page.games.${category.key}.title`)}
              </Common.Box>
              <Common.ScrollableRow>
                <Grid>
                  {mapIndexed(
                    (game, index) => (
                      <Common.Box key={`fake-${index}`} width="100%">
                        <CustomisableGameTile
                          id={game.id}
                          blitzEnabled={game.blitzEnabled}
                          imageUrl={game.imageUrl}
                          displayProvider={game.displayProvider}
                          provider={game.provider}
                          tags={game.tags}
                          title={game.title}
                        />
                      </Common.Box>
                    ),
                    R.slice(0, 6, category.games)
                  )}
                </Grid>
              </Common.ScrollableRow>
            </div>
          ),
          R.values(props.customisableRows)
        )
      ) : (
        <div>
          <Common.Box
            py={1}
            display="flex"
            alignItems="center"
            color={props.titleColor || 'darkblue'}
            fontSize={props.titleFontSize || '4'}
            fontWeight="600"
          >
            {i18n.translate('landing-page.new-games')}
            {props.arrow && (
              <Common.Space pl="0">
                <Icons.ArrowEast size="20px" />
              </Common.Space>
            )}
          </Common.Box>
          <GameQuery
            name="new"
            params={{
              tags: ['new'],
              limit: 6,
            }}
          >
            {data => (
              <Common.ScrollableRow>
                <Grid>
                  {mapIndexed(
                    (id, index) => (
                      <Common.Box key={id || `fake-${index}`} width="100%">
                        <GameTile
                          id={id}
                          color={props.titleColor || 'darkblue'}
                        />
                      </Common.Box>
                    ),
                    data.ids
                  )}
                </Grid>
              </Common.ScrollableRow>
            )}
          </GameQuery>
        </div>
      )}
      {props.customisableRows && !props.hideButton && (
        <Common.Box py={1} display="flex" justifyContent="center">
          <AltButtonLink to="/casino">
            {i18n.translate('landing-page.explore')}
          </AltButtonLink>
        </Common.Box>
      )}
    </>
  ) : (
    <Spinner />
  )
}

CustomisableRows.defaultProps = {
  titleFontSize: '4',
}

CustomisableRows.propTypes = {
  arrow: PropTypes.bool,
  customisableRows: PropTypes.object,
  hideButton: PropTypes.bool,
  titleColor: PropTypes.string,
  titleFontSize: PropTypes.string,
  isLoading: PropTypes.bool,
  onFetch: PropTypes.func,
}
