import { connect } from 'react-redux'

import * as Constants from '../../constants'
import * as ui from '../../ui'

import { GlobalStyles as Component } from './global-styles'

function mapStateToProps(state) {
  const depositVisible = ui.getVisibility(state.ui, {
    id: Constants.TransactionType.DEPOSIT,
  })
  const withdrawalVisible = ui.getVisibility(state.ui, {
    id: Constants.TransactionType.WITHDRAWAL,
  })
  const sidebarVisible = ui.getVisibility(state.ui, { id: 'sidebar' })

  return {
    blockScroll: depositVisible || withdrawalVisible || sidebarVisible,
  }
}

export const GlobalStyles = connect(mapStateToProps)(Component)
