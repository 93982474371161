import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Loadable from 'react-loadable'
import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { createBrowserHistory as createHistory } from 'history'
// eslint-disable-next-line emotion/no-vanilla
import { hydrate } from 'emotion'

import * as i18n from '@rushplay/i18n'

import * as configuration from './configuration'
import * as Utils from './utils'
import App from './app'
import createStore from './create-store'
import { getLanguage } from './combined-selectors'

const history = createHistory({ basename: window.__BASENAME__ })
history.listen(Utils.History.createPreviousLocationListener(history))
const store = createStore({ initialState: window.__APP_DATA__, history })

const state = store.getState()

// TODO: Ugly hack to avoid flash of untranslated text
const translationsFetch = store.dispatch(
  i18n.fetch(configuration.getCdnUrl(state.configuration), getLanguage(state))
)

const element = document.querySelector('[data-app-root]')

Promise.all([Loadable.preloadReady(), translationsFetch[1]]).then(() => {
  if (window.__EMOTION_IDS__) {
    hydrate(window.__EMOTION_IDS__)
  }

  if (window.__APP_DATA__) {
    ReactDOM.hydrate(
      <HelmetProvider>
        <Provider store={store}>
          <App history={history} router={ConnectedRouter} />
        </Provider>
      </HelmetProvider>,
      element
    )
  } else {
    ReactDOM.render(
      <HelmetProvider>
        <Provider store={store}>
          <App history={history} router={ConnectedRouter} />
        </Provider>
      </HelmetProvider>,
      element
    )
  }
})

if (module.hot) {
  module.hot.accept('./app/index.js', () => {
    const App = require('./app').default
    ReactDOM.hydrate(
      <HelmetProvider>
        <Provider store={store}>
          <App history={history} router={ConnectedRouter} />
        </Provider>
      </HelmetProvider>,
      element
    )
  })
}
