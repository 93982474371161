import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import * as configuration from '../../configuration'
import * as gameCatalog from '../../game-catalog'

import { CustomisableRows as Component } from './customisable-rows'

function mapStateToProps(state) {
  return {
    clientType: configuration.getClientType(state.configuration),
    isLoading: processes.isRunning(state.processes, {
      ids: ['FETCH_GAME_CATALOG_CATEGORY'],
    }),
    customisableRows: gameCatalog.getGameCatalog(state),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onFetch: () =>
      dispatch([
        processes.start('FETCH_GAME_CATALOG_CATEGORY'),
        api.fetchGameCatalog('featured-landing-game', {
          success: res => [
            api.fetchGameCatalogCategories(
              {
                categoryId: R.path(['categories', 0, 'id'], res.value),
                clientType: props.clientType,
              },
              {
                success: catRes => {
                  return [
                    processes.stop('FETCH_GAME_CATALOG_CATEGORY'),
                    gameCatalog.updateGameCatalogCategories({
                      categories: R.pick([0, 1, 2], catRes.value.sections),
                    }),
                  ]
                },
                failure: () => {
                  return [
                    processes.stop('FETCH_GAME_CATALOG_CATEGORY'),
                    gameCatalog.updateGameCatalogCategories({
                      categories: [],
                    }),
                  ]
                },
                version: 1,
              }
            ),
          ],
          failure: () => {
            return [
              processes.stop('FETCH_GAME_CATALOG_CATEGORY'),
              gameCatalog.updateGameCatalogCategories({
                categories: [],
              }),
            ]
          },
          version: 1,
        }),
      ]),
  }
}

const connector = R.compose(connect(mapStateToProps, mapDispatchToProps))

export const CustomisableRows = connector(Component)
