import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const MarkerIcon = styled.div`
  width: 80%;
  height: 80%;
  mask: url(/images/marker-icon.svg) no-repeat center;
  background-color: black;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const Marker = styled.span`
  ${props =>
    css({
      alignItem: 'center',
      border: '2px solid',
      borderColor: props.checked ? 'green' : 'gray3',
      borderRadius: '4px',
      display: 'flex',
      height: '20px',
      justifyContent: 'center',
      mr: '8px',
      position: 'relative',
      width: '20px',
    })};
`

const Input = styled.input`
  display: none;
`

export function Checkbox(props) {
  const i18n = I18n.useI18n()
  return (
    <Common.Box position="relative" textAlign="left">
      <Input
        checked={Boolean(props.value)}
        id={props.id}
        type="checkbox"
        value={Boolean(props.value)}
      />
      <Label htmlFor={props.id} onClick={() => props.onChange(!props.value)}>
        <Marker checked={props.value}>{props.value && <MarkerIcon />}</Marker>
        {i18n.isExistingTranslation(props.label)
          ? i18n.translate(props.label)
          : props.label}
      </Label>
    </Common.Box>
  )
}

Checkbox.defaultProps = {
  size: 'default',
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
}
