import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import { PrimaryButton } from '../components/common/button'

export function SubmitButton(props) {
  const i18n = I18n.useI18n()
  const form = Forms.useFormContext()
  const errors =
    props.onlyScopes == null
      ? Forms.getFormErrors(form.state)
      : R.pick(props.onlyScopes, Forms.getFormErrors(form.state))

  return (
    <PrimaryButton
      disabled={
        !R.isNil(props.disabled)
          ? props.disabled
          : R.not(R.isEmpty(errors)) || props.loading
      }
      stretched={props.stretched}
      type="submit"
    >
      {props.children
        ? props.children
        : i18n.translate(`${form.name}.submit.label`)}
    </PrimaryButton>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  stretched: PropTypes.bool,
  onlyScopes: PropTypes.arrayOf(PropTypes.string),
}
