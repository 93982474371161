import * as React from 'react'

/* @jsx UiSchema.createElement */
import { UiSchema } from '@rushplay/forms'

export const uiSchema = (
  <UiSchema.Box textAlign="left">
    <UiSchema.Box pt={0}>
      <UiSchema.Text content="source-of-funds.content.why" />
    </UiSchema.Box>
    <UiSchema.Box width="50%">
      <UiSchema.Box pt={2} pb={0}>
        <UiSchema.Box fontWeight="bold" color="black" pb={0}>
          <UiSchema.Text content="source-of-funds.employment.title" />
        </UiSchema.Box>
        <UiSchema.Control scope="#/properties/employment" />
      </UiSchema.Box>
      <UiSchema.Box
        py={0}
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              employment: {
                contains: {
                  oneOf: [
                    { const: 'Full time' },
                    { const: 'Part time' },
                    { const: 'Self Employed' },
                  ],
                },
              },
            },
          }),
        ]}
      >
        <UiSchema.Box fontWeight="bold" color="black" pb={0}>
          <UiSchema.Text content="source-of-funds.occupation.title" />
        </UiSchema.Box>
        <UiSchema.Control scope="#/properties/occupation" />
      </UiSchema.Box>
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Box fontWeight="bold" color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.other-sources.title" />
      </UiSchema.Box>
      <UiSchema.Box color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.other-sources.additional-info" />
      </UiSchema.Box>
      <UiSchema.Control scope="#/properties/otherSources" />
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Control
        scope="#/properties/otherSourcesField"
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              otherSources: {
                contains: {
                  const: 'Other',
                },
              },
            },
          }),
        ]}
      />
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Box fontWeight="bold" color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.salary.title" />
      </UiSchema.Box>
      <UiSchema.Control scope="#/properties/salary" />
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Box fontWeight="bold" color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.deposits-level.title" />
      </UiSchema.Box>
      <UiSchema.Box color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.deposits-level.additional-info" />
      </UiSchema.Box>
      <UiSchema.Control scope="#/properties/depositsLevel" />
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Box fontWeight="bold" color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.source-of-deposits.title" />
      </UiSchema.Box>
      <UiSchema.Box color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.source-of-deposits.additional-info" />
      </UiSchema.Box>
      <UiSchema.Control scope="#/properties/sourceOfDeposits" />
    </UiSchema.Box>
    <UiSchema.Box py={0}>
      <UiSchema.Control
        scope="#/properties/sourceOfDepositsOtherField"
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              sourceOfDeposits: {
                contains: {
                  const: 'Other',
                },
              },
            },
          }),
        ]}
      />
    </UiSchema.Box>

    <UiSchema.Box py={0}>
      <UiSchema.Box fontWeight="bold" color="black" pb={0} id="top-select">
        <UiSchema.Text content="source-of-funds.funds-country-origin.title" />
      </UiSchema.Box>
      <UiSchema.Box color="black" pb={0}>
        <UiSchema.Text content="source-of-funds.funds-country-origin.additional-info" />
      </UiSchema.Box>
      <UiSchema.Control scope="#/properties/fundsCountryOrigin" />
    </UiSchema.Box>

    <UiSchema.Box width="50%">
      <UiSchema.Box py={0}>
        <UiSchema.Box fontWeight="bold" color="black" pb={0}>
          <UiSchema.Text content="source-of-funds.nationality.title" />
        </UiSchema.Box>
        <UiSchema.Control scope="#/properties/nationality" />
      </UiSchema.Box>
      <UiSchema.Box py={0}>
        <UiSchema.Box fontWeight="bold" color="black" pb={0}>
          <UiSchema.Text content="source-of-funds.place-of-birth.title" />
        </UiSchema.Box>
        <UiSchema.Control scope="#/properties/placeOfBirth" />
      </UiSchema.Box>
      <UiSchema.Box py={0}>
        <UiSchema.Box fontWeight="bold" color="black" pb={0}>
          <UiSchema.Text content="source-of-funds.id-number.title" />
        </UiSchema.Box>
        <UiSchema.Control scope="#/properties/idCardNumber" />
      </UiSchema.Box>
    </UiSchema.Box>
    <UiSchema.Box pb={0} pt={2}>
      <UiSchema.Control scope="#/properties/confirmation" />
    </UiSchema.Box>
  </UiSchema.Box>
)
