import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as jurisdiction from '@rushplay/compliance/jurisdiction'
import { withTranslate } from '@rushplay/i18n'

import { CurrentSessionTime as Component } from './current-session-time'

function mapStateToProps(state) {
  const visible = jurisdiction.getCurrentSessionTimeVisibility(
    state.jurisdiction
  )
  const loggedInAt = R.path(['loggedInAt'], state.session)

  if (visible && loggedInAt) {
    return {
      loggedInAt: new Date(loggedInAt),
    }
  }

  return {}
}

const connector = R.compose(
  connect(mapStateToProps),
  withTranslate,
  recompose.branch(props => !props.loggedInAt, recompose.renderNothing)
)

export const CurrentSessionTime = connector(Component)
