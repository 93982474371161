import * as recompose from 'recompose'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { color, font, fontSize } from '@rushplay/theme'
import { noneOf } from '@rushplay/common'

const Heading = styled('div', {
  shouldForwardProp: noneOf(['as', 'align', 'color']),
})`
  font-family: ${font('head')};
  margin: 0;
  ${props => props.align && `text-align: ${props.align}`};
  ${props => props.color && `color: ${color(props.color)(props)}`};
`

Heading.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
}

export const H1 = recompose.defaultProps({ as: 'h1' })(styled(Heading)`
  font-size: ${fontSize(4)};
  font-weight: bold;
  line-height: 1.26;
`)

export const H2 = recompose.defaultProps({ as: 'h2' })(styled(Heading)`
  font-size: ${fontSize(3)};
  font-weight: bold;
  line-height: 1.14;
`)

export const H3 = recompose.defaultProps({ as: 'h3' })(styled(Heading)`
  font-size: ${fontSize(2)};
  line-height: 1.45;
  font-weight: bold;
`)

export const H4 = recompose.defaultProps({ as: 'h4' })(styled(Heading)`
  font-size: ${fontSize(0)};
  line-height: 1.14;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
`)

export const H5 = recompose.defaultProps({ as: 'h5' })(styled(Heading)`
  font-size: ${fontSize(1)};
  font-style: italic;
  line-height: 1.26;
  font-weight: normal;
`)
