import React from 'react'

import { Global, css } from '@emotion/core'

export function ScrollLock() {
  // This components kills scrolling when mounted.
  return (
    <Global
      styles={css`
        body {
          overflow: hidden;
        }
      `}
    />
  )
}
