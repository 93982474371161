import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { CheckboxField } from './checkbox-field'
import { CheckboxGroup } from './checkbox-group'
import { InputField } from './input-field'
import { SelectField } from './select-field'
import { SubmitButton } from './submit-button'

function NumberField(props) {
  return <InputField inputMode="numeric" {...props} />
}

function Text(props) {
  const i18n = I18n.useI18n()
  return <Common.Text {...props}>{i18n.translate(props.children)}</Common.Text>
}

export function FieldBuilder(props) {
  return (
    <Forms.FieldBuilder
      components={{
        box: Common.Box,
        multipleSelectField: CheckboxGroup,
        singleSelectField: SelectField,
        booleanField: CheckboxField,
        numberField: NumberField,
        submit: SubmitButton,
        text: Text,
        textField: InputField,
        ...props.components,
      }}
      disabled={props.disabled}
      getComponent={props.getComponent}
      getCustomField={props.getCustomField}
      name={props.name}
      initialValues={props.initialValues}
      uiSchema={props.uiSchema}
    />
  )
}

Text.propTypes = {
  children: PropTypes.node,
}

FieldBuilder.propTypes = {
  components: PropTypes.object,
  disabled: PropTypes.bool,
  getComponent: PropTypes.func,
  getCustomField: PropTypes.func,
  initialValues: PropTypes.object,
  name: PropTypes.string,
  uiSchema: PropTypes.object.isRequired,
}
