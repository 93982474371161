import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'

const BaseButton = styled('button', {
  shouldForwardProp: noneOf(['small', 'stretched']),
})`
  display: inline-block;
  cursor: pointer;
  outline: none;

  text-align: center;
  text-decoration: none;
  font-family: ${t.font('head')};
  font-size: ${t.fontSize(1)};
  font-weight: bold;
  line-height: 1;

  background-color: transparent;

  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 9999px;

  padding: ${t.spacing(1)} ${t.spacing(2)};
  ${props => props.stretched && 'width: 100%;'};

  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover,
  &:disabled:active,
  &[disabled]:active {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.5);
    background-color: ${t.color('gray')};
    border-color: ${t.color('gray')};
  }

  ${props =>
    props.small &&
    `
    padding: ${t.spacing(0)(props)} ${t.spacing(1)(props)};
    font-size: ${t.fontSize(0)(props)};
  `}
`

export const Button = styled(BaseButton)`
  color: rgba(0, 0, 0, 0.5);

  border-color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: ${t.color('gray7')};
  }
`

export const PrimaryButton = styled(BaseButton)`
  background-color: ${t.color('green')};
  border-color: ${t.color('green')};
  color: ${t.color('white')};
  text-shadow: 0 2px 4px ${t.color('black', 0.2)};

  &:hover,
  &:active {
    background-color: ${t.color('green6')};
    border-color: ${t.color('green6')};
  }

  &:active {
    box-shadow: inset 0 1px 9px 0 rgba(0, 0, 0, 0.2);
  }
`

export const AltButton = styled(BaseButton)`
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);

  &:hover {
    color: ${t.color('white')};
    border-color: ${t.color('white')};
  }
`

export const AltButtonInverted = styled(BaseButton)`
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);

  &:hover {
    color: black;
    border-color: black;
  }
`

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  stretched: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
}
