import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import * as notifications from '../notifications'

import * as types from './types'

export function fetch() {
  return [
    processes.start('FETCH_ENABLED_COUNTRIES'),
    api.fetchCountries({
      cacheFor: Infinity,
      success: res => [
        processes.stop('FETCH_ENABLED_COUNTRIES'),
        add(res.value),
      ],
      failure: () => [
        processes.stop('FETCH_ENABLED_COUNTRIES'),
        notifications.add({ message: 'error.fetch.countries' }, 'error'),
      ],
      version: 1,
    }),
  ]
}

export function add(payload) {
  return {
    type: types.ADDED,
    payload,
  }
}
