import * as R from 'ramda'
import { connect } from 'react-redux'
import { withTheme } from 'emotion-theming'

import * as locks from '@rushplay/compliance/locks'
import * as api from '@rushplay/api-client'
import * as _session from '@rushplay/session'
import * as analytics from '@rushplay/analytics'

import * as notifications from '../../notifications'
import * as player from '../../player'
import * as session from '../../session'
import * as combinedSelectors from '../../combined-selectors'
import * as inventory from '../../inventory'

import { AcceptBonusFunds as Component } from './accept-bonus-funds'

function mapStateToProps(state) {
  const amountCents = player.getLastDepositAmountCents(state.player)
  const lock = locks.getLock(state.locks, { type: 'accept-bonus-funds' })
  const welcomeOffer = combinedSelectors.getWelcomeOffer(state, amountCents)
  const rewards = R.filter(Boolean, [
    R.path(['bonusAmount'], lock) > 0 && 'casino-bonus',
    R.pathOr(0, ['fixedFeatureTriggers'], welcomeOffer) > 0 && 'bonus-rounds',
    R.pathOr(0, ['fixedFreebet'], welcomeOffer) > 0 && 'sports-bet',
    R.pathOr(0, ['fixedLotteryBundles'], welcomeOffer) > 0 && 'lottery-bundles',
  ])
  return {
    bonusAmount: R.path(['bonusAmount'], lock),
    bonusPercentage: R.pathOr(0, ['bonusMoneyPercent'], welcomeOffer),
    isFetching: !amountCents,
    maxBonusCents: R.pathOr(0, ['bonusMoneyPercentMaxCents'], welcomeOffer),
    minDepositAmount: combinedSelectors.getWelcomeOfferMinDepositLimit(state),
    rewards,
    token: _session.getSessionToken(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onAccept: bonusAmount =>
      dispatch(
        api.acceptBonusFunds({
          success: () => [
            analytics.bonus({ value: bonusAmount, claimed: true }),
            locks.fetch(),
            locks.remove('accept-bonus-funds'),
            inventory.fetch(),
          ],
          failure: () =>
            notifications.add(
              { message: 'errors.accept-bonus-funds.failed' },
              'error'
            ),
          version: 1,
        })
      ),
    onDecline: (token, bonusAmount) =>
      dispatch(
        api.declineBonusFunds({
          success: () => [
            analytics.bonus({ value: bonusAmount, claimed: false }),
            inventory.clearInventory(),
            session.refresh(token),
            locks.fetch(),
            locks.remove('accept-bonus-funds'),
          ],
          failure: () =>
            notifications.add(
              { message: 'errors.decline-bonus-funds.failed' },
              'error'
            ),
          version: 1,
        })
      ),
    onFetch: () => dispatch(player.fetch()),
  }
}

function mergeProps(stateProps, dispatchProps) {
  return {
    bonusAmount: stateProps.bonusAmount,
    bonusPercentage: stateProps.bonusPercentage,
    isFetching: stateProps.isFetching,
    maxBonusCents: stateProps.maxBonusCents,
    minDepositAmount: stateProps.minDepositAmount,
    rewards: stateProps.rewards,
    onAccept: () => dispatchProps.onAccept(stateProps.bonusAmount),
    onDecline: () =>
      dispatchProps.onDecline(stateProps.token, stateProps.bonusAmount),
    onFetch: () => dispatchProps.onFetch(),
  }
}

const connector = R.compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withTheme
)

export const AcceptBonusFunds = connector(Component)
